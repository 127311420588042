import {httpReq} from "../../../htttpReq";
import store from "../../store";
import router from '../../../router';

export default {
	namespaced: true,

	state: {},

	getters:   {},
	mutations: {},
	actions: {}
}