import {httpReq} from "@/htttpReq";

export default {
  namespaced: true,

  state: {
    question: '',
      actions: [],
    openModal: false,
    skip: null
  },

  getters: {
    question(state){
      return state.question
    },

    openModal(state){
      return state.openModal
    },

    actions(state){
      return state.actions
    },
    skip(state){
      return state.skip
    }
  },

  mutations: {
    updateErrors(state, payload){
      state.errors = payload;
    },

    closeModal(state){
      state.question = ''
      state.openModal = false
    },

    labelText(state, payload){
      state.labelText = payload;
    },

    textEdit(state, payload){
      state.editText = payload;
    },

    openModal(state, payload){

      state.question = payload.question
      state.actions = payload.buttons
      if (payload.skip === true)
        state.skip = payload.skip_text
      else
        state.skip = null
      state.openModal = true && state.question
    }
  },

  actions: {

  },
};
