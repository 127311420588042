import {httpReq} from "@/htttpReq";

export default {
  namespaced: true,

  state: {
    errors: [],
    openModal: false,
    labelText: false,
    editText: false
  },

  getters: {
    errors(state){
      return state.errors;
    },

    openModal(state){
      return state.openModal;
    },

    labelText(state){
      return state.labelText;
    },

    textEdit(state){
      return state.editText
    }
  },

  mutations: {
    updateErrors(state, payload){
      state.errors = payload;
    },

    closeModal(state){
      state.errors = [];
      state.openModal = false
    },

    labelText(state, payload){
      state.labelText = payload;
    },

    textEdit(state, payload){
      state.editText = payload;
    },

    openModal(state, payload){
      for(let key in payload){
        for(let i = 0; i < payload[key].length; i++){
          state.errors.push(payload[key][i]);
        }
      }
      state.openModal = true;
    }
  },

  actions: {

  },
};
