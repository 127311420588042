// TODO is temporary wrapper :)
export const httpReq = {
    get(url, params = {}) {
        return request.make(url, 'get', params)
    },
    post(url, params = {}) {
        return request.make(url, 'post', params)
    },
    put(url, params = {}) {
        return request.make(url, 'put', params)
    },
    delete(url, params = {}) {
        return request.make(url, 'delete', params)
    }

};
