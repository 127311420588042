import {httpReq} from "../../../htttpReq";
import store from "../../store";

export default {
  namespaced: true,

  state: {
    userInfo: {
      user: {}
    },

    passwordInfo: {
      current_password: '',
      password: '',
      password_confirm: ''
    },

    prefer_entries: '',
    size_entering_type: '',
  },

  getters: {
    userInfo(state) {
      return state.userInfo;
    },

    passwordInfo(state){
      return state.passwordInfo;
    },

    preferEntries(state){
      return state.prefer_entries;
    },
    sizeEnteringType(state){
      return state.size_entering_type;
    }
  },

  mutations: {
    userInfo(state, payload) {
      state.userInfo = payload;
    },

    clearInput(state){
      for(let key in state.passwordInfo){
      }
    },

    updatePrefer(state, payload){
      localStorage.setItem('prefer_entries', payload)
      state.prefer_entries = payload
    },
    updateSizeEntering(state, payload){
      localStorage.setItem('size_entering_type', payload)
      state.size_entering_type = payload
    }
  },

  actions: {
    updateInfo({commit}, credentials) {
      store.commit('preloader/preloader', true);
      httpReq.post('user/update-account', {
          name: credentials.name,
          last_name: credentials.last_name,
          prefer_entry: credentials.prefer_entry,
          size_entering_type: credentials.size_entering_type,
          skip_packages: credentials.skip_packages,
        })
        .then(response => {
          localStorage.setItem('skip_packages', response.data.skip_packages)
          commit('updatePrefer', response.data.profile.prefer_entry);
          commit('updateSizeEntering', response.data.profile.size_entering_type);
          store.commit('orderPreview/updateFirstDimension', response.data.profile.prefer_entry);
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },

    getInfo({commit}) {
      store.commit('preloader/preloader', true);
      httpReq.get('user/update-account')
        .then(response => {
          commit('updatePrefer', response.data.prefer_entries);
          commit('updateSizeEntering', response.data.size_entering_type);
          commit('userInfo', response.data);
        })
        .catch((error) => {
          if(error.response.status == '401'){
            localStorage.removeItem('user-token');
            localStorage.removeItem('nameUser');
            window.location = '/login';
          }
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },

    //on page user update password
    changePassword({commit}, credentials) {
      store.commit('preloader/preloader', true);
      httpReq.post('user/update-account-password', {
          current_password: credentials.current_password,
          password: credentials.password,
          password_confirm: credentials.password_confirm,
        })
        .then(response => {
            localStorage.setItem('user-token', response.data.token);
            window.location.reload()
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors);
          store.commit('errorsModal/labelText', true);
        })
        .finally(() => {
          commit('clearInput');
          store.commit('preloader/preloader', false);
        })
    },
  },
};
