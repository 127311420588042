import {httpReq} from "../../htttpReq";
import router from "../../router";
import store from "../store";

export default {
  namespaced: true,
  state: {
    message: '',
    text: '',
    reject: {},
  },

  getters: {
    message(state) {
      return state.message;
    },

    reject(state){
      return state.reject;
    },

    rejectReason(state){
      return state.rejectReason;
    }
  },

  mutations: {
    updateMessage(state, payload) {
      state.message = payload;
    },

    clearMessage(state){
      state.message = '';
    },

    rejectReason(state, payload){
      state.reject = payload;
    }
  },

  actions: {
    sendEmail(context, credential) {
      store.commit('preloader/preloader', true);
      httpReq.post('check-status', {
        email: credential.email,
      })
        .then((response) => {
          let codeStatus = response.data.code;
          context.commit('clearMessage');
          if (codeStatus === 'is_logged') {
            router.push({name: 'pending'});
          }

          else if (codeStatus === 'is_not_have_status') {
            context.commit('updateMessage', response.data);
          }

          else if (codeStatus === 'not_found_in_system') {
            context.commit('updateMessage', response.data);
          }

          else if (codeStatus === 'pending') {
            router.push({name: 'pending'});
          }

          else if (codeStatus === 'approved') {
            context.commit('updateMessage', response.data);
          }

          else if (codeStatus === 'rejected'){
            context.commit('rejectReason', response.data);
            router.push({name: 'reject'});
          }

        })
        .catch(error => {

        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        });
    },
      resendEmail(context, credential) {
          store.commit('preloader/preloader', true);
          httpReq.post('resend', {
              email: credential.email,
          })
              .then((response) => {
                  if (response.status == 200)
                      store.commit('errorsModal/openModal', [['Email has been sent. Check your spam folder if you don\'t receive it.']])
              })
              .catch(error => {

              })
              .finally(() => {
                  store.commit('preloader/preloader', false);
              });
      },

  },
};
