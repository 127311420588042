import store from "../../store";
export default {
    namespaced: true,
    state: {
        status: false,
        params: {
            action: String,
            actionData: {},
            itemType: String
        }
    },
    getters: {
        get: state => key => {
            return _.has(state, key) ? state[key] : null;
        },
        itemName(state) {
            let result = '';
            switch (state.params.itemType) {
                case 'oi':
                    result = 'Order Item';
                case 'ac':
                    result = 'accessory';
            }
            return result;
        }
    },
    mutations: {
        setStatus(state, payload) {
            state.status = payload;
        },
        setParams(state, payload) {
            state.params = payload;
        }
    },
    actions: {
        deleteId({state}) {
            store.dispatch(state.params.action, state.params.actionData);
            state.status = false;
            if (state.params.actionAfter) {
              store.dispatch(state.params.actionAfter.action, state.params.actionAfter.data)
            }
        }
    }
};
