<template>
  <transition
    v-if="show"
    name="fade"
  >
    <div
      class="modal modal_wrap "
    >
      <div
        class="modal_popup"
        :class="getModelSize"
      >
        <div class="modal-content-wrapper">
          <div class="d-flex justify-content-between pb-3">
            <div class="title">
              <h3>{{ title }}</h3>
            </div>
            <div>
              <button
                class="btn btn_transparent tw-m-0 tw-border-none"
                type="button"
                @click="$emit('update:show',false),$emit('closed')"
              >
                <svg-icon name="solid/times"></svg-icon>
              </button>

            </div>
          </div>
          <div class="container modal_content">
            <slot name="modal-content">
              Content goes here
            </slot>
          </div>

          <div class="justify-content-between">
            <slot name="actions">
              Actions goes here
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "BaseModalComponent",
  props: {
    size: {
      type: String,
      default: 'sm'
    },
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'title goes here'
    }
  },
  computed: {
    getModelSize() {
      if (!(["sm", "lg"].includes(this.size))) return 'generic-modal'
      return this.size === 'sm' ? 'generic-modal' : 'modal_full'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/utils/_vars.scss";

.generic-modal {
  .modal_content {
    max-height: calc(100vh - 220px);
  }
}

.modal-content-wrapper {
  position: relative;
}
.modal {
  .title {
    text-transform: uppercase;
    font-size: $h4;
    color: $white;
    font-weight: bolder;
  }
}

.action-btn {
  display: flex;
  color: #ffffff;
  cursor: pointer;
  transition: all .2s;

  :hover {
    color: $green;
  }

  .lg {
    font-size: 2em;
  }
}


</style>
