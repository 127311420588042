<template>
  <div class="modal modal_wrap" v-if="openModal" @keyup.esc="closePopup">
    <div class="modal_popup">
      <h4 class="error_title">Please check following information</h4>
      <span class="modal__close" @click="closePopup">
         <svg-icon name="times"/>
      </span>

      <p class="text text_green text_italic" v-if="textEdit">You can not edit this item</p>

      <p class="text text_green text_italic" v-if="textLabel">Please check the following fields and try again:</p>
      <div class="divider" v-else></div>
      <p class="text text_green text_italic" v-if="!errorsList || errorsList.length == 0">Unknown error happened and has been logged with us. We will aim to resolve this issue as soon as possible. Our apologies for inconvenience</p>
      <ul v-else>
        <li class="text text_green text_italic"
            v-for="(item, index) in errorsList"
            :key="index"
        >
        <span  v-if="item !== 'link'"  v-html="item" />

        <span  v-else class="noMarkered" @click="closePopup">
           <router-link :to="{name: 'login'}"
                        class="btn btn_transparent"
           >login here</router-link>
        </span>

     </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: "errorModal",

    data() {
      return {}
    },

    methods: {
      closePopup() {
        return this.$store.commit('errorsModal/closeModal');
      },
    },

    computed: {
      errorsList() {
        return this.$store.getters['errorsModal/errors'];
      },

      openModal() {
        return this.$store.getters['errorsModal/openModal'];
      },

      textLabel() {
        return this.$store.getters['errorsModal/labelText'];
      },

      textEdit(){
        return this.$store.getters['errorsModal/textEdit'];
      }
    },
  }
</script>

<style scoped lang="scss">

  @import "../../../src/assets/scss/utils/vars";

  .error_title{
    color: #dddddd;
  }

  ul {
    li {
      list-style: inside disc;
    }
  }

  .divider {
    &:before {
      border-color: $green;
    }
  }

  .btn {
    margin-bottom: 0;
  }

  .noMarkered{
    list-style-type: none;
  }
</style>
