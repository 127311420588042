<template>
  <div class="modal modal_wrap" v-if="open">
    <div class="modal_popup">
      <p class="text text_white"> {{ question }}
        <span class="modal__close" @click="close">
         <svg-icon name="times"/>
      </span>
      </p>
      <p v-if="skip">
        <label class="form__label form__label_row">
          <input type="checkbox"
                 hidden="hidden"
                 class="form__checkbox"
                 v-model="skip_question"
          >
          <span class="form__checkbox_icon"></span>
          <span class="form__labelTitle">{{ skip }}</span>
        </label>
      </p>
      <div class="justify-content-between">
        <slot name="actions" >
          <button class="btn btn_transparent tw-flex tw-items-center tw-space-x-1.5" @click="click(i.handler)" v-for="(i) in actions">
             <svg-icon v-if="i.icon" :name="i.icon"></svg-icon> <span>{{i.name}}</span>
          </button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>

  import router from "../../router";
  import {mapGetters} from 'vuex';

  export default {
    name: "GenericQuestion",
    data() {
      return {
        skip_question: false
      }
    },
    props: {
      title: {
        type: String,
        default: 'Cancel will discard changes. Do want to continue?'
      },
    },
    methods: {
        click(act){
            act(!this.skip_question);
            this.$store.commit('genericQuestion/closeModal')
        },
        close() {
          this.$store.commit('genericQuestion/closeModal')
        }
    },

    computed: {
      ...mapGetters(
        {
            open: 'genericQuestion/openModal',
          question: 'genericQuestion/question',
          skip: 'genericQuestion/skip',
          actions: 'genericQuestion/actions',
        }
      ),

    }
  }
</script>

<style scoped>
  .justify-content-between {
    display: flex;
  }

  p.text{
    position: relative;
    padding-right: 30px;
  }

  .modal__close{
    top: 0;
    right: 0;
  }

</style>
