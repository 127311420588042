import store from '../../store'
import router from '../../../router'
import {httpReq} from '@/htttpReq'

export default {
  namespaced: true,

  state: {
    order: {},
    ordersSorting: 'by_date',
    ordersSearch: null,
    orderAllItems: [],
    statusList: [
      {name:'Draft', icon: 'solid/pen-swirl',ref:'ref_draft', hidden: true},
      {name:'Finance Approval', icon: 'solid/hand-holding-usd',ref:'ref_finance_approval'},
      {name:'Awaiting Payment on Account', icon: 'solid/file-invoice-dollar',ref:'ref_awaiting_payment_on_account'},
      {name:'Production Approval'  , icon: 'solid/list-check',ref: 'ref_production_approval'},
      {name:'Production Scheduled', value: 'ORDER PROCESSING', icon: 'solid/conveyor-belt',ref:'ref_production_scheduled'},
      {name:'In Production', icon: 'solid/tools',ref: 'ref_in_production'},
      {name:'Production Completed', icon: 'solid/box-check',ref:'ref_production_completed'},
      {name:'Dispatched', icon: 'solid/truck-fast',ref: 'ref_dispatched'},
      {name:'Awaiting Payment', icon: 'solid/sack-dollar',ref: 'ref_awaiting_payment'},
      {name:'Completed', icon: 'solid/archive',ref: 'ref_completed'},
      // {name:'Cancelled', icon: 'solid/archive',ref: 'ref_cancelled'},
    ]
  },

  getters: {
    ordersList: (state) => (payload) => {
      return state[`ordersList${payload.toString().replace(/\s/g, "")}`] || []
    },
     order(state) {
      return state.order
    },
    orderID(state) {
      return state.order?.id || ''
    },
    customerID(state) {
      return state.order?.customer_id || ''
    },
    ordersAmount: (state) => (payload) => {
      return state[`ordersPagination${payload.toString().replace(/\s/g, "")}`]?.total || ''
    },
    ordersNextPage: (state) => (payload) => {
      return state[`ordersPagination${payload.toString().replace(/\s/g, "")}`]?.current_page + 1 || 1
    },
    ordersTotalPages: (state) => (payload) => {
      return state[`ordersPagination${payload.toString().replace(/\s/g, "")}`]?.total_pages || 1
    },
    ordersSorting(state) {
      return state.ordersSorting
    },
    ordersSearch(state) {
      return state.ordersSearch
    },
    orderAllItems(state) {
      return state.orderAllItems || []
    },

  },

  mutations: {
   ordersList(state, payload){
      if(!state[`ordersList${payload[1]}`]) {
        state[`ordersList${payload[1]}`] = []
      }
      state[`ordersList${payload[1]}`].push(...payload[0])
    },
   order(state, payload){
      state.order = payload;
    },
   ordersPagination(state, payload){
      state[`ordersPagination${payload[1]}`] = payload[0];
    },
   ordersSearch(state, payload){
      state.ordersSearch = payload;
    },
   ordersSorting(state, payload){
      state.ordersSorting = payload;
    },
    resetOrders(state, payload){
      state[`ordersList${payload.toString().replace(/\s/g, "")}`] = [];
      state[`ordersPagination${payload.toString().replace(/\s/g, "")}`] = {};
    },
    resetOrderPrice(state, payload){
      state.orderPrice = {}
    },
    resetSorting(state, payload){
      state.ordersSorting = null
    },
      orderAllItems(state, payload){
          state.orderAllItems = payload
      },
      resetOrderAllItems(state, payload){
          state.orderAllItems = {}
      },
  },

  actions: {
    getOrdersList({state, getters, commit}, payload) {
      // store.commit('preloader/preloader', true);
      let parameters = {
        // filter: {
        // }
      }
      if (payload) {
        // Object.assign(parameters.filter, {status: payload.status}),
        Object.assign(parameters, {page: getters.ordersNextPage(payload.status)})
      }
      if (getters.ordersSorting) {Object.assign(parameters, {sort: getters.ordersSorting})}
      let status = payload?.status || ''
      if (state.ordersSorting && getters.ordersNextPage(payload.status) <= getters.ordersTotalPages(payload.status)) {
        return httpReq.get(`ordering-portal/my-orders?paginate=10&filter[status]=${payload?.status || ''}&filter[search]=${getters.ordersSearch || ''}`,
          parameters
        )
          .then(response => {
            commit('ordersList', [response.data?.data , status.replace(/\s/g, "")]);
            commit('ordersPagination', [response.data?.pagination , status.replace(/\s/g, "")]);
          })
          .catch( error => {
            console.log(error)
          })
          .finally(() => {
            // store.commit('preloader/preloader', false);
          })
      }
    },
    deleteOrder({state, getters, commit}, payload) {
      store.commit('preloader/preloader', true);
      return httpReq.delete(`ordering-portal/my-orders/${payload}`,)
        .then(response => {
          commit('ordersSorting', getters.ordersSorting+' ');
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },
    getOrder({state, getters, commit}, payload) {
      store.commit('preloader/preloader', true);
      return httpReq.get(`ordering-portal/my-orders/dashboard/${payload}`)
        .then(response => {
          commit('order', response.data?.data);
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },
    changeOrdersStatus({state, getters, commit}, payload) {
      store.commit('preloader/preloader', true);
      return httpReq.put(`ordering-portal/my-orders/${payload.id}/${payload.status}`, payload.parameters
      )
        .then(response => {
          commit('ordersSorting', getters.ordersSorting+' ');
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },
    cloneOrder({state, getters, commit}, payload) {
      store.commit('preloader/preloader', true);
      return httpReq.post(`ordering-portal/my-orders/${payload.id}/clone`
      )
        .then(response => {
          commit('ordersSorting', getters.ordersSorting+' ');
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },
    createNewOrder({state, getters, commit}, payload) {
      // store.commit('preloader/preloader', true);
      let parameters = {
      }
      if (payload.parameters) {
        Object.assign(parameters, payload.parameters);
      }
      return httpReq.post(`ordering-portal/my-orders`, parameters
      )
        .then(response => {
          commit('order', response.data?.data);
        })
        .finally(() => {
          // store.commit('preloader/preloader', false);
        })
    },
      getOrderAllItems({state, getters, commit}, payload) {
          store.commit('preloader/preloader', true);
          return httpReq.get(`ordering-portal/my-orders/${payload}/get-all-items`,)
              .then(response => {
                  commit('orderAllItems', response.data?.data);
              })
              .finally(() => {
                  store.commit('preloader/preloader', false);
              })
      },

  },
};
