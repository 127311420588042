import store from "../../store";
import {httpReq} from "@/htttpReq";

export default {
  namespaced: true,

  state: {
    colors: {},
    defaultColors: [],
    filtrated: [],
    colour_group: 0,
      open_modal: false,
      current_colour: null
  },

  getters: {
    colors(state) {
      delete state.colors.debug;
      return state.colors;
    },
      defaultColors(state) {
          return state.defaultColors;
      },
    filtrated(state) {
      return state.filtrated;
    },
      openModal(state) {
          return state.open_modal;
      },
      colourGroup(state){
        return state.colour_group;
      },
      selectedColour(state){
        return state.current_colour;
      }
  },

  mutations: {
    colors(state, payload) {
      state.colors = payload;
      state.filtrated = payload;
    },
    filtered(state, payload) {
      state.filtrated = payload;
    },
      colourGroup(state, payload)
      {
          state.colour_group = payload;
      },
      openModal(state, payload) {
          state.open_modal = payload;
      },
      defaultColors(state, payload){
        state.defaultColors = payload;
      },
      getColors({commit}, params) {
          let group = store.getters['optionColours/colourGroup']
          // style_id = _.has(params, style_id) ? params.style_id : store.getters['orderPosition/orderPosition'].style.id,
          //     grade_id = _.has(params, grade_id) ? params.grade_id : store.getters['orderPosition/orderPosition'].grade.id;

          store.commit('preloader/preloader', true);
          httpReq.get('order/colours/' + group + '/' + store.getters['orderPosition/orderPosition'].colour.id)
              .then(response => {
                  var data = response.data;
                  if (data.default) {
                      store.commit('optionColours/defaultColors', data.default);
                      delete data.default;
                  }

                  store.commit('optionColours/colors', data);
              })
              .catch(error => {
                  store.commit('errorsModal/openModal', error.response.data.errors);
              })
              .finally(() => {
                  store.commit('preloader/preloader', false);
              })
      },
      selectedColour(state, payload){
          state.current_colour = payload;

      }

  },

  actions: {
  },
};
