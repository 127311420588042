import Vue from 'vue'
import store from '../../store'
import router from '../../../router'
import {httpReq} from '@/htttpReq'
import packages from '../packages/packages'
import orderPreview from "./orderPreview";

export default {
    namespaced: true,

    state: {
        stop_calculation: false,
        currentPosition: {

            order_id: 0,
            dealer_id: 0,
            id: 0,
            location: '',
            location_org: '',
            item: {
                id: 0,
                name: '',
                type_id: 0,
                type_name: '',
                style_id: 0,
                style_name: '',
                grade_id: 0,
                grade_name: 0,
                category_id: 0,
                category_name: ''
            },
            wdt: '',
            wdm: 0,
            wdb: 0,
            htl: '',
            htm: 0,
            htr: 0,

            colour: {
                id: 0,
                name: '',
                full_name: '',
                hexcode: '',
                image: '',
                image_url: '',
                category_id: 0,
                category_name: ''
            },

            has_insert: false,
            has_only_colour: true,

            insert_colour: {
                id: 0,
                name: '',
                full_name: '',
                hexcode: '',
                image: '',
                image_url: '',
                category_id: 0,
                category_name: ''
            },

            options: {
                default: [],
                available: []
            },

            items: [],
            components_price: 0,
            outOfSquareImageUrl: null,
            isOutOfSquareCustomImage: false,

        },
        isCompleted: false,
        movingBackFrom: null,

        current_step: 'None',
        current_option: 'None',
        stepsHistory: [],
        updatePrice: false,

        osqStandardFiles: new FormData(),
        selectModal: false,
        warningModal: false,
        deleteModal: false,

        warningOutOfSquare: false,
        shouldUpdateOutOfSquareImage: false,
        hideBackButtonAtAccessories: false,
    },

    getters: {
        getHideBackButtonAtAccessoriesState (state) {
          return state.hideBackButtonAtAccessories
        },

        isStepOnTypeStyleGradeColour(state) {
            return ['Type','Style', 'Grade', 'Frame Colour' ].includes(state.current_step)
        },
        isCompleted: function (state) {
            return state.isCompleted
        },
        osqStandardFiles(state) {
            return state.osqStandardFiles
        },
        updatePrice(state) {
            return state.updatePrice
        },

        isEdit(state){
            return state.currentPosition.id !== 0
        },

        orderPosition(state) {
            return state.currentPosition
        },

        // additionalAccessory(state) {
        //   return state.additionalAccessory
        // },


        currentOption(state) {
            return state.current_option
        },

        // filterStyle(state) {
        //     let arr = []
        //     _.forEach(state.currentPosition.item.style_id, function (el) {
        //         arr.push(el.id)
        //         return arr
        //     })
        //     return arr
        // },

        // filterGrade(state) {
        //     let arr = []
        //     _.forEach(state.orderPosition.grade.id, function (el) {
        //         arr.push(el.id)
        //         return arr
        //     })
        //     return arr
        // },

        // modals(state) {
        //     return state.modals
        // },

        // selectModal(state) {
        //     return state.selectModal
        // },

        // warningModal(state) {
        //     return state.warningModal
        // },

        // deleteModal(state) {
        //     return state.deleteModal
        // },

        // warningOutOfSquare(state) {
        //     return state.warningOutOfSquare
        // },

        currentStep(state) {
            return state.current_step
        },

        getCurrentOptionSettings(state) {
            if (state.current_step == 'Option') {
                if (state.currentPosition.options['default'][state.current_option])
                    return state.currentPosition.options['default'][state.current_option].settings
                else
                    return state.currentPosition.options['available'][state.current_option].settings
            }
        },
        getCurrentOptionSelection(state) {
            if (state.current_step == 'Option') {
                if (state.currentPosition.options['default'][state.current_option]) {
                    return state.currentPosition.options['default'][state.current_option].selection
                }
                else
                    return state.currentPosition.options['available'][state.current_option].selection
            }
        },

        getPrice(state) {
            var price = state.currentPosition.dry_price

            for (var k in state.currentPosition.options.default) {
                price += state.currentPosition.options.default[k].option_price
            }
            for (var j in state.currentPosition.options.available) {
                price += state.currentPosition.options.available[j].option_price
            }

            if (!price)
                return 0

            return price.toFixed(2)
        }

    },

    mutations: {
      updateHideBackButtonAtAccessoriesState (state,payload) {
        state.hideBackButtonAtAccessories = payload
      },
        addHistory(state, payload) {
            const {currentStep, currentOption, handlers, immediateHandlers} = payload
            const stepName = `${currentStep}-${currentOption}`

            let last = state.stepsHistory.slice(-1)[0]
            if (state.stepsHistory.filter(i => i.name === stepName).length > 1 && last && last.name === stepName) state.stepsHistory.pop()
            state.stepsHistory.push({
                name: stepName,
                step: currentStep,
                option: currentOption,
                handlers: handlers || [],
                immediateHandlers: immediateHandlers || []
            })
        },
        setItem(state, payload){
          state.currentPosition.item = payload
        },
        setOutOfSquareStandardImageUrl(state, payload) {
            state.currentPosition.isOutOfSquareCustomImage = false
            state.currentPosition.outOfSquareImageUrl = payload
            state.shouldUpdateOutOfSquareImage = true
        },
        setOutOfSquareCustomImageUrl(state, payload) {
            state.currentPosition.isOutOfSquareCustomImage = true
            state.currentPosition.outOfSquareImageUrl = payload.imageUrl
            state.shouldUpdateOutOfSquareImage = true
        },
        updatePrice(state, payload) {
            state.updatePrice = payload
        },

        cleanObject(state) {

            store.commit('app/clearBradcrumbs')

            state.currentPosition = {

                order_id: 0,
                dealer_id: 0,
                id: 0,
                location: '',
                location_org: '',
                item: {
                    id: 0,
                    name: '',
                    type_id: 0,
                    type_name: '',
                    style_id: 0,
                    style_name: '',
                    grade_id: 0,
                    grade_name: 0,
                    category_id: 0,
                    category_name: ''
                },
                wdt: '',
                wdm: 0,
                wdb: 0,
                htl: '',
                htm: 0,
                htr: 0,

                colour: {
                    id: 0,
                    name: '',
                    full_name: '',
                    hexcode: '',
                    image: '',
                    image_url: '',
                    category_id: 0,
                    category_name: ''
                },

                has_insert: false,
                has_only_colour: true,
                insert_colour: {
                    id: 0,
                    name: '',
                    full_name: '',
                    hexcode: '',
                    image: '',
                    image_url: '',
                    category_id: 0,
                    category_name: ''
                },

                options: {
                    default: [],
                    available: []
                },

                items: [],
                components_price: 0,
                outOfSquareImageUrl: null,
                isOutOfSquareCustomImage: false
            }
            state.current_step = 'None'
            state.current_option = 'None'
            state.osqStandardFiles = new FormData();
            state.stepsHistory = []
            state.isCompleted = false
            state.movingBackFrom = null
        },

        nextStep(state, payload) {
            state.current_step = payload
        },

        setOsqStandardFiles(state, payload) {
            state.osqStandardFiles = payload
        },

        setOrderId(state, payload) {
            state.currentPosition.order_id = payload
        },

        calculateNextStep(state) {
            let s = 'All Options'
            state.current_option = 'None'

            if (state.currentPosition.location == '') {
                s = 'Location'
            }
            else if (state.currentPosition.item.type_id == 0) {
                s = 'Type'
            }
            else if (state.currentPosition.item.style_id == 0) {
                s = 'Style'
            }
            else if (state.currentPosition.item.grade_id == 0) {
                s = 'Grade'
            }
            else if (state.currentPosition.colour.id == 0) {
                s = 'Frame Colour'
            }
            else if (state.currentPosition.has_insert && !state.currentPosition.is_insert_colour_set)
                s = 'Insert Colour'
            else {
                var opt = []

                for (var o in state.currentPosition.options.default) {
                    opt.push([o, state.currentPosition.options.default[o]])
                }

                opt.sort(function (a, b) {
                    return a[1].sort_order - b[1].sort_order
                })

                var found = false
                opt.forEach(function (i) {
                    if (!found && i[1].is_set !== true) {
                        s = 'Option'
                        state.current_option = i[1].code
                        found = true
                    }
                })
            }

            if (s == 'Option' && (state.current_option == 'MIDR' || state.current_option == 'MULL'))
            {
                var midr, mull

                midr = state.currentPosition.options.default['MIDR']
                mull = state.currentPosition.options.default['MULL']

                if (midr && mull)
                {
                    var midr_count = 0,
                        mull_count = 0

                    _.each(midr.settings.values, (i) => {
                        if (i.required && i.selected)
                            midr_count++
                    })
                    _.each(mull.settings.values, (i) => {
                        if (i.required && i.selected)
                            mull_count++
                    })

                    if (midr_count > 0 && mull_count > 0)
                    {
                        state.current_option = 'MM'
                    }

                }

            }


            if (s == 'All Options')
            {
                var arr = store.getters['app/get']('breadcrumbs').filter(function(v, i, a) {
                    return v.order < 100
                })
                state.isCompleted = true
                state.stepsHistory = []
                store.commit('app/set', {k: 'breadcrumbs', v: arr})
            }

            state.current_step = s

        },

        resolveMidrailMullion(state, option)
        {
            var opposite = (option == 'MIDR' ? 'MULL' : 'MIDR'),
                settings = state.currentPosition.options.default[opposite].settings
            _.each(settings.values, (i) => {
                i.selected = false
            })
            store.commit('orderPosition/setOption', { option: opposite, selection: settings})
            store.commit('orderPosition/setOption', { option: option, selection: state.currentPosition.options.default[option].settings})
            state.currentPosition.options.default[opposite].is_set = true
            store.commit('orderPosition/showOption', option)
        },

        removeCylinder(state){
            var co = state.currentPosition.options.default['CYL']
          console.log(co)
            if (co) {
                _.forEach(co.settings.groups.default.choices, (i) => {
                    i.default = i.name === 'No Cylinder'
                })
                store.commit('orderPosition/setOption', {
                    option: 'CYL',
                    selection: co.settings
                })
            }
        },

        showOption(state, payload) {
            state.current_step = 'Option'
            state.current_option = payload
        },

        goToStep(state, payload) {

            state.current_step = payload
            if (payload != 'Option') {
                state.current_option = 'None'
            }
        },

        setLocation(state, payload) {
            state.currentPosition.location = payload

            store.commit('orderPosition/fillBreadcrumbs')

            store.commit('orderPosition/calculateNextStep')
        },

        setTypeAndSize(state, payload) {

            store.commit('orderPosition/setWidth', payload.width)
            store.commit('orderPosition/setDrop', payload.drop)

            state.currentPosition.item.type_id = payload.type.id
            state.currentPosition.item.type_name = payload.type.name

            store.commit('orderPosition/fillBreadcrumbs')

            store.commit('orderPosition/setStyle', null)

            store.commit('orderPosition/calculateNextStep')

            store.commit('orderPosition/updateName')

        },

        setType(state, payload) {
            if (payload == null) {
                state.currentPosition.item.type_id = 0
                state.currentPosition.item.type_name = ''
            }
            else {
                state.currentPosition.item.type_id = payload.id
                state.currentPosition.item.type_name = payload.name

                store.commit('orderPosition/fillBreadcrumbs')
            }
            store.commit('orderPosition/calculateNextStep')

            store.commit('orderPosition/updateName')
        },

        setStyle(state, payload) {
            let old_style = state.currentPosition.item.style_id

            if (payload == null) {
                state.currentPosition.item.style_id = 0
                state.currentPosition.item_style_name = ''
                store.commit('orderPosition/setGrade', null)
            }
            else {
                state.currentPosition.item.style_id = payload.id
                state.currentPosition.item.style_name = payload.name
                store.commit('orderPosition/fillBreadcrumbs')
                if (old_style != payload.id && state.currentPosition.item.grade_id != 0)
                    store.commit('orderPosition/setGrade', null)
            }


            store.commit('orderPosition/updateName')
            store.commit('orderPosition/calculateNextStep')
        },

        setGrade(state, payload) {
            if (payload == null) {
                state.currentPosition.item.grade_id = 0
                state.currentPosition.item.grade_name = ''
                store.commit('orderPosition/setFrameColour', null)
                state.currentPosition.options = {default: [], available: []}
            }
            else {
                state.currentPosition.item.grade_id = payload.id
                state.currentPosition.item.grade_name = payload.name
                store.commit('orderPosition/fillBreadcrumbs')
            }
            store.commit('orderPosition/updateName')
            store.commit('orderPosition/calculateNextStep')
        },

        setFrameColour(state, payload) {
            const backupPosition = _.cloneDeep(state.currentPosition)

            if (payload == null) {
                state.currentPosition.colour = {id: 0, name: '', full_name: ''}
                state.currentPosition.insert_colour = {id: 0, name: '', full_name: ''}
                state.currentPosition.is_insert_colour_set = false
            }
            else {
                state.currentPosition.colour.id = payload.id
                state.currentPosition.colour.name = payload.short_name
                state.currentPosition.colour.full_name = payload.full_name

                // state.currentPosition.insert_colour = {
                //     id: 0,
                //     name: '',
                //     full_name: '',
                //     hexcode: '',
                //     image: '',
                //     category_id: 0,
                //     category_name: ''
                // }

              if (state.isCompleted) {
                return httpReq.put('order/setup-position',{
                  position: state.currentPosition
                })
                  .then((response) => {
                    if (response.data.pc_charge_alert === true && store.getters['orderPreview/askPcCharge']) {
                      store.commit('genericQuestion/openModal', {
                          question: 'Selected colour may incur powder coating charge depending on volume. Do you wish to proceed?',
                          skip: true,
                          skip_text: 'Don\'t ask this question for this order',
                          buttons: [
                            {
                              name: 'Yes',
                              icon: 'solid/check',
                              handler: function (skip) {
                                store.commit('orderPreview/askPcCharge', skip)
                                const rollbackHandler = () => {
                                  store.commit('orderPosition/setupPosition', backupPosition)
                                }

                                return store.dispatch('orderPosition/guessNextStep', rollbackHandler)
                              }
                            },
                            {
                              name: 'No, let me pick another colour',
                              icon: 'solid/times',
                              handler: function () {
                                state.currentPosition = _.cloneDeep(backupPosition)
                                store.commit('genericQuestion/closeModal')
                              }
                            }]
                        }
                      )
                    }else {
                      const rollbackHandler = () => {
                        store.commit('orderPosition/setupPosition', backupPosition)
                      }

                      return store.dispatch('orderPosition/guessNextStep', rollbackHandler)
                    }
                  })
              }

                store.commit('orderPosition/fillBreadcrumbs')

                store.commit('preloader/preloader', true)

                httpReq.put('order/setup-position',
                    {
                        position: state.currentPosition
                    })
                    .then((response) => {
                        if (response.data.pc_charge_alert === true && store.getters['orderPreview/askPcCharge']){
                            store.commit('genericQuestion/openModal', {
                                question: 'Selected colour may incur powder coating charge depending on volume. Do you wish to proceed?',
                                skip: true,
                                skip_text: 'Don\'t ask this question for this order',
                                buttons: [
                                    {
                                        name: 'Yes',
                                      icon: 'solid/check',
                                        handler: function (skip) {
                                            store.commit('genericQuestion/closeModal')
                                            store.commit('orderPosition/setupPosition', response.data)
                                            store.commit('orderPosition/updateName')
                                            store.commit('orderPosition/calculateNextStep')
                                            store.commit('orderPreview/askPcCharge', skip)
                                        }
                                    },
                                    {
                                        name: 'No, let me pick another colour',
                                        icon: 'solid/times',
                                        handler: function () {
                                            store.commit('genericQuestion/closeModal')
                                        }
                                    }]
                            }
                            )
                        }
                        else{
                            store.commit('orderPosition/setupPosition', response.data)
                            store.commit('orderPosition/updateName')
                            store.commit('orderPosition/calculateNextStep')

                        }

                    })
                    .catch(error => {
                        console.log(error)
                        store.commit('errorsModal/openModal', error.response.data.errors)
                    })
                    .finally(() => {
                        store.commit('preloader/preloader', false)
                    })
            }
        },

        setInsertColour(state, payload) {
            const backupPosition = _.cloneDeep(state.currentPosition)

            state.currentPosition.insert_colour.id = payload.id
            state.currentPosition.insert_colour.name = payload.short_name
            state.currentPosition.insert_colour.full_name = payload.full_name
            state.currentPosition.insert_colour.is_same_as_frame = payload.is_same_as_frame
            state.currentPosition.is_insert_colour_set = true

            if(state.isCompleted) {
                const rollbackHandler = () => {
                    store.commit('orderPosition/setupPosition', backupPosition)
                    store.commit('orderPosition/updateName')
                }
                return store.dispatch('orderPosition/guessNextStep', rollbackHandler)
            }

            store.commit('orderPosition/fillBreadcrumbs')
            store.commit('orderPosition/updateName')


            store.commit('preloader/preloader', true)

            httpReq.put('order/setup-position',
                {
                    position: state.currentPosition
                })
                .then((response) => {
                    if (response.data.pc_charge_alert === true && store.getters['orderPreview/askPcCharge']){
                        store.commit('genericQuestion/openModal', {
                                question: 'Selected colour may incur powder coating charge depending on volume. Do you wish to proceed?',
                                skip: true,
                                skip_text: 'Don\'t ask this question for this order',
                                buttons: [
                                    {
                                        name: 'Yes',
                                      icon: 'solid/check',
                                        handler: function (skip) {
                                            store.commit('orderPosition/setupPosition', response.data)
                                            store.commit('orderPosition/calculateNextStep')
                                            store.commit('orderPreview/askPcCharge', skip)
                                        }
                                    },
                                    {
                                        name: 'No, let me pick another colour',
                                        icon: 'solid/times',
                                        handler: function () {
                                            store.commit('genericQuestion/closeModal')
                                        }
                                    }]
                            }
                        )
                    }
                    else{
                        store.commit('orderPosition/setupPosition', response.data)
                        store.commit('orderPosition/calculateNextStep')

                    }


                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })


            // store.commit('orderPosition/calculateNextStep')
            // store.commit('orderPosition/updateName')
        },

        updateOption(state, payload){
            state.stop_calculation = true
            store.commit('orderPosition/optionNext', payload);
        },

        setOption(state, payload) {
            //Track changes on editing
            // let trackChanges = _.get(payload,'trackChanges', true)
            // if(state.isCompleted && trackChanges){
            //     const backup = _.cloneDeep(store.getters['orderPosition/getCurrentOptionSettings'])
            //     const optionName = state.current_option
            //     const rollbackHandler = () => {
            //         store.commit('orderPosition/setOption', {
            //             option: optionName,
            //             selection: backup,
            //             trackChanges: false,
            //         })
            //     }
            //     store.dispatch('orderPosition/trackChanges', rollbackHandler)
            // }

            var price = 0

            switch (payload.option) {
            case 'MESH':
            case 'CYL':
            case 'WHL':
            case 'INS':
            case 'LOCK':
                _.forEach(payload.selection.groups.default.choices, (i) => {
                    if (i.default == true) {
                        price = i.price
                    }
                })
                break
            case 'HNG':

                _.forEach(payload.selection.groups.hinge_type.choices, (i) => {
                    if (i.default == true)
                        price = i.price
                })

                _.forEach(payload.selection.groups.hinge_qty.choices, (i) => {
                    if (i.default == true)
                        price = price * i.components[0].qty
                })

                break
            case 'PET':
                _.forEach(payload.selection.groups.size.choices, (i) => {
                    if (i.default == true)
                        price = i.price
                })

                _.forEach(payload.selection.groups.flap.choices, (i) => {
                    if (i.default == true)
                        price += i.price
                })
                break
            case 'ACC':
                payload.selection.forEach(function (i) {
                    price += i.price
                })
                break
            case 'LFTT':
                var qty = 0
                _.forEach(payload.selection.settings, (i) => {
                    if (i == true)
                        qty += 1
                })
                price = payload.selection.choice_price * qty
                break
            case 'PSHP':
                var qty = 0
                _.forEach(payload.selection.settings, (i) => {
                    if (i == true)
                        qty += 1
                })
                price = payload.selection.choice_price * qty
                break
            case 'PRTS':
            case 'HOPH':
                var qty = 0
                _.forEach(payload.selection.choices, (i) => {
                    if (i.selected == true)
                        qty += 1
                })
                price = payload.selection.choice_price * qty
                break
            case 'ADD':
                _.each(payload.selection.groups, (i, k) => {
                    if (k != 'other') {
                        _.each(i.choices, (j) => {
                            if (j.default == true  && j.price)
                                price += j.price
                        })
                    }
                    else if (k == 'other') {
                        _.each(i.choices, (j) => {
                            if (j.default == true)
                                price += j.price
                        })
                    }
                })
                break
            case 'MIDR':
            case 'MULL':
                _.forEach(payload.selection.values, (i) => {
                    if (i.selected == true) {
                        price += i.choice_price
                    }
                })
                break

            }


            if (state.currentPosition.options.default[payload.option]) {
                state.currentPosition.options.default[payload.option].settings = payload.selection
                state.currentPosition.options.default[payload.option].option_price = price
            }
            else {
                state.currentPosition.options.available[payload.option].settings = payload.selection
                state.currentPosition.options.available[payload.option].option_price = price
            }
        },


        editItem(state, payload) {

            store.commit('preloader/preloader', true)
            httpReq.get('order/get-position',
                {
                    id: payload
                })
                .then((response) => {

                    store.commit('orderPreview/positionBuilder', true)

                    store.commit('orderPosition/setupPosition', response.data)

                    state.currentPosition.is_insert_colour_set = true

                    store.commit('orderPosition/fillBreadcrumbs')

                    store.commit('orderPosition/calculateNextStep')
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })

        },

        fillBreadcrumbs(state){
            store.commit('app/clearBradcrumbs')

            if (state.currentPosition.location != '')
                store.dispatch('app/addBreadcrumb',
                    {
                        route: {
                            step: 'Location'
                        },
                        name: state.currentPosition.location,
                        order: 10
                    }
                )

            if (state.currentPosition.item.type_id > 0)
                store.dispatch('app/addBreadcrumb',
                    {
                        route: {
                            step: 'Type'
                        },
                        name: state.currentPosition.wdt + 'x' + state.currentPosition.htl + ' - ' + state.currentPosition.item.type_name,
                        order: 20
                    }
                )

            if (state.currentPosition.item.style_id > 0)
                store.dispatch('app/addBreadcrumb',
                    {
                        route: {
                            step: 'Style'
                        },
                        name: state.currentPosition.item.style_name,
                        order: 30
                    }
                )

            if (state.currentPosition.item.grade_id > 0)
                store.dispatch('app/addBreadcrumb',
                    {
                        route: {
                            step: 'Grade'
                        },
                        name: state.currentPosition.item.grade_name,
                        order: 40
                    }
                )

            if (state.currentPosition.colour.id > 0)
                store.dispatch('app/addBreadcrumb',
                    {
                        route: {
                            step: 'Frame Colour'
                        },
                        name: state.currentPosition.colour.full_name,
                        order: 50
                    }
                )

            if (state.currentPosition.has_insert && state.currentPosition.insert_colour.id > 0) {

                store.dispatch('app/addBreadcrumb',
                    {
                        route: {
                            step: 'Insert Colour'
                        },
                        name: state.currentPosition.insert_colour.full_name,
                        order: 60
                    }
                )
            }

        },

        setupPosition(state, payload) {

            for (var k in payload) {

                state.currentPosition[k] = payload[k]
                state.currentPosition['location_org'] = payload['location']
            }
            if (state.currentPosition['htl'] > 2250) {
                let hng = _.filter(state.currentPosition['options']['default'], o => {
                    return o.code === 'HNG'
                })

                if (hng.length > 0){
                    hng = hng[0].settings
                    if (!hng.is_set && !hng.default_set) {

                        let current = _.filter(hng.groups['hinge_qty'].choices, i => {

                            return i.default === true
                        })[0].components[0].qty

                        hng.groups['hinge_qty'].choices = _.map(hng.groups['hinge_qty'].choices, i => {
                            i.default = i.components[0].qty === (current + 1)
                            return i
                        })
                        hng.default_set = true
                        store.commit('orderPosition/setOption', {option: 'HNG', selection: hng})
                    }
                }
            }

        },

        forceStep(state, payload) {

            state.current_step = payload.route.step
            if (payload.route.option) {
                state.current_option = payload.route.option
            }

        },

        optionNext(state, payload) {
            //On edit
            if(state.isCompleted) {
                // const backup = _.cloneDeep(store.getters['orderPosition/getCurrentOptionSettings'])
                // const optionName = state.current_option
                // const rollbackHandler = () => {
                //     console.log('------------Rollback handler onNEXT CLICK lock.........')
                //
                //     store.commit('orderPosition/setOption', {
                //         option: optionName,
                //         selection: backup
                //     })
                // }
                // return store.dispatch('orderPosition/guessNextStep')

            }

            var op = null
            if (state.currentPosition.options.default[payload]) {
                op = state.currentPosition.options.default[payload]
            }
            else {
                op = state.currentPosition.options.available[payload]
            }

            op.is_set = true

            if (payload == 'OSQR')
                return

            var name = ''

            switch (payload) {
            case 'MESH':
            case 'WHL':
            case 'CYL':
            case 'LOCK':
                _.forEach(op.settings.groups.default.choices, (i) => {
                    if (i.default == true)
                        name = i.name
                })
                break
            case 'HNG':
                _.forEach(op.settings.groups.hinge_type.choices, (i) => {
                    if (i.default == true)
                        name = i.name
                })

                _.forEach(op.settings.groups.hinge_qty.choices, (i) => {
                    if (i.default == true)
                        name = i.components[0].qty + ' x ' + name
                })

                break
            }


            store.dispatch('app/addBreadcrumb',
                {
                    route: {
                        step: 'Option',
                        option: payload
                    },
                    name: name
                }
            )

            store.commit('preloader/preloader', true)
            op.return_option = payload.option

            httpReq.put('order/update-option',
                {
                    payload: state.currentPosition,
                    option: payload
                })
                .then(response => {

                    store.commit('orderPosition/setupPosition', {
                        items: response.data.items,
                        options: response.data.options
                    })
                  if(!state.stop_calculation)  store.commit('orderPosition/calculateNextStep')
                  state.stop_calculation = false
                })
                .catch(error => {
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })


        },

        setWidth(state, payload) {
            state.currentPosition.wdt = payload
            state.currentPosition.wdm = payload
            state.currentPosition.wdb = payload
            store.commit('orderPosition/updateName')
        },

        setDrop(state, payload) {
            state.currentPosition.htl = payload
            state.currentPosition.htm = payload
            state.currentPosition.htr = payload
            store.commit('orderPosition/updateName')
        },

        updateName(state) {
            state.currentPosition.name = state.currentPosition.wdt + 'x' +
                state.currentPosition.htl + 'mm - ' +
                state.currentPosition.item.type_name + ' - ' +
                state.currentPosition.item.style_name + ' - ' +
                state.currentPosition.item.grade_name + ' - ' +
                state.currentPosition.colour.name

            if (state.currentPosition.insert_colour && state.currentPosition.insert_colour.id > 0 && state.currentPosition.colour.id !== state.currentPosition.insert_colour.id) {
                state.currentPosition.name += ' - ' + state.currentPosition.insert_colour.name
            }

        },




        updateOptionPrice(state, payload) {
            state.currentPosition.options[payload.group][payload.option].option_price = payload.price
            switch (payload.option) {
            case 'LOCK':
                state.currentPosition.options[payload.group][payload.option].selection.groups.default.choice_price = payload.price
                break
            case 'PET':
                state.currentPosition.options[payload.group][payload.option].selection.size.choice_price = payload.price
                break
            }
        },

        updateDimensions(state, payload) {
            state.currentPosition.wdt = payload.d.length1
            state.currentPosition.wdm = payload.d.middleH
            state.currentPosition.wdb = payload.d.length3

            state.currentPosition.htl = payload.d.length4
            state.currentPosition.htm = payload.d.middleW
            state.currentPosition.htr = payload.d.length2

            state.osqStandardFiles = payload.f

            httpReq.put('order/update-dimensions', state.currentPosition )
                .then(response => {
                    store.commit('orderPosition/setupPosition', response.data)
                    store.commit('orderPosition/calculateNextStep')
                })
                .catch(error => {
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })

        },

        uploadOSQFiles(state, id) {
            let data = store.getters['orderPosition/osqStandardFiles'];
            let hasFiles = false

            for(var v of data.entries())
            {
                hasFiles = true
                break
            }

            if (hasFiles) {
                request.make(
                    `order/upload-option-file/${_.toInteger(id)}`,
                    'post',
                    data
                ).then((response) => {
                }).catch(
                    error => {
                        store.commit('errorsModal/openModal', error.response.data.errors);
                        if (_.toInteger(error.response.status) === 401) {
                            localStorage.removeItem('user-token');
                            localStorage.removeItem('nameUser');
                            window.location = '/login';
                        }
                    }
                );
            }
        },

        saveOSQR(state, payload) {
            store.commit('preloader/preloader', true)

            httpReq.put('order/osqr',
                {
                    position: state.currentPosition
                })
                .then((response) => {
                    store.commit('orderPosition/setupPosition', response.data)
                }).catch(error => {
                store.commit('errorsModal/openModal', error.response.data.errors)
                if (error.response.status == '401') {
                    localStorage.removeItem('user-token')
                    localStorage.removeItem('nameUser')
                    window.location = '/login'
                }
            }).finally((response) => {
                store.commit('preloader/preloader', false)
            })
        }
    },

    actions: {
        updateHideBackButtonAtAccessoriesState ({commit}){
          store.commit('orderPosition/updateHideBackButtonAtAccessoriesState')
        },
        updateGrade({commit, state, dispatch}, payload) {
            const currentGradeId = state.currentPosition.item.grade_id
            const currentGradeName = state.currentPosition.item.grade_name
            const rollbackHandler = () => {
                state.currentPosition.item.grade_id = currentGradeId
                state.currentPosition.item.grade_name = currentGradeName
                // store.commit('orderPosition/fillBreadcrumbs')
                store.commit('orderPosition/updateName')
            }

            state.currentPosition.item.grade_id = payload.id
            state.currentPosition.item.grade_name = payload.name
            // store.commit('orderPosition/fillBreadcrumbs')
            store.commit('orderPosition/updateName')

            dispatch('guessNextStep', rollbackHandler)
        },
        updateStyle({commit, state, dispatch}, payload) {
            const currentStyleId = state.currentPosition.item.style_id
            const currentStyleName = state.currentPosition.item.style_name
            const rollbackHandler = () => {
                state.currentPosition.item.style_id = currentStyleId
                state.currentPosition.item.style_name = currentStyleName
                // store.commit('orderPosition/fillBreadcrumbs')
                store.commit('orderPosition/updateName')
            }

            state.currentPosition.item.style_id = payload.id
            state.currentPosition.item.style_name = payload.name
            // store.commit('orderPosition/fillBreadcrumbs')
            store.commit('orderPosition/updateName')

            dispatch('guessNextStep', rollbackHandler)
        },
        updateType({commit, state, dispatch}, payload) {
            const width = state.currentPosition.wdt
            const drop = state.currentPosition.htl
            const typeId = state.currentPosition.item.type_id
            const typeName = state.currentPosition.item.type_name

            const rollbackHandler = () => {
                store.commit('orderPosition/setWidth', width)
                store.commit('orderPosition/setDrop', drop)

                state.currentPosition.item.type_id = typeId
                state.currentPosition.item.type_name = typeName

                // store.commit('orderPosition/fillBreadcrumbs')
                store.commit('orderPosition/updateName')
            }

            commit('setWidth', payload.width)
            commit('setDrop', payload.drop)
            state.currentPosition.item.type_id = payload.type.id
            state.currentPosition.item.type_name = payload.type.name
            // store.commit('orderPosition/fillBreadcrumbs')
            store.commit('orderPosition/updateName')

            dispatch('guessNextStep', rollbackHandler)
        },
        trackChanges({commit, state}, rollbackHandler) {
            const stepName = `${state.current_step}-${state.current_option}`
            let currentStep = state.current_step
            let currentOption = state.current_option

            let last = state.stepsHistory.slice(-1)[0]
            if(state.stepsHistory.filter(i => i.name === stepName).length > 1 && last && last.name === stepName) state.stepsHistory.pop()
            state.stepsHistory.push({
                name: stepName,
                step: undefined,
                option: currentOption,
                handlers: [rollbackHandler],
                immediateHandlers: []
            })
        },
        resetStep({commit, state}){
            state.stepsHistory = []
            state.current_step = 'All Options'
            state.current_option = 'None'
        },
        rollbackStepToAllOptions({ state, dispatch }) {
            if(state.current_step === 'All Options') return

            dispatch('guessPrevStep').then( () => {
                if(state.current_step !== 'All Options') {
                    dispatch('rollbackStepToAllOptions')
                }
            })
        },
        guessNextStep( {commit, state}, rollbackHandler ) {
            state.movingBackFrom = null
            store.commit('preloader/preloader', true)
            const stepName = `${state.current_step}-${state.current_option}`
            let currentStep = state.current_step
            let currentOption = state.current_option
            let handlers = []
            if(rollbackHandler) handlers.push(rollbackHandler)
            let immediateHandlers = []

            if(state.current_step === 'Option') {
                if (state.currentPosition.options.default[currentOption]) {
                    const isSet = _.get(state.currentPosition.options.default[currentOption], 'is_set', false)
                    const immediateHandlerToRollBackIsSet = () => {
                        state.currentPosition.options.default[currentOption].is_set = isSet
                    }
                    immediateHandlers.unshift(immediateHandlerToRollBackIsSet)
                } else {
                    const isSet = _.get(state.currentPosition.options.available[currentOption], 'is_set', false)
                    const immediateHandlerToRollBackIsSet = () => {
                        state.currentPosition.options.available[currentOption].is_set = isSet
                    }
                    immediateHandlers.unshift(immediateHandlerToRollBackIsSet)
                }
                commit('addHistory', {
                    currentStep: currentStep,
                    currentOption: currentOption,
                    handlers: handlers,
                    immediateHandlers: immediateHandlers,
                })
                // let last = state.stepsHistory.slice(-1)[0]
                // if (state.stepsHistory.filter(i => i.name === stepName).length > 1 && last && last.name === stepName) state.stepsHistory.pop()
                // state.stepsHistory.push({
                //     name: stepName,
                //     step: currentStep,
                //     option: currentOption,
                //     handlers: handlers,
                //     immediateHandlers
                // })
                commit('optionNext', state.current_option)
                return
            }

            httpReq.put('order/get-item-setup', {
                step: currentStep,
                position: state.currentPosition,
                current_option: state.current_option,
            }).then(response => {
                const nextStep = _.get(response, 'data.next.next_step', 'All Options')
                const optionName = _.get(response, 'data.next.option', 'None')
                const newPosition = _.get(response, 'data.position', undefined) || undefined

                if (newPosition) {
                    const backup = _.cloneDeep(state.currentPosition)
                    const handler = () => {
                        // state.currentPosition = backup
                        store.commit('orderPosition/setupPosition', backup)
                    }
                    handlers.unshift(handler)
                    state.currentPosition = newPosition
                }
                //Set step and option
                state.current_step = _.startCase(nextStep)
                state.current_option = optionName

                _.each(state.currentPosition.options.default, (o) => {
                    store.commit('orderPosition/setOption', {
                        option: o.code,
                        selection: o.settings
                    })
                })

                store.commit('orderPosition/fillBreadcrumbs')
                store.commit('orderPosition/updateName')
            }).catch(error => {
                store.commit('errorsModal/openModal', error)
                currentStep = false
            }).finally(() => {
                store.commit('preloader/preloader', false)

                if (state.current_step === 'All Options') return state.stepsHistory = []

                commit('addHistory', {
                    currentStep: currentStep,
                    currentOption: currentOption,
                    handlers: handlers,
                    immediateHandlers: immediateHandlers,
                })

                // let last = state.stepsHistory.slice(-1)[0]
                // if (state.stepsHistory.filter(i => i.name === stepName).length > 1 && last && last.name === stepName) state.stepsHistory.pop()
                // state.stepsHistory.push({
                //     name: stepName,
                //     step: currentStep,
                //     option: currentOption,
                //     handlers: handlers,
                //     immediateHandlers
                // })

            })


        },
        // addHistory( {commit, state, dispatch} ) {
        //
        // },
        guessPrevStep( {commit, state, dispatch} ) {
            const count = state.stepsHistory.length
            const last = state.stepsHistory.slice(-1)[0]

            if(count === 0) {
                const step = state.current_step
                const option = state.current_option
                state.movingBackFrom = { step, option }

                state.current_step = 'All Options'
                state.current_option = 'None'

                return true
            }

            if(last && last.step) {
                const step = state.current_step
                const option = state.current_option
                state.movingBackFrom = { step, option }

                state.current_step = last.step
                state.current_option = last.option
                state.stepsHistory[state.stepsHistory.length - 1].step = false

                //Immediate handlers
                if(last.immediateHandlers){
                    last.immediateHandlers.forEach(h => h())
                    last.immediateHandlers = undefined
                }

                //Added to reset
                // if(last.handlers){
                //     last.handlers.forEach(h => h())
                //     last.handlers = undefined
                // }
            }
            else {
                if(state.stepsHistory.length === 1) {
                    // this.$root.$emit('navigate-back', {msg: 'Navigate all', sett: last})
                    const step = state.current_step
                    const option = state.current_option
                    state.movingBackFrom = { step, option }

                    state.current_step = 'All Options'
                    state.current_option = 'None'
                    state.stepsHistory = []
                    if(last.handlers){
                        last.handlers.forEach(h => h())
                        last.handlers = undefined
                    }
                }else {
                    let s = state.stepsHistory.pop()
                    if(s && s.handlers){
                        s.handlers.forEach(h => h())
                        s.handlers = undefined
                    }
                    dispatch('guessPrevStep')
                }
            }

        },
        // save(state, mode) {
        save({state}, mode) {
            store.commit('preloader/preloader', true)

            let d = store.getters['orderPosition/orderPosition']
            var order_id = d.order_id
            httpReq.put('order/update-item', {
              ...d,
              shouldUpdateOutOfSquareImage: state.shouldUpdateOutOfSquareImage,
              outOfSquareCustomUpload: state.currentPosition.isOutOfSquareCustomImage,
              outOfSquareImageUrl: state.currentPosition.outOfSquareImageUrl
            })
                .then((response) => {
                    if (response.data) {
                        store.commit('orderPosition/uploadOSQFiles', response.data.data.position_id);
                        if (mode == 0) {
                            //location sequence updating
                            store.commit('orderPreview/positionBuilder', false)
                            store.dispatch('orderPreview/getPreview')
                        }
                        else if (mode == 1) //create new
                        {
                            store.commit('orderPosition/cleanObject')
                            store.commit('orderPosition/setOrderId', order_id)
                            store.commit('orderPosition/nextStep', 'Location')
                        }
                        else if (mode == 2) //clone
                        {
                            store.commit('orderPosition/setupPosition', {
                                id: null,
                                order_id: order_id,
                                current_step: 'All Options'
                            })
                            store.dispatch('orderPreview/getPreview')
                        }

                        if(response.data.data.actions_sync) {
                          store.commit('orderPreview/setItemAsyncActions', response.data.data.actions_sync)
                        }
                    }
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                    if (error.response.status == '401') {
                        localStorage.removeItem('user-token')
                        localStorage.removeItem('nameUser')
                        window.location = '/login'
                    }
                })
                .finally((response) => {
                    store.commit('preloader/preloader', false)
                })
        },
    },
}
