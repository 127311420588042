import {httpReq} from '@/htttpReq'

export default {
  namespaced: true,
  state: {

    applications: [],

    loaded: false,

    user: {
      first_name: 'Firstname',
    },
  },
  getters: {
    user(state) {
      return state.user;
    },

    applications(state){
      return state.applications;
    },

    loaded(state){
      return state.loaded;
    }

  },
  mutations: {
      applications(state, payload){
        state.applications = payload;
        state.loaded = true;
      },

  },
  actions: {
      loadApplications({commit}){
          httpReq.get('dashboard/get-pending-applications')
              .then(response => {
                  commit('applications', response.data);
              })
              .catch(error => {
              })
              .finally(() => {
              })
      }
  },
};
