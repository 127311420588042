import {httpReq} from "@/htttpReq";
import store from "../store";

export default {
  namespaced: true,

  state: {
    stateList: []
  },

  getters: {
    state(state) {
      return state.stateList
    }
  },

  mutations: {
    state(state, payload){
      state.stateList = payload;
    }
  },

  actions: {
    state({commit}) {
      // store.commit('preloader/preloader', true);
      httpReq.get('site/states')
        .then(response => {
          commit('state', response.data);
        })
        .finally(() => {
          // store.commit('preloader/preloader', false);
        })
    },
  },
};
