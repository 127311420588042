import {configure, defineRule } from 'vee-validate';
import { localize } from "@vee-validate/i18n";
import en from '@vee-validate/i18n/dist/locale/en.json';
import AllRules from '@vee-validate/rules';

configure({
  generateMessage: localize('en',en),
  validateOnInput: true,
});

Object.keys(AllRules).forEach(rule => {
  defineRule(rule, AllRules[rule]);
});

defineRule('digits_without_whitespace', (value, [target],input) => {
  if (!value ||  value.replace(/ /g, '').length <=  parseInt(target)) return true

  return `The ${input.field} field must be numeric and exactly contain ${target} digits`;
});

defineRule('uri_safe', (value, [target],input) => {
  if (value && value.replace(/ /g, '').length <=  parseInt(target)) return true

  return `The ${input.field} field must be numeric and exactly contain ${target} digits`;
});

defineRule('decimal', (value, [target],input) => {
  let decimal_available = value && value.toString().split('.').length > 1;

  if (!value || value.toString().split('.').length === 1 ||
    (decimal_available && value.toString().split('.')[1].toString().length <= target)) return true

  return `The ${input.field} field must be numeric and may contain ${target} decimal points`;
});

defineRule('valid_au_postcode', (value, [target],input) => {
  let strongRegex = new RegExp("(^0(2|8|9){1}[0-9]{2})|(^[1-9]{1}[0-9]{3})$");
  if(!value || strongRegex.test(value)) return true

  return `The ${input.field} is invalid.`;
});

defineRule('minLength', (value, [target],input) => {
  if(value.length >= target) return true
  return `The ${input.field} is invalid.`;
});

defineRule('string_without_whitespace', (value, [target],input) => {
  if (!value ||  !(/\s/.test(value.toString())) ) return true

  return `The ${input.field} field cannot contain whitespace`;
});



