<template>
  <div v-if="$store.getters['success/successModalVisibility']" class="modal modal_wrap">
    <div class="modal_popup">
      <p class="text text_white">
        {{ $store.getters['success/getSuccessMessage'] }}
        <span
          class="modal__close"
          @click="hideSuccessModal()"
        >
          <svg-icon name="times"/>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import GlobalMixins from "../../Mixins/GlobalMixins";
export default {
  name: "BaseModalComponent",
  mixins : [GlobalMixins],
  props: {
    size: {
      type: String,
      default: 'sm'
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getModelSize() {
      if (!(["sm", "lg"].includes(this.size))) return 'generic-modal'
      return this.size === 'sm' ? 'generic-modal' : 'modal_full'
    }
  }
}
</script>

<style lang="scss" scoped>
.text_white {
  margin: 1.5rem 0 .5rem;
}
</style>
