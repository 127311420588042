import store from "../../store";
import {httpReq} from '@/htttpReq'


export default {
  namespaced: true,
  state: {
    loaded: false,
    sales: [
    ],
  },
  getters: {

    loaded(state){
      return state.loaded
    },

    sales(state) {
      return state.sales;
    },
  },
  mutations: {
    sales(state, payload)
    {
      state.sales = payload;
    },

      loaded(state, payload)
      {
        state.loaded = payload;
      }
  },
  actions: {
      loadSales({commit}){
          httpReq.get('dashboard/get-sales')
              .then(response => {
                  commit('sales', response.data);
                  commit('loaded', true);
              })
              .catch(error => {
              })
              .finally(() => {
              })
      }
  },
};
