export default {
  namespaced: true,
  state: {
    preloader: false,
  },
  getters: {
    preloader(state){
      return state.preloader;
    }
  },
  mutations: {
    preloader(state, payload){
      state.preloader = payload;
    }
  },
  actions: {

  },
};
