export default {
  namespaced: true,
  state: {
    items: [
      {
        img: require('../../assets/img/png/new-estimate.png'),
        text: 'Create a New Estimate',
        link: '#',
      },
      {
        img: require('../../assets/img/png/estimate.png'),
        text: 'Retrive Existing Estimate',
        link: '#',
      },
      {
        img: require('../../assets/img/png/dealer.png'),
        text: 'Find a Dealer',
        link: '#',
      },
      {
        img: require('../../assets/img/png/contact.png'),
        text: 'Contact Starline Security',
        link: '#',
      },
    ],
  },
  getters: {
    items(state) {
      return state.items;
    },
  },
  mutations: {

  },
  actions: {

  },
};
