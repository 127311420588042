import store from '../../store'
import router from '../../../router'
import {httpReq} from '@/htttpReq'

export default {
  namespaced: true,

  state: {
    customers: [],
    customer: {},
    customersSearch: null,
  },

  getters: {
    customers(state) {
      return state.customers || []
    },
    customersFixedAmount: (state) => (payload) => {
      const customersFixedAmount = state.customers.filter(function (item) {
          return item.name?.toLowerCase().includes(payload.text.toLowerCase()) ||
            item.last_name?.toLowerCase().includes(payload.text.toLowerCase()) ||
            item.name?.concat(' ',item?.last_name)?.includes(payload.text) ||
            item.email?.toLowerCase().includes(payload.text.toLowerCase()) ||
            item.phone?.toLowerCase().includes(payload.text.toLowerCase()) ||
            item.landline?.toLowerCase().includes(payload.text.toLowerCase()) ||
            item.address?.toLowerCase().includes(payload.text.toLowerCase()) ||
            item.suburb?.toLowerCase().includes(payload.text.toLowerCase()) ||
            item.postcode?.toLowerCase().includes(payload.text.toLowerCase()) ||
            item.state?.name?.toLowerCase().includes(payload.text.toLowerCase()) ||
            item.state?.code?.toLowerCase().includes(payload.text.toLowerCase())
        }).slice(0, payload.amount || 5);
      return customersFixedAmount
    },
    customer(state) {
      return state.customer || {}
    },
    customerProperty: (state) => (payload) => {
      if (payload.key_2) {
        return state.customer[payload.key]?.[payload.key_2]
      } else {
        return state.customer?.[payload.key]
      }
    },
    primaryAddress(state) {
      return state.primary_address || {}
    },
    customerID(state) {
      return state.customer?.id || null
    },
    customersSearch(state) {
      return state.customersSearch
    },
  },

  mutations: {
    customers(state, payload){
      state.customers = payload;
    },
    customer(state, payload){
      state.customer = payload;
    },
    customerProperty(state, payload){
      if (payload.key_2) {
        if (!state.customer[payload.key]) {
          state.customer[payload.key] = {}
        }
        state.customer[payload.key][payload.key_2]= payload.value;
      } else {
        state.customer[payload.key] = payload.value;
      }
    },
    primaryAddress(state, payload){
      state.primary_address = {
        address: payload.address,
        suburb: payload.suburb,
        state: payload.state,
        postcode: payload.postcode
      }
    },
    resetCustomers(state, payload){
      state.customers = []
    },
    resetCustomer(state, payload){
      state.customer = {}
    },
    customersSearch(state, payload){
      state.customersSearch = payload;
    },
  },

  actions: {
    getCustomers({state, getters, commit}, payload) {
      return httpReq.get(`ordering-portal/my-jobs/create-new/customers?filter[search]=${getters.customersSearch || ''}`
      )
      .then(response => {
        commit('customers', response.data?.data);
      })
    },
    getCustomer({state, getters, commit}, payload) {
      return httpReq.get(`ordering-portal/my-jobs/create-new/customers/${payload || getters.customerID}`
      )
        .then(response => {
          commit('customer', response.data?.data);
          commit('primaryAddress', response.data?.data);
        })
        .catch(error => {
          console.log(error)
        })
    },
    editCustomer({state, getters, commit}, payload) {
      store.commit('preloader/preloader', true);
      let parameters = {
      }
      if (payload.parameters) {
        Object.assign(parameters, payload.parameters);
      }
      return httpReq.put(`ordering-portal/my-jobs/${payload.job_id}/edit-customer/${payload.id || getters.customerID}`,parameters
      )
        .then(response => {
          commit('customer', response.data?.data);
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },
    createNewCustomer({state, getters, commit}, payload) {
      store.commit('preloader/preloader', true);
      let parameters = {
      }
      if (payload.parameters) {
        Object.assign(parameters, payload.parameters);
      }
      return httpReq.post(`ordering-portal/my-jobs/create-new/customers`, parameters
      )
        .then(response => {
          commit('customer', response.data?.data);
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },
  },
};
