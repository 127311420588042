import {httpReq} from "@/htttpReq";
import store from "../../store";

export default {
    namespaced: true,

    state: {
        providers: [],
        loadingProviders: false,
        dispatchingStatus: {
          processing: false,
          success: false,
          failed: false,
        },
    },
    mutations: {
        setProviders(state, providers) {
            state.providers = providers
        },
        setLoadingStatus(state, isLoading) {
            state.loadingProviders = isLoading
        },
        setDispatchingStatus(state, payload) {
            state.dispatchingStatus = payload
        },
        markDispatched(state, orderId) {
            let orders = store.state['production'].productionOrders
            let filtered = orders.filter(o => o.order_id !== orderId)
            store.commit('production/productionOrders', filtered)
        }
    },
    actions: {
        async fetchProviders({commit}) {
            commit('setLoadingStatus', true)
            store.commit('preloader/preloader', true)
            const response = await httpReq.get('starline/shipment/get')
            commit('setProviders', response.data)
            commit('setLoadingStatus', false)
            store.commit('preloader/preloader', false)

        },
        async dispathOrder({commit, state}, {orderId, shipping}) {
            commit('setDispatchingStatus', {...state.dispatchingStatus, processing: true, success: false, failed: false })
            store.commit('preloader/preloader', true)
            commit('setDispatchingStatus', true)
            try {
                const response = await httpReq.post(`order/dispatch/${orderId}`, {shipping: shipping})
              commit('setDispatchingStatus', {...state.dispatchingStatus, processing: false, success: true, failed: false })
              commit('markDispatched', orderId)
            }
            catch(error) {
                store.commit('errorsModal/openModal', error.response.data.errors)
                commit('setDispatchingStatus', {...state.dispatchingStatus, processing: false, success: false, failed: true })
            }
            finally {
                store.commit('preloader/preloader', false)
            }

        }
    }
}
