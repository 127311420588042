import {httpReq} from "@/htttpReq";
import store from "@/store/store";


export default {
  namespaced: true,
  state: {
    data: [],
    selected: null,
    stats: null
  },
  getters: {
    data(state) {
      return state.data;
    },
    stats(state){
      if (!state.stats && state.selected)
        store.commit('dealers/loadStats', state.selected.id)
      return state.stats
    },
    selected(state){
      return state.selected
    }

  },
  mutations: {

    data(state, payload) {
      state.data = payload;
    },

    stats(state, payload){
      state.stats = payload
    },

    selected(state, payload){
      state.selected = payload
      state.stats = null
    },

    loadData(state, payload){

        httpReq.get('dashboard/dealers', payload)
            .then(response => {
                state.data = response.data;
            })
            .catch(error => {
            })
            .finally(() => {
            })
    },
    update(state, payload){
      let a = state.data['raw']
      state.data['raw'] = _.map(a, (i) => {
        if (i.id == payload.id)
          return payload

        return i
      })
      state.selected = payload
    },
    loadStats(state, payload){
      httpReq.get('dashboard/dealer/' + payload)
        .then(response => {
          state.stats = response.data
        })
        .catch(error => {
        })
        .finally(() => {
        })
    }
  },



  actions: {
    syncXero(state, payload){
      store.commit('preloader/preloader', true)
      httpReq.post('starline/integrations/sync-dealer-xero/' + payload)
        .then(response => {
          store.commit('dealers/update', response.data)
          if(!response.data.xero_contact_id) {
            store.commit('errorsModal/openModal', {error: ['Xero - Synchronization failed']});
          }
        })
        .catch(error => {
          store.commit('errorsModal/openModal', {error: ['Xero - Synchronization failed']});
        })
        .finally(() => {
          store.commit('preloader/preloader', false)
        })
    },
    syncZoho(state, payload){
      store.commit('preloader/preloader', true)
      httpReq.post('starline/integrations/sync-dealer-zoho/' + payload)
        .then(response => {
          store.commit('dealers/update', response.data)
          if(!response.data.zoho_crm_dealer_id || !response.data.zoho_crm_account_id) {
            store.commit('errorsModal/openModal', {error: ['Zoho - Synchronization failed']});
          }
        })
        .catch(error => {
          store.commit('errorsModal/openModal', {error: ['Zoho - Synchronization failed']});
        })
        .finally(() => {
          store.commit('preloader/preloader', false)
        })
    },
  },
};
