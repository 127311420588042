import store from '../../store'
import router from '../../../router'
import {httpReq} from '@/htttpReq'

export default {
    namespaced: true,

    state: {
        colors: {},
        filtrated: []
    },

    getters: {
        colors(state) {
            delete state.colors.debug
            return state.colors
        },
        filtrated(state) {
            return state.filtrated
        }
    },

    mutations: {
        colors(state, payload) {
            state.colors = payload
            state.filtrated = payload
        },
        filtered(state, payload) {
            state.filtrated = payload
        }
    },

    actions: {
        getColors({commit}, params) {
            let type_id = _.has(params, type_id) ? params.type_id : store.getters['orderPosition/orderPosition'].item.type_id,
                style_id = _.has(params, style_id) ? params.style_id : store.getters['orderPosition/orderPosition'].item.style_id,
                grade_id = _.has(params, grade_id) ? params.grade_id : store.getters['orderPosition/orderPosition'].item.grade_id
            store.commit('preloader/preloader', true)
            httpReq.get('order/order-colours/' + type_id + '/' + style_id + '/' + grade_id)
                .then(response => {
                    commit('colors', response.data)
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },

        getInsertColors({commit}, params) {
            let type_id = _.has(params, type_id) ? params.type_id : store.getters['orderPosition/orderPosition'].item.type_id,
                style_id = _.has(params, style_id) ? params.style_id : store.getters['orderPosition/orderPosition'].item.style_id,
                grade_id = _.has(params, grade_id) ? params.grade_id : store.getters['orderPosition/orderPosition'].item.grade_id
            store.commit('preloader/preloader', true)
            httpReq.get('order/insert-colours/' + type_id + '/'
              + style_id + '/'
              + grade_id + '/'
              + store.getters['orderPosition/orderPosition'].colour.id)
                .then(response => {

                    //   if (response.data.skip){
                    //       //this.$parent.exchangeData = 'selectOptions';
                    //       let path = store.getters['app/orderBuilderPath']('ins_color_id>' + 0);
                    //
                    //       router.push({path: path});
                    //   }
                    // else {
                    commit('colors', response.data)
                    // }
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },

        getColorsNew({commit}, params) {
            store.commit('preloader/preloader', true)
            httpReq.get('/order/item-colours',
                {
                    item_id: params
                }
            )
                .then(response => {
                    commit('colors', response.data)
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        }
    },
}
