import {httpReq} from "../../../htttpReq";
import router from "../../../router";
import store from "../../store";

export default {
  namespaced: true,

  state: {
    owners: null,
    experience: null,
    state: null,
    liabilities: null,
    showResend: false,
  },

  getters: {
    owners(state) {
      return state.owners;
    },

    experience(state) {
      return state.experience;
    },

    state(state) {
      return state.state;
    },

    liabilities(state){
      return state.liabilities;
    },
      showResend(state) {
      return state.showResend
      }
  },

  mutations: {
    updateOwners(state, payload) {
      state.owners = payload;
    },

    updateExperience(state, payload) {
      state.experience = payload;
    },

    updateState(state, payload) {
      state.state = payload;
    },

    updateLiabilities(state, payload){
      state.liabilities = payload;
    },
      showResend(state, payload){
          state.showResend = payload
      }
  },

  actions: {
    getOwners({commit}) {
      httpReq.get('site/number-of-employees')
        .then(response => {
          commit('updateOwners', response.data)
        });
    },

    getExperience({commit}) {
      httpReq.get('site/experience')
        .then(response => {
          commit('updateExperience', response.data);
        });
    },

    getState({commit}) {
      httpReq.get('site/states')
        .then(response => {
          commit('updateState', response.data);
        });
    },

    getLiabilities({commit}) {
      httpReq.get('site/public-liabilities')
        .then(response => {
          commit('updateLiabilities', response.data)
        });
    },

    postUser({commit}, credentials){
      store.commit('preloader/preloader', true);
      httpReq.post('register', credentials)
        .then(response => {
          if (response.status == 201)
            router.push({name: 'success'});
          else
            commit('showResend', true)
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors || error.response.data.error);
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        });
    },
      resendEmail(context, credential) {
          store.commit('preloader/preloader', true);
          httpReq.post('resend', {
              email: credential.email,
          })
              .then((response) => {
                  if (response.status == 200) {
                      store.commit('errorsModal/openModal', [['Email has been sent. Check your spam folder if you don\'t receive it.']])
                      commit('showResend', false)
                  }
              })
              .catch(error => {

              })
              .finally(() => {
                  store.commit('preloader/preloader', false);
              });
      },

  },
};
