import { httpReq } from '../../htttpReq';
import router from '../../router';
import store from "../store";

export default {
  namespaced: true,
  state: {
    priceList: null,
    sendMass: null,
    openModal: false,
    matrix: null,
  },
  getters: {
    priceList(state) {
      return state.priceList;
    },

    openModal(state){
      return state.openModal
    },
    matrix(state){
      return state.matrix;
    }
  },
  mutations: {
    updatePrice(state, data) {
      state.priceList = data;
    },

    openModal(state, payload){
      state.openModal = payload
    },

    closePopup(state, payload){
      state.openModal = payload;
    },

    updateMatrix(state, payload){
      state.matrix = payload;
    }
  }
  ,
  actions: {
    getPrice({ commit }) {
      const token = localStorage.getItem('user-token');
      store.commit('preloader/preloader', true);
      httpReq.get('user/price-category')
        .then((response) => {
          commit('updatePrice', response.data.data);
        })
        .catch((error) => {})
        .finally(() => {
          store.commit('preloader/preloader', false);
        });
    },


    getMatrix({ commit }) {
      const token = localStorage.getItem('user-token');
      store.commit('preloader/preloader', true);
      httpReq.get('user/item-matrix')
        .then((response) => {
          commit('updateMatrix', response.data.data);
        })
        .catch((error) => {})
        .finally(() => {
          store.commit('preloader/preloader', false);
        });
    },

    handleApprove(context, credential) {
      store.commit('preloader/preloader', true);
      httpReq.post('profile/approve', credential.userPrice)
        .then((response) => {
        })
        .catch((error) => {
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
          context.commit('openModal', false);
          router.push({name: 'dashboard'})
        });
    },
  },

};
