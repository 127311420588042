import store from '../../store'
import {httpReq} from '@/htttpReq'
import router from '@/router'



export default {
    namespaced: true,

    state: {
        productionReportSections: {
          details: {
            url: 'details',
            isLoading: false,
          },
          items: {
            url: 'items',
            isLoading: false,
          },
          cuttingLength: {
            url: 'cutting_length',
            isLoading: false,
          },
          cuttingSheet: {
            url: 'cutting_sheet',
            isLoading: false,
          },
          pcSheet: {
            url: 'pc_sheet',
            isLoading: false,
          },
          finance: {
            url: 'finance',
            isLoading: false,
          },
        },
        items: {},
        itemsLoading: false,
        itemsLoaded: false,
        locationLoadingStatus: {},
        loadingStatus: {},
        _locations: {},
        accessories: [],
        id: '',

        additionalAccessory: {
            name: '',
            mode: 'new',
            open: false,
            style: 0,
            item: 0,
            qty: 1,
            measure_type: '',
            l1_selected: false,
            l2_selected: false,
            l3_selected: false,
            l4_selected: false,
            length1: 0,
            length2: 0,
            length3: 0,
            length4: 0,
            colour: {
                id: 0,
                name: ''
            }
        },
        additionalData: [],

        editAccessory: false,
        cancel: false,
        delete: false,

        action: '',
        key: '',
        status: false,
        cloneWindow: false,
        estimateQuote: false,

        settingsWindow: false,
        totalPriceWindow: false,

        incomplete: false,
        openReject: false,

        positionBuilder: false,

        first_dimension: ''
    },

    getters: {

        isWidth(state){

            if (state.first_dimension == '' && localStorage.getItem('token')) {
                httpReq.get('user/profile')
                    .then(response => {
                        store.commit('orderPreview/setFirstDimension', response.data.prefer_entry)
                      store.commit('auth/userRole', response.data.user_role)
                    })
                    .catch()
                    .finally()
            }

            return state.first_dimension == 'width'
        },

        items(state) {
            return state.items
        },

        itemsLoading(state) {
            return state.itemsLoading
        },

        itemsLoaded(state) {
            return state.itemsLoaded
        },


        accessories(state) {
            return state.accessories
        },

        id(state) {
            return state.id
        },

        editAccessory(state) {
            return state.editAccessory
        },

        cancel(state) {
            return state.cancel
        },

        openReject(state) {
            return state.openReject
        },

        delete(state) {
            return state.delete
        },

        action(state) {
            return state.action
        },

        key(state) {
            return state.key
        },

        status(state) {
            return state.status
        },

        cloneWindow(state) {
            return state.cloneWindow
        },

        estimateQuote(state) {
            return state.estimateQuote
        },

        settingsWindow(state) {
            return state.settingsWindow
        },

        totalPriceWindow(state) {
            return state.totalPriceWindow
        },

        positionBuilder(state) {
            return state.positionBuilder
        },

        additionalAccessory(state) {
            return state.additionalAccessory
        },

        additionalData: state => position => {

            var ii = _.filter(state.additionalData, (i) => {return i.id === position})
            var i
            if (ii.length === 0){
                i = {
                    id: position,
                    loading: false,
                    accessories: [],
                    options: []
                }
                state.additionalData.push(i)
                store.dispatch('orderPreview/loadAdditionalData', i)

            }else i = ii[0]

            return i
        }
    },

    mutations: {
        updateSectionDetails(state, {sectionName, section}) {
          state.productionReportSections[sectionName] = section
        },
        cleanAccessory(state){
            state.additionalAccessory = {
                name: '',
                mode: 'new',
                open: false,
                    style: 0,
                    item: 0,
                    qty: 1,
                    measure_type: '',
                    l1_selected: false,
                    l2_selected: false,
                    l3_selected: false,
                    l4_selected: false,
                    length1: 0,
                    length2: 0,
                    length3: 0,
                    length4: 0,
                    colour: {
                    id: 0,
                        name: ''
                }
            };
        },

        setFirstDimension(state, payload){
            state.first_dimension = payload
        },

        items(state, payload) {
            state.items = payload
        },

      setLazyLoadedItems(state, payload) {
        state.items = {...state.items, ...payload}
      },


      id(state, payload) {
            state.id = payload
        },


        clearItems(state) {
            state.items = {}
        },

        openReject(state, payload) {
            state.openReject = payload
        },

        closeReject(state, payload){
            state.openReject = payload
        },



        cancel(state, payload) {
            state.cancel = payload
        },

        delete(state, payload) {
            state.delete = payload
        },

        action(state, payload) {
            state.action = payload
        },

        key(state, payload) {
            state.key = payload
        },

        status(state, payload) {
            state.status = payload
        },

        cloneWindow(state, payload) {
            state.cloneWindow = payload
        },

        estimateQuote(state, payload) {
            state.estimateQuote = payload
        },

        setSettingsWindow(state, payload) {
            state.settingsWindow = payload
        },

        setTotalPriceWindow(state, payload) {
            state.totalPriceWindow = payload
        },
        additionalAccessory(state, payload){
            for(var k in payload) {
                if (k == 'debug')
                    continue;
                state.additionalAccessory[k] = payload[k];
                state.additionalAccessory.l1_selected = payload.length1 && payload.length1 > 0;
                state.additionalAccessory.l2_selected = payload.length2 && payload.length2 > 0;
                state.additionalAccessory.l3_selected = payload.length3 && payload.length3 > 0;
                state.additionalAccessory.l4_selected = payload.length4 && payload.length4 > 0;
            }

        },


    },

    actions: {

        deleteAccessory({commit}, payload){
            store.commit('preloader/preloader', true)

            httpReq.put('order/delete-accessory', {id : payload, order_id: this.getters['orderPreview/id']} )
                .then(response => {
                })
                .catch(error => {
                })
                .finally(() => {
                    store.dispatch('orderPreview/getPreview');
                    store.commit('preloader/preloader', false)
                })
        },

        saveAccessory({commit, state}){
            store.commit('preloader/preloader', true)

            store.commit('orderPreview/additionalAccessory', {order_id: this.getters['orderPreview/id']})

            httpReq.put('order/save-accessory', state.additionalAccessory)
                .then(response => {
                    store.commit('orderPreview/cleanAccessory');
                    store.dispatch('orderPreview/getPreview');
                })
                .catch(error => {
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },

        editAccessory(state, payload) {
            store.commit('preloader/preloader', true)

            httpReq.get('order/render-accessory/' + payload.order_item_id)
                .then(response => {
                    store.commit('orderPreview/additionalAccessory', response.data);
                    store.commit('orderPreview/additionalAccessory', {open: true, mode: 'edit'});
                    store.commit('accessories/currentItem', {
                        style: response.data.style
                    });
                })
                .catch(error => {
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },

        getLocations({commit, getters, state}) {
          store.commit('preloader/preloader', true)
          httpReq.get('order/orders/' + getters['id'] + '/locations')
                .then(response => {
                  commit('setLocations', response.data)
            })
            .catch((error) => {
                console.log('Error while loading locations', error)
            })
            .finally(() => {
                store.commit('preloader/preloader', false)
            })
        },

        async loadLocation({commit, getters}, name ) {
          let locName = name.toString().toLocaleLowerCase().trim()
          commit('locationLoadingStatus', {name: locName, isLoading: true, error: null })

          await httpReq.get('order/orders/' + getters['id'] + '/locations/' + encodeURIComponent(locName) + '/items')
              .then(response => {
                commit('updateLocationDetails', {name: locName, data: response.data})
                commit('locationLoadingStatus', {name: locName, isLoading: false, error: null })
              })
              .catch(error => {
                commit('locationLoadingStatus', {name: locName, isLoading: false, error: error })
              })
          return true
        },

        cleanLocation({dispatch, commit, getters}) {
          httpReq.get('order/orders/' + getters['id'] + '/locations')
            .then(({data}) => {
              commit('removeExtraLocations', data)
            })
        },

        async reloadLocation({dispatch, commit}, name ) {
            dispatch('getOrderSummary')
            await dispatch('loadLocation', name)
            dispatch('cleanLocation')
        },

        sortLocations({ getters, dispatch }, updatedLocationList) {
            const orderedList = updatedLocationList.map(i => i.name)
            httpReq.put(`order/orders/${getters['id']}/locations/sort`, orderedList)
              .then(response => {
              })
              .catch(error => {
              })
        },

        getItems({commit, getters}) {
          store.commit('preloader/preloader', true)
          commit('itemsLoading', true)
          httpReq.get('order/orders/' + getters['id'] + '/items')
            .then(response => {
              commit('bulkLoadLocationItems', response.data)
              commit('itemsLoaded', true)
              commit('itemsLoading', false)
            })
            .catch((error) => {
                console.log('Error while loading items', error)
            })
            .finally(() => {
              store.commit('preloader/preloader', false)
              commit('itemsLoading', false)
            })
        },

        loadAdditionalData({commit}, payload){
            httpReq.get('order/orders/additional/' + payload.id)
                .then(response => {
                    commit('updateAdditionalData', {item: payload, data: response.data})
                })
                .catch({

                })
        },

        getOrderSummary({commit, getters}) {
          httpReq.get('order/orders/' + getters['id'] + '/summary')
            .then(response => {
              commit('updateOrderSummary', response.data)
            })
            .catch((error) => {
            })
            .finally(() => {
            })
        },

        getPreview({commit, dispatch}, silent) {
            if (silent !== true)
                store.commit('preloader/preloader', true)

            store.commit('orderPreview/items', {locations: {}})
            dispatch('getOrderSummary')
            dispatch('getLocations')
            dispatch('getItems')

            httpReq.get('order/order/' + this.getters['orderPreview/id'])
                .then(response => {
                    commit('items', response.data)
                    if (silent !== true)
                        store.commit('preloader/preloader', false)
                })
                .catch(error => {
                    if (silent !== true)
                        store.commit('preloader/preloader', false)
                })
                .finally(() => {
                })
        },

        getPreviewLessInfo({commit, dispatch}, silent) {
          if (silent !== true)
            store.commit('preloader/preloader', true)

          store.commit('orderPreview/items', {locations: {}})
          dispatch('getOrderSummary')
          dispatch('getLocations')
          httpReq.get('order/order/' + this.getters['orderPreview/id'])
            .then(response => {
              commit('items', response.data)
              if (silent !== true)
                store.commit('preloader/preloader', false)
            })
            .catch(error => {
              if (silent !== true)
                store.commit('preloader/preloader', false)
            })
            .finally(() => {
            })
        },

        getPreviewGuest({commit}, credential) {
            store.commit('preloader/preloader', true)
            httpReq.get('site/order-preview',
                {
                    params:
            {
                order_id: credential.query.order_id,
                key: credential.query.key,
            }
                })
                .then(response => {
                    commit('items', response.data)
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },

        cloneOrder({commit, dispatch}, credential) {
            store.commit('preloader/preloader', true)
            httpReq.put('order/clone',
                {
                    id: credential
                })
                .then(response => {
                    localStorage.setItem('order_id', response.data.order_id)
                    commit('id', response.data.order_id)
                    router.push(`/order-preview/${response.data.order_id}`)
                    dispatch('getPreview')

                    commit('cloneWindow', true)
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },

        deleteOrder({commit, dispatch}, credential) {
            store.commit('preloader/preloader', true)
            httpReq.delete('order/delete',
                {
                    data: {
                        order_id: credential
                    }
                })
                .then(response => {
                    if (response.status === 200) {
                        router.push({name: 'orderList'})
                    }
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },

        async submitOrder({commit, dispatch}, credential) {
            store.commit('preloader/preloader', true)
            await httpReq.post('order/submit-order',
                {
                    order_id: credential
                })
                .then(response => {
                    // if (response.status === 200) {
                    //     router.push({name: 'orderList'})
                    //     store.dispatch('orderItems/getOrders', {clear: true})
                    // }
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {

                    store.commit('preloader/preloader', false)
                })
        },

        productionReport({commit, dispatch}, credential) {
            store.commit('preloader/preloader', true)
            httpReq.get('order/order-report/' + credential)
                .then(response => {
                    if (response.status === 200) {
                        router.push('/order-report/' + credential)
                    }
                    commit('items', response.data)
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },

      async loadProductionReportSection({commit, state}, {sectionName, orderNumber}) {
          let section = _.cloneDeep(state.productionReportSections[sectionName])
          commit('updateSectionDetails', {sectionName, section: {...section, isLoading: true} })
          const response = await httpReq.get(`order/order-report/${orderNumber}/${section.url}`)
          commit('setLazyLoadedItems', response.data)
          commit('updateSectionDetails', {sectionName, section: {...section, isLoading: false} })
      },
      productionReportLazyLoad({commit, dispatch}, orderNumber) {
        dispatch('loadProductionReportSection', {sectionName: 'details',orderNumber})
        dispatch('loadProductionReportSection', {sectionName: 'items',orderNumber})
        dispatch('loadProductionReportSection', {sectionName: 'cuttingLength',orderNumber})

        dispatch('loadProductionReportSection', {sectionName: 'cuttingSheet',orderNumber})
        dispatch('loadProductionReportSection', {sectionName: 'pcSheet',orderNumber})
        dispatch('loadProductionReportSection', {sectionName: 'finance',orderNumber})

      },

        productionReview({commit, dispatch}, credential) {
            store.commit('preloader/preloader', true)
            httpReq.get('order/prod-review/' + credential)
                .then(response => {
                    if (response.status === 200) {
                        router.push('/prod-review/' + credential)
                    }
                    commit('items', response.data)
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },

        sendOrderToProd({commit, dispatch}, credential) {
            store.commit('preloader/preloader', true)
            httpReq.post('order/produce/' + credential)
                .then(response => {
                    if (response.status === 200) {
                        router.push({name: 'productionList'})
                        store.commit('production/loaded', false)
                    }
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },

        rejectOrderProd({commit, dispatch}, payload) {
            store.commit('preloader/preloader', true)
            httpReq.post('order/reject-order', payload)
                .then(response => {
                    if (response.status === 200) {
                        router.push({name: 'productionList'})
                        store.commit('production/loaded', false)
                    }
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },

        completeOrder({commit, dispatch}, credential) {
            store.commit('preloader/preloader', true)
            httpReq.post('order/complete/' + credential)
                .then(response => {
                    if (response.status === 200) {
                        router.push({name: 'productionList'})
                        store.commit('production/loaded', false)
                    }
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },

        async quoteOrder({commit}, credential) {
            store.commit('preloader/preloader', true)
            await httpReq.post('order/send-quote',
                {
                    order_id: credential
                })
                .then(response => {
                    if (response.status === 200) {
                        // router.push({name: 'orderList'});
                        commit('action', 'quote')
                        commit('estimateQuote', true)
                        // store.dispatch('orderItems/getOrders')
                    }
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },

        async estimateOrder({commit}, credential) {
            store.commit('preloader/preloader', true)
            await httpReq.post('order/send-estimate',
                {
                    order_id: credential
                })
                .then(response => {
                    if (response.status === 200) {
                        // router.push({name: 'orderList'});
                        commit('action', 'estimate')
                        commit('estimateQuote', true)
                        // store.dispatch('orderItems/getOrders')
                    }
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },

        cancelOrder({commit}, credential) {
            store.commit('preloader/preloader', true)
            httpReq.post('order/cancel',
                {
                    order_id: credential
                })
                .then(response => {
                    if (response.status === 200) {
                        router.push({name: 'orderList'})
                    }
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },

        approveOrder({commit, state}, id) {
            store.commit('preloader/preloader', true)
            httpReq.post('site/send-order-approve',
                {
                    order_id: id,
                    key: state.key,
                })
                .then(response => {
                    if (response.status === 200) {
                        commit('status', true)
                    }
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },

        rejectOrder({commit, state}, credential) {
            store.commit('preloader/preloader', true)
            httpReq.post('site/send-order-reject',
                {
                    order_id: state.id,
                    key: state.key,
                    comment: credential
                })
                .then(response => {
                    if (response.status === 200) {
                        router.push({name: 'orderList'})
                    }
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },

        reviewOrder({commit, state}, credential) {
            store.commit('preloader/preloader', true)
            httpReq.post('site/send-order-review',
                {
                    order_id: state.id,
                    key: state.key,
                    comment: credential
                })
                .then(response => {
                    if (response.status === 200) {
                        router.push({name: 'orderList'})
                    }
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },
    },
}
