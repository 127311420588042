import store from "../../store";
import router from "../../../router";
import {httpReq} from "@/htttpReq";

export default {
  namespaced: true,

  state: {
    packages: {
      data: []
    },

    isEdit: false,
    isEditFromSet: false,
    isEditFromPackage: false,
    packageId: 0,
    package: {},

    packagesForOrder: {},

    cloneModal: false
  },

  getters: {
    packages(state) {
      return state.packages;
    },

    IsEdit(state) {
      return state.isEdit;
    },
    IsEditFromSet(state) {
      return state.isEditFromSet;
    },
    IsEditFromPackage(state) {
      return state.isEditFromPackage;
    },

    packagesForOrder(state) {
      return state.packagesForOrder;
    },
    count(state) {
      return state.count;
    },

    cloneModal(state){
      return state.cloneModal
    },

    packageId(state){
      return state.packageId
    }
  },

  mutations: {
    setClone(state, payload){
      state.cloneModal = payload;
    },

    setIsEdit(state, payload) {
      state.isEdit = payload;
    },
    setIsEditFromSet(state, payload) {
      state.isEditFromSet = payload;
    },
    setIsEditFromPackage(state, payload) {
      state.isEditFromPackage = payload;
    },

    updatePackages(state, payload) {
      state.packages = payload;
    },
    setTypesStylesGrades(state, payload) {
      state.typesStylesGrades = payload;
    },
    packageId(state, payload) {
      state.packageId = payload;
    },

    updatePackagesForOrder(state, payload) {
      state.packagesForOrder = payload;
    },

    clearPackage(state) {
      state.packages = {};
      state.packageId = 0;
    },
  },

  actions: {

    getPackage({commit, state}, id) {
      httpReq.get('package/package/' + id).then(response => {
        state.package = response.data;
      }).catch(error => {
        store.commit('errorsModal/openModal', error.response.data.errors);
      }).finally(() => {
        store.commit('preloader/preloader', false);
      });
    },

    update({commit, state}, params) {
      let data = store.getters['createdOrder/createdOrder'];
      store.commit('preloader/preloader', true);
      httpReq.post('package/update',
        {
          package_id: state.packageId,
          set_id: '',
          name: data.items.name,
          item_id: 0,
          options: store.getters['options/selected'],
          accessories: data.items.accessories
        }).then((response) => {
        if (response.data) {
          store.commit('createdOrder/selectModal', false);
          store.commit('packages/setIsEditFromPackage', false);
          store.commit('createdOrder/cleanOrderObj');
          store.dispatch('packages/getPackages');
          router.push({name: 'packages'});
        }
      }).catch(error => {
        store.commit('errorsModal/openModal', error.response.data.errors);
      }).finally((response) => {
        store.commit('preloader/preloader', false);
      });
    },

    prepareUpdatePackage({commit, state, dispatch}, id) {
      let packageItem = _.find(state.packages.data, function (o) {
        return o.id === id;
      });
      state.packageId = _.toInteger(id);
      store.commit('createdOrder/selectModal', true);
      store.commit('createdOrder/updateType', _.toInteger(packageItem.type));
      store.commit('createdOrder/updateStyle', packageItem.styles);
      store.commit('createdOrder/updateGrade', packageItem.grades);
      store.commit('createdOrder/updateName', _.toString(packageItem.name));

      if (Object.keys(packageItem.accessories).length) {
        store.commit(
          'createdOrder/setAccessories', _.toArray(_.reduce(packageItem.accessories, (memo, item, key) => {
              item.lengthVal = item.lengthVal;
              item.mitreCut = item.mitre;
              item.name = item.get_item.name;
              item.type = item.get_item.type;
              item.typeName = '';
              memo[key] = item;
              return memo;
            }, {}
          ))
        );
      }

      if (Object.keys(packageItem.options).length) {
        store.commit('createdOrder/setOptions', _.reduce(packageItem.options, (memo, item, key) => {
            item.components = _.isUndefined(item.settings.components) ? item.components : item.settings.components;
            item.settings = _.isUndefined(item.settings.settings) ? item.settings : item.settings.settings;
            memo[key] = item;
            return memo;
          }, {}
          )
        );
      }

      dispatch('editOptionsInPackage', id);

      store.commit(
        'app/set',
        {
          k: 'breadcrumbs',
          v: [
            {
              route: {
                path: '/package-builder'
              },
              name: packageItem.name,
              disabled: true,
            },
            {
              route: {
                path: `/package-builder/${packageItem.type}`
              },
              name: packageItem.type_name,
              disabled: true,
            },
            {
              route: {
                path: `/package-builder/${packageItem.styles[0].id}`
              },
              name: 'Style',
              disabled: true,
              selected: packageItem.styles
            },
            {
              route: {
                path: `/package-builder/${packageItem.grades[0].id}`
              },
              name: 'Grade',
              disabled: true,
              selected: packageItem.grades
            },
          ]
        }
      );

      router.push({
        path: `/package-builder/${_.toInteger(packageItem.type)}/${_.toInteger(packageItem.styles[0].id)}/${_.toInteger(packageItem.grades[0].id)}`
      })
    },

    editOptionsInPackage({commit}, id) {
      store.commit('preloader/preloader', true);
      httpReq.get('package/package/' + id)
        .then(response => {
          store.commit('options/setOptionsToOrder', response.data);
        })
        .catch(error => {
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },

    postPackages({commit}, credantials) {
      let data = store.getters['createdOrder/createdOrder'];
      let style_id = store.getters['createdOrder/filterStyle'];
      let grade_id = store.getters['createdOrder/filterGrade'];
      store.commit('preloader/preloader', true);
      httpReq.post('package/create',
        {
          name: data.items.name,
          all_users: data.items.allUsers,
          type: data.items.type_id,
          styles: style_id,
          grades: grade_id,
          accessories: data.items.accessories,
          options: store.getters['options/selected'],
        }
      )
        .then((response) => {
          if (response.data) {
            store.commit('createdOrder/selectModal', false);
            store.commit('createdOrder/cleanOrderObj');
            store.dispatch('packages/getPackages');
          }
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors);
          if (error.response.status == '401') {
            localStorage.removeItem('user-token');
            localStorage.removeItem('nameUser');
            window.location = '/login';
          }
        })
        .finally((response) => {
          store.commit('preloader/preloader', false);
        });
    },

    getPackages({commit}) {
      store.commit('preloader/preloader', true);
      httpReq.get('package/all')
        .then(response => {
          commit('updatePackages', response.data);
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors);
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },

    delPackage({commit}, id) {
      store.commit('preloader/preloader', true);
      httpReq.delete('package/delete?id=' + id)
        .then(response => {
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors);
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },

    getPackageSingleOption({commit}, credentials) {
      store.commit('preloader/preloader', true);
      let config = {
        params: {
          type: store.getters['createdOrder/createdOrder'].items.type_id,
          styles_id: store.getters['createdOrder/filterStyle'],
          grades_id: store.getters['createdOrder/filterGrade'],
          option_name: credentials ? credentials : '',
        }
      };

      httpReq.get('package/package-single-option', config)
        .then(response => {
          store.commit('options/openDefaultOption', response.data);
        })
        .catch(error => {
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },

    editSingleOptionInPackage({commit}, credentials) {
      store.commit('preloader/preloader', true);
      httpReq.get('package/package-option', {
        option_name: credentials.option,
        package_id: credentials.packageId
      })
        .then(response => {
          store.commit('options/openDefaultOption', response.data);
        })
        .catch(error => {
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },

    getPackageToOrder({commit}) {
      store.commit('preloader/preloader', true);
      httpReq.get('order/order-packages?type_id=' + store.getters['createdOrder/createdOrder'].items.type_id + '&grade_id=' + store.getters['createdOrder/createdOrder'].items.grade_id + '&style_id=' + store.getters['createdOrder/createdOrder'].items.style_id)
        .then(response => {
          let count = 0;
          for (let i in response.data.packages) {
            count += response.data.packages[i].length;
          }

          let path = store.getters['app/orderBuilderPath']('package_id>' + 0);
          if (count < 1) {
            router.push({path: path})
          }

          commit('updatePackagesForOrder', response.data);
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors);
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },

    editPackageInOrder({commit, state}, item) {
      let packageItem = _.find(state.packagesForOrder.packages, function (o) {
        for (let k in o) {
          if (o[k].id === item.id) {
            return o[k].id
          }
        }
      });
      packageItem = _.find(packageItem, function (o) {
        return o.id === item.id
      });
      state.isEdit = true;
      state.packageId = _.toInteger(item.id);

      if (packageItem.accessories !== undefined) {
        store.commit(
          'createdOrder/setAccessories',
          _.toArray(_.reduce(packageItem.accessories, (memo, item, key) => {
              item.lengthVal = {
                l1: item.length1,
                l2: item.length2,
                l3: item.length3,
                l4: item.length4,
              };
              item.mitreCut = item.mitre;
              item.name = item.name;
              item.type = item.type;
              item.typeName = '';
              memo[key] = item;
              return memo;
            }, {}
          ))
        );
      }

      if (packageItem.accessories === undefined) {
        store.commit('createdOrder/clearAccessories');
      }

      if (packageItem.options !== undefined) {
        store.commit('createdOrder/updateOptionsFromSelectedPackage', packageItem.options);
      }
    },

    clone({dispatch}, params) {
      store.commit('preloader/preloader', true);
      httpReq.post('package/clone',
        {
          id: params.id,
          name: params.name
        })
        .then((response) => {
          if (response.status === 200) {
            dispatch('getPackages');
          }
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors);
          if (error.response.status == '401') {
            localStorage.removeItem('user-token');
            localStorage.removeItem('nameUser');
            window.location = '/login';
          }
        }).finally((response) => {
        store.commit('preloader/preloader', false);
      });
    },


  },
};
