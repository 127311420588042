"use strict";

import {ifAuthenticated} from "@/router";
import store from '@/store/store';
import menu from '@/store/modules/menu';

export default  {
  path: '/orders-list',
  name: 'orderList',
  component: () => import('../../components/orders/OrdersList').then((Component) => {
    store.registerModule('module', menu);
    return Component;
  }),
  beforeEnter: ifAuthenticated,
};

