export default {
  namespaced: true,
  state_id: {
    items: [
      {
        img: require('../../assets/img/png/account.png'),
        text: 'Sign in',
        link: 'login',
      },
      {
        img: require('../../assets/img/png/customer.png'),
        text: 'Become a Wholesale Customer',
        link: 'registration',
      },
      {
        img: require('../../assets/img/png/status.png'),
        text: 'Check Application Status',
        link: 'status',
      },
      {
        img: require('../../assets/img/png/contact.png'),
        text: 'Contact Starline Security',
        link: '/',
      },
    ],
  },
  getters: {
    items(state) {
      return state.items;
    },
  },
  mutations: {

  },
  actions: {

  },
};
