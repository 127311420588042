import {httpReq} from "../../../htttpReq";
import store from "../../store";

export default {
  namespaced: true,
  state : {
    items: {},
    filtratedItems: {},
    itemsForAllProducts: {}
  },
  getters: {
    filtrateItems: state => params => {
      if (_.toInteger(params.gradeId) > 0) {
        return state.filtratedItems = _.filter(
          state.items,
          (o) => {
            return _.toInteger(o.type) === _.toInteger(params.typeId)
              && _.toInteger(o.style) === _.toInteger(params.styleId)
              && _.toInteger(o.grade) === _.toInteger(params.gradeId)
              && (
                (_.toInteger(params.profileId) > 0)
                  ? _.toInteger(o.applied_to_profile) === _.toInteger(params.profileId)
                  : true
              );
          }
        )
      } else if (_.toInteger(params.styleId) > 0) {
        return state.filtratedItems = _.filter(
          state.items,
          (o) => {
            return _.toInteger(o.type) === _.toInteger(params.typeId)
              && _.toInteger(o.style) === _.toInteger(params.styleId)
              && (
                (_.toInteger(params.profileId) > 0)
                  ? _.toInteger(o.applied_to_profile) === _.toInteger(params.profileId)
                  : true
              );
          }
        )
      } else if (_.toInteger(params.typeId) > 0) {
        return state.filtratedItems = _.filter(
          state.items,
          (o) => {
            return _.toInteger(o.type) === _.toInteger(params.typeId)
              && (
                (_.toInteger(params.profileId) > 0)
                  ? _.toInteger(o.applied_to_profile) === _.toInteger(params.profileId)
                  : true
              );
          }
        )
      } else if (_.toInteger(params.profileId) > 0) {
        return state.filtratedItems = _.filter(
          state.items,
          (o) => {
            return _.toInteger(o.applied_to_profile) === _.toInteger(params.profileId);
          }
        )
      }

      return state.filtratedItems = state.items;

    }
  },
  mutations: {},
  actions: {
    getDefaults({state, commit}, params) {
      httpReq.get('/discounts/get-current-discount').then(response => {
        if (Object.keys(response.data).length) {
          state.itemsForAllProducts = _.isNull(response.data.data) ? {} : response.data.data
        }
      }).catch(error => {
        if (error.response.status == '401') {
          localStorage.removeItem('user-token');
          localStorage.removeItem('nameUser');
          window.location = '/login';
        }
        store.commit('errorsModal/openModal', error.response.data.errors);
      }).finally(() => {
        store.commit('preloader/preloader', false);
      })
    },
    getAll({state, commit}, params) {
      httpReq.get('/discounts/all').then(response => {
        if (Object.keys(response.data).length) {
          state.items = state.filtratedItems = response.data.data
        }
      }).catch(error => {
        if (error.response.status == '401') {
          localStorage.removeItem('user-token');
          localStorage.removeItem('nameUser');
          window.location = '/login';
        }
        store.commit('errorsModal/openModal', error.response.data.errors);
      }).finally(() => {
        store.commit('preloader/preloader', false);
      })
    },
    remove({state, dispatch}, params) {
      store.commit('preloader/preloader', true);
      httpReq.delete(`/discounts/delete/${params.id}`).then((r) => {
        _.forEach(params.actions, (action) => {
          dispatch(action)
        });
      }).catch(error => {
        store.commit('errorsModal/openModal', error.response.data.errors);
      }).finally(() => {
        store.commit('preloader/preloader', false);
      })
    },
    create({state, dispatch}, params) {
      store.commit('preloader/preloader', true);
      httpReq.post('/discounts/create', params).then((response) => {
        if (response.data) {
          _.forEach(params.actions, (action) => {
            dispatch(action)
          });
        }
      }).catch(error => {
        store.commit('errorsModal/openModal', error.response.data.errors);
        if (error.response.status == '401') {
          localStorage.removeItem('user-token');
          localStorage.removeItem('nameUser');
          window.location = '/login';
        }
      }).finally((response) => {
        store.commit('preloader/preloader', false);
      });
    },
    update({state, dispatch}, params) {
      store.commit('preloader/preloader', true);
      httpReq.put('/discounts/update', params).then((response) => {
      }).catch(error => {
        store.commit('errorsModal/openModal', error.response.data.errors);

        if (error.response.status == '401') {
          localStorage.removeItem('user-token');
          localStorage.removeItem('nameUser');
          window.location = '/login';
        }
      }).finally((response) => {
        setTimeout(_ => store.commit('preloader/preloader', false), 666)
      });
    }
  }
}
