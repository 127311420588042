export const status = [
  {code:'DRFT',text:'Draft'},
  {code:'EST',text:'Estimate'},
  {code:'QOT',text:'Quote'},
  {code:'ORD',text:'Order'},
  {code:'PEND',text:'Pending Approval'},
  {code:'APPR',text:'Approved'},
  {code:'PROD',text:'In production'},
  {code:'PRODSCH',text:'Production Scheduled'},
  {code:'REVW',text:'Client Question'},

]
