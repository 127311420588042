export default {
  namespaced: true,
  state: {
    allSelected: {},
  },
  getters: {
    getSelectedOption: (state) => (name) => {
      return _.find(state.allSelected, {option_name: name});
    }
  },
  mutations: {
    appendToAllSelected(state, payload) {
      state.allSelected = _.assign(state.allSelected, payload);
    },
    clearAllSelected(state, payload) {
      state.allSelected = {};
    }
  }
}
