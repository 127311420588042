import {httpReq} from "../../htttpReq";
import router from "../../router";
import store from "../store";

export default {
  namespaced: true,
  actions : {
    sendCode(context, credential){
      httpReq.post('webhook/xero-sync', {
        code: credential.code,
        state: credential.state,
        session_state: credential.session_state,
      })
    },
  },
};
