export default {
    namespaced: true,
    state: {
      price: null,
    },
    mutations: {
        setField,
    },
    getters: {
      currentPrice(state) {
        let value = 0
        if(Array.isArray(state.price)) {
          state.price.forEach(section => {
            for(const key in section) {
              value += section[key]
            }
          })
        }
        return value
      }
    }
}

function setField(state, { field, value }) {
  if (!field) return
  state[field] = value
}