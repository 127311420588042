import Vue from 'vue';
import store from "../../store";
import router from "../../../router";
import {httpReq} from "@/htttpReq";
import packages from "../packages/packages";

export default {
  namespaced: true,

  state: {

    orderPosition: {
      location: '',
      type: {
        id: 0,
        name: ''
      },
      style: {
          id: 0,
          name: ''
      },
      grade: {
          id: 0,
          name: ''
      },
      item_id: 0,
      dimensions: {
        wdt: 0,
        wdm: 0,
        wdb: 0,
        htl: 0,
        htm: 0,
        htr: 0
      },
      colour: {
          id: 0,
          short_name: '',
          full_name: ''
      },
      insert_colour: {
          id: 0,
          short_name: '',
          full_name: ''
      },
      options: []
    },

    createdOrder: {
      id: '',
      user_id: '',
      itemId: '',
      items: {
        name: '',
        location: '',
        id_location: '',
        type_id: '',
        style_id: [],
        grade_id: [],
        package_id: '',
        colors: '',
        options: {},
        insert_color: {},
        width: '',
        drop: '',

        allUsers: false,
        accessories: []
      }
    },

    osqStandardFiles: new FormData(),
    selectModal: false,
    additionalAccessories: false,
    warningModal: false,
    deleteModal: false,

    warningOutOfSquare: false,

    modals: {
      selectType: false,
      selectStyle: false,
      selectGrade: false,
      selectColors: false,
      selectInsertColors: false,
      selectOptions: false,
    }

  },

  getters: {
    osqStandardFiles(state) {
      return state.osqStandardFiles;
    },

    createdOrder(state) {
      return state.createdOrder;
    },

    filterStyle(state) {
      let arr = [];
      _.forEach(state.createdOrder.items.style_id, function (el) {
        arr.push(el.id);
        return arr;
      });
      return arr;
    },

    filterGrade(state) {
      let arr = [];
      _.forEach(state.createdOrder.items.grade_id, function (el) {
        arr.push(el.id);
        return arr;
      });
      return arr;
    },

    modals(state) {
      return state.modals;
    },

    selectModal(state) {
      return state.selectModal;
    },

    additionalAccessories(state) {
      return state.additionalAccessories;
    },

    warningModal(state) {
      return state.warningModal;
    },

    deleteModal(state) {
      return state.deleteModal;
    },

    warningOutOfSquare(state) {
      return state.warningOutOfSquare;
    }
  },

  mutations: {
    setOsqStandardFiles(state, payload) {
      state.osqStandardFiles = payload;
    },

    selectModal(state, payload) {
      state.selectModal = payload;
    },

    additionalAccessories(state, payload) {

      state.additionalAccessories = payload;
    },

    warningModal(state, payload) {
      state.warningModal = payload;
    },

    deleteModal(state, payload) {
      state.deleteModal = payload;
    },

    warningOutOfSquare(state, payload) {
      state.warningOutOfSquare = payload;
    },


    updateLocation(state, payload) {
      state.createdOrder.items.location = payload;
    },

    updateIdLocation(state, payload) {
      state.createdOrder.items.id_location = payload;
    },

    updateType(state, payload) {
      state.createdOrder.items.type_id = payload;
    },

    updateStyle(state, payload) {
      state.createdOrder.items.style_id = payload;
    },

    updateGrade(state, payload) {
      state.createdOrder.items.grade_id = payload;
    },

    updateColors(state, payload) {
      state.createdOrder.items.colors = payload;
    },

    changeInsertColour: (state, payload) => {
        state.createdOrder.items.insert_color = payload;
    },

    updateOptionsSettings(state, payload) {
      state.createdOrder.items.options = {...state.createdOrder.items.options};

      if (_.has(state.createdOrder.items.options, payload.components[0].component_type)) {
        state.createdOrder.items.options[payload.components[0].component_type] = payload;
      }

      Vue.set(
        state.createdOrder.items.options,
        payload.components[0].component_type,
        payload
      );
      // state.createdOrder.items.options.push(payload);
    },

    updateOptionsFromSelectedPackage(state, payload) {
      state.createdOrder.items.options = payload;
    },

    setOptions(state, payload) {
      state.createdOrder.items.options = payload;
    },

    addAccessories(state, payload) {
      state.createdOrder.items.accessories = payload;
    },

    setAccessories(state, payload) {
      state.createdOrder.items.accessories = payload;
    },

    createAccessory(state, payload) {
      if (state.createdOrder.items.accessories === '') {
        state.createdOrder.items.accessories = [];
        state.createdOrder.items.accessories.push(payload);
      }
      else {
        state.createdOrder.items.accessories.push(payload);
      }
    },

    delAccessory(state, payload) {
      state.createdOrder.items.accessories.splice(payload, 1);
    },

    clearAccessories(state) {
      state.createdOrder.items.accessories = []
    },

    updateAccessory(state, payload) {
      state.createdOrder.items.accessories[payload.id].lengthVal = payload.length;
      state.createdOrder.items.accessories[payload.id].mitreCut = payload.mitreCut;
      state.createdOrder.items.accessories[payload.id].qty = payload.qty;
      state.createdOrder.items.accessories[payload.id].color = payload.color;
    },

    updateOrderId(state, payload) {
      state.createdOrder.id = payload;
    },

    updateUserId(state, payload) {
      state.createdOrder.user_id = payload;
    },

    updateWidth(state, payload) {
      state.createdOrder.items.width = payload;
    },

    updateDrop(state, payload) {
      state.createdOrder.items.drop = payload;
    },

    updateName(state, payload) {
      state.createdOrder.items.name = payload;
    },

    updateForAllUsers(state, payload) {
      state.createdOrder.items.allUsers = payload;
    },

    editingModals(state, payload) {
      state.modals[payload] = true;
    },

    cleanOrderObj(state) {
      state.createdOrder.id = '';
      state.createdOrder.user_id = '';
      for (let k in state.createdOrder.items) {
        state.createdOrder.items[k] = ''
      }
    },

    updatePackagesOrder(state, payload) {
      state.createdOrder.items.package_id = payload;
    },

    updateOrderInsert(state, payload) {
      state.createdOrder.items.ins = payload;
    },

    updateOrderMesh(state, payload) {
      state.createdOrder.items.mesh = payload;
    },

    clearPackagesOrder(state) {
      state.createdOrder.items.package_id = '';
      state.createdOrder.items.accessories = [];
      state.createdOrder.items.options = [];
    },

    updateItemId(state, payload) {
      state.createdOrder.itemId = payload;
    }
  },

  actions: {
    putUpdates({commit, dispatch, state}, credantials) {
      let data = store.getters['createdOrder/createdOrder'];
      store.commit('preloader/preloader', true);

      // Handle location.
      let selectedLocation = data.location;
      if (!selectedLocation && data.items.location) {
        selectedLocation = data.items.location;
      }
      if (!data.items.location) {
        let allBreadcrumbs = store.getters['app/data'].breadcrumbs;
        if (allBreadcrumbs && allBreadcrumbs[0] && allBreadcrumbs[0].name && !selectedLocation) {
          selectedLocation = allBreadcrumbs[0].name;
        }
      }

      const clonedItems = JSON.parse(JSON.stringify(data.items));
      clonedItems.location = selectedLocation;

      httpReq.put('order/order',
        {
          id: data.id,
          locations: selectedLocation,
          items: [
            {
              location: selectedLocation,
              items: clonedItems
            }
          ]
        }).then((response) => {
        if (response.data.data) {
          if (response.data.data.order_position_option_id) {
            store.dispatch('orderItem/uploadOSQFiles', _.toInteger(response.data.data.order_position_option_id))
          }
          store.commit('createdOrder/selectModal', false);
          store.commit('options/cleanOptions');
          store.commit('options/setOldOptions', {});
          store.commit('createdOrder/cleanOrderObj');
          store.dispatch('orderPreview/getPreview');
        }
      }).catch(error => {
        store.commit('errorsModal/openModal', error.response.data.errors);
        if (error.response.status == '401') {
          localStorage.removeItem('user-token');
          localStorage.removeItem('nameUser');
          window.location = '/login';
        }
      })
        .finally((response) => {
          store.commit('preloader/preloader', false);
        });

    },

    putUpdatesAdditionalAccessory({commit, dispatch, state}, credantials) {

      let data = store.getters['createdOrder/createdOrder'];
      store.commit('preloader/preloader', true);

      httpReq.put('order/order-additional-accessory',
        {
          id: data.id,
          locations: data.location,
          items: [
            {
              location: data.location,
              items: data.items
            }
          ]
        }).then((response) => {
        if (response.data.data) {
          store.commit('createdOrder/selectModal', false);
          store.commit('createdOrder/cleanOrderObj');
          store.dispatch('orderPreview/getPreview');
        }
      })

        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors);

          if (error.response.status == '401') {
            localStorage.removeItem('user-token');
            localStorage.removeItem('nameUser');
            window.location = '/login';
          }

        })

        .finally((response) => {
          store.commit('preloader/preloader', false);
        });

    },

    postOrder({commit}, credantials) {
      let data = store.getters['createdOrder/createdOrder'];
      store.commit('preloader/preloader', true);
      httpReq.put('order/order',
        {
          id: data.id,
          locations: data.location,
          items: [
            {
              location: data.location,
              items: data.items,
            }
          ]
        })
        .then((response) => {
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors);
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        });
    }

  },
};
