import store from "../../store";
import {httpReq} from '@/htttpReq'

export default {
  namespaced: true,
  state: {
      loaded: false,
    statistics: {
      orders:
          [],
      finances:
          [],
    },
  },
  getters: {

      loaded(state){
          return state.loaded
      },
      statistics(state) {
      return state.statistics;
    },
  },
  mutations: {
      statistics(state, payload){
          state.statistics = payload;
      },
      loaded(state, payload)
      {
          state.loaded = payload;
      }
  },
  actions: {
        loadStatistics({commit}){
            httpReq.get('dashboard/get-financial')
                .then(response => {
                    commit('statistics', response.data);
                    commit('loaded', true);
                })
                .catch(error => {
                })
                .finally(() => {
                })

        }
  },
};
