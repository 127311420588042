import router from "../../router";
import store from "../store";
import preloader from "./preloader";
import _ from 'lodash'

export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('user-token') || '',
        errorMessage: [],
        status: false,
        userRole: '',
        nameUser: localStorage.getItem('nameUser') || '',
        skipPackages: '',

        successText: false,

        resetModal: false,
        abilities: JSON.parse(localStorage.getItem('abilities') || '[]'),
    },
    getters: {
        can: (state) => (abilityName) => {
            return !!state.abilities.find(ability => _.toLower(ability.name) === _.toLower(abilityName))
        },
        canSeePrice: (state, getters) => {
            return getters.can('see price')
        },
        canSeeCost: (state, getters) => {
            return getters.can('see cost')
        },
        showPrice: (state, getters) => (area) => {
            return area === 'my-orders'? getters.can('see cost'): getters.can('see price')
        },
        
        isAuthenticated(state) {
            return !!state.token;
        },

        token(state) {
            return state.token;
        },

        errorMessage(state) {
            return state.errorMessage;
        },

        status(state) {
            return state.status;
        },

        nameUser(state) {
            return state.nameUser;
        },

        getUserRole(state) {
            return state.userRole;
        },

        successText(state) {
            return state.successText;
        },
        skipPackages(state) {
            return state.skipPackages;
        },

        resetModal(state) {
            return state.resetModal;
        }

    },
    mutations: {
        setAbilities: function (state, payload) {
            state.abilities = payload
        },

        closeModal(state) {
            state.status = false
            state.errorMessage.length = 0
        },

        authLogout(state) {
            state.token = ''
        },

        updateLogin(state) {
            state.token = localStorage.getItem('user-token')
        },

        isNotAuth(store, payload) {
            store.token = payload
        },

        nameLogin(state, payload) {
            state.nameUser = payload
        },

        userRole(state, payload) {
            state.userRole = payload
        },

        successText(state, payload) {
            state.successText = payload
        },

        skipPackages(state, payload) {
            state.skipPackages = payload;
        },

        updateResetModal(state, payload) {
            state.resetModal = payload;
        }
    },
    actions: {
        // updateAbilitiesDelayed: _.debounce(({dispatch}) => {
        //     dispatch('updateAbilities')
        // }, 300),

        async getSettingsMenu() {
            store.commit('preloader/preloader', true);
            const response = await request.make('user/wholesale/settings-menu', 'get')
            store.commit('preloader/preloader', false);
            return response
        },

        async updateAbilities({commit}) {
            const response = await request.make('user/get_my_rights', 'get')
            const result = response.data.data.permissions.map(x => {
                return {id: x.id, name: x.name}
            })
            localStorage.setItem('abilities', JSON.stringify(result) || '[]');
            commit('setAbilities', result)
            return response
        },

        login(context, credential) {
            // debugger;
            store.commit('preloader/preloader', true);

            request.make('login', 'post',
                {
                    email: credential.email,
                    password: credential.password,
                }
            ).then((response) => {

                request.login({token: response.data.token});

                context.commit('updateLogin');
                context.commit('nameLogin', response.data.name);
                context.commit('userRole', response.data.user_role);
                context.commit('skipPackages', response.data.skip_packages);

                localStorage.setItem('user_role', response.data.user_role);
                localStorage.setItem('nameUser', response.data.name);
                localStorage.setItem('skip_packages', response.data.skip_packages);

                context.dispatch('updateAbilities').then(() => {

                  const redirectRouteObject = localStorage.getItem('redirectRouteObject');

                  if (!redirectRouteObject) {
                      router.push({name: 'dashboard'});
                    } else {
                        localStorage.removeItem('redirectRouteObject');
                        window.location = JSON.parse(redirectRouteObject).fullPath;
                    }
                })


            }).catch(error => {
                store.commit('errorsModal/openModal', error.response.data.errors);
                request.logout()
            }).finally(() => {
                store.commit('preloader/preloader', false);
            });
            // >>>>>>>
            //     1199588195655026 - Implement - user - rights - for-multi - user - accounts
        },
        forgotPass(context, credential) {
            store.commit('preloader/preloader', true)
            request.make('request-reset-password', 'post',
                {
                    email: credential.email,
                }
            )
                .then((response) => {
                    //context.commit('updateResetModal', false)
                    if (response.status === 201 || response.status === 200) {
                        context.commit('successText', true)
                    }

                })
                .catch(error => {
                    localStorage.removeItem('user-token')
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },


        logout(context) {
            request.logout();
            context.commit('authLogout');
            localStorage.removeItem('user-token'); // TODO deprecated
            localStorage.removeItem('nameUser');
            localStorage.removeItem('user_role');
            localStorage.removeItem('skip_packages');
            localStorage.removeItem('abilities')
            context.commit('updateLogin');
            router.push({name: 'home'});
        },

        closeModal(context) {
            context.commit('closeModal');
        },
    },
}
