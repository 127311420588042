export default {
  namespaced: true,
  state: {
    axioErrors: {},
    errorModalVisibility: false
  },
  getters: {
    getErrors(state) {
      return state.axioErrors
    },
    errorModalVisibility(state) {
      return state.errorModalVisibility
    }
  },
  mutations: {
    SET_ERRORS(state, errors) {
      state.axioErrors = errors
    },
    REMOVE_ERRORS(state) {
      state.axioErrors = {}
    },
    HIDE_MODAL(state) {
      state.errorModalVisibility = false
    },
    SHOW_MODAL(state) {
      state.errorModalVisibility = true
    }
  },
  actions: {

    setErrors({commit}, errors) {
      commit('SET_ERRORS', errors);
      commit('SHOW_MODAL');
    },
    removeErrors({commit}) {
      commit('REMOVE_ERRORS');
      commit('HIDE_MODAL');
    }
  },
}
