import store from '../../store'
import {httpReq} from '@/htttpReq'
import router from '@/router'


export default {
  namespaced: true,

  state: {
    items: {},
    itemsLoading: false,
    itemsLoaded: false,
    locationLoadingStatus: {},
    _locations: {},
    accessories: [],
    id: '',
    askPcCharge: true,

    additionalAccessory: {
      name: '',
      mode: 'new',
      open: false,
      style: 0,
      item: 0,
      qty: 1,
      measure_type: '',
      l1_selected: false,
      l2_selected: false,
      l3_selected: false,
      l4_selected: false,
      length1: 0,
      length2: 0,
      length3: 0,
      length4: 0,
      colour: {
        id: 0,
        name: ''
      }
    },
    additionalData: [],

    editAccessory: false,
    cancel: false,
    delete: false,

    action: '',
    key: '',
    status: false,
    cloneWindow: false,
    estimateQuote: false,

    settingsWindow: false,
    totalPriceWindow: false,

    incomplete: false,
    openReject: false,

    positionBuilder: false,

    first_dimension: '',

    customer_action: null
  },

  getters: {

    customerAction(state){
      return state.customer_action
    },

    isWidth(state) {
      if (!localStorage.getItem('token')) return
      if (state.first_dimension == '') {
        httpReq.get('user/profile')
          .then(response => {
            store.commit('orderPreview/setFirstDimension', response.data.prefer_entry)
            store.commit('auth/userRole', response.data.user_role)
          })
          .catch()
          .finally()
      }

      return state.first_dimension == 'width'
    },

    items(state) {
      return state.items
    },

    itemsLoading(state) {
      return state.itemsLoading
    },

    itemsLoaded(state) {
      return state.itemsLoaded
    },


    accessories(state) {
      return state.accessories
    },

    id(state) {
      return state.id
    },

    editAccessory(state) {
      return state.editAccessory
    },

    cancel(state) {
      return state.cancel
    },

    openReject(state) {
      return state.openReject
    },

    delete(state) {
      return state.delete
    },

    action(state) {
      return state.action
    },

    key(state) {
      return state.key
    },

    status(state) {
      return state.status
    },

    cloneWindow(state) {
      return state.cloneWindow
    },

    estimateQuote(state) {
      return state.estimateQuote
    },

    settingsWindow(state) {
      return state.settingsWindow
    },

    totalPriceWindow(state) {
      return state.totalPriceWindow
    },

    positionBuilder(state) {
      return state.positionBuilder
    },

    additionalAccessory(state) {
      return state.additionalAccessory
    },

    additionalData: state => position => {

      var ii = _.filter(state.additionalData, (i) => {
        return i.id === position
      })
      var i
      if (ii.length === 0) {
        i = {
          id: position,
          loading: false,
          accessories: [],
          options: []
        }
        state.additionalData.push(i)
        store.dispatch('orderPreview/loadAdditionalData', i)

      } else i = ii[0]

      return i
    },
    askPcCharge(state) {
      return state.askPcCharge
    }
  },

  mutations: {
    updateFirstDimension(state, payload){
      state.first_dimension = payload
    },
    setItemAsyncActions (state,payload) {
      state.items.actions_sync = payload
    },
    customerAction(state, payload){
      state.customer_action = payload
    },

    askPcCharge(state, payload) {
      state.askPcCharge = payload
    },

    cleanAccessory(state) {
      state.additionalAccessory = {
        name: '',
        mode: 'new',
        open: false,
        style: 0,
        item: 0,
        qty: 1,
        measure_type: '',
        l1_selected: false,
        l2_selected: false,
        l3_selected: false,
        l4_selected: false,
        length1: 0,
        length2: 0,
        length3: 0,
        length4: 0,
        colour: {
          id: 0,
          name: ''
        }
      };
    },

    setFirstDimension(state, payload) {
      state.first_dimension = payload
    },

    items(state, payload) {
      state.items = {...state.items, ...payload}

      state.incomplete = false
      let standardItems
      let defaultItems
      _.forEach(state.items.locations, el => {
        standardItems = _.find(el.items.standard, 'is_incomplete')
        defaultItems = _.find(el.items.default, 'is_incomplete')

        if (standardItems !== undefined || defaultItems !== undefined) {
          state.incomplete = true
          return false
        } else state.incomplete = false
      })
    },

    locationLoadingStatus(state, payload) {
      state.locationLoadingStatus = {...state.locationLoadingStatus, [payload.name]: {...payload}}
    },

    updateLocationDetails(state, payload) {
      let locations = {...state.items.locations}
      let name = payload.name.toString().trim()
      if (_.isEmpty(payload.data)) { // The location is not presented on the server, so delete it.
        delete locations[name]
      } else {
        locations[name] = {...locations[name], ...payload.data}

        _.forEach(payload.data.items.standard, (i) => {
          state.additionalData = _.filter(state.additionalData, j => {
            return j.id !== i.item_id
          })
        })
      }
      state.items = {...state.items, locations: locations}
    },

    setLocations(state, payload) {
      let locations = state.items.locations
      locations = {...locations, ...payload}
      state.items = {...state.items, locations: locations}
    },

    removeExtraLocations(state, payload) {
      let locations = state.items.locations
      for (let loc in locations) {
        if (!payload[loc]) {
          delete locations[loc]
        }
      }
      state.items = {...state.items, locations: locations}
    },

    updateAdditionalData(state, payload) {
      state.additionalData = _.map(state.additionalData, (i) => {
        if (i.id === payload.item.id) {
          i.loading = false
          i.accessories = payload.data.accessories
          i.options = payload.data.options
        }
        return i
      })
    },

    bulkLoadLocationItems(state, payload) {
      state.items = {...state.items, locations: payload}
    },

    itemsLoading(state, payload) {
      state.itemsLoading = payload
    },

    itemsLoaded(state, payload) {
      state.itemsLoaded = payload
    },

    updateOrderSummary(state, payload) {
      state.items = {...state.items, price: payload}
    },

    id(state, payload) {
      state.id = payload
    },


    positionBuilder(state, payload) {

      if (payload == false) {
        store.commit('orderPosition/cleanObject')
      }

      state.positionBuilder = payload
    },

    clearItems(state) {
      state.items = {}
    },

    openReject(state, payload) {
      state.openReject = payload
    },

    closeReject(state, payload) {
      state.openReject = payload
    },


    cancel(state, payload) {
      state.cancel = payload
    },

    delete(state, payload) {
      state.delete = payload
    },

    action(state, payload) {
      state.action = payload
    },

    key(state, payload) {
      state.key = payload
    },

    status(state, payload) {
      state.status = payload
    },

    cloneWindow(state, payload) {
      state.cloneWindow = payload
    },

    estimateQuote(state, payload) {
      state.estimateQuote = payload
    },

    setSettingsWindow(state, payload) {
      state.settingsWindow = payload
    },

    setTotalPriceWindow(state, payload) {
      state.totalPriceWindow = payload
    },
    additionalAccessory(state, payload) {
      for (var k in payload) {
        if (k == 'debug')
          continue;
        state.additionalAccessory[k] = payload[k];
        state.additionalAccessory.l1_selected = payload.length1 && payload.length1 > 0;
        state.additionalAccessory.l2_selected = payload.length2 && payload.length2 > 0;
        state.additionalAccessory.l3_selected = payload.length3 && payload.length3 > 0;
        state.additionalAccessory.l4_selected = payload.length4 && payload.length4 > 0;
      }

    },

    checkAdditionalAccessory(state, payload) {

//      if (!checkPCCharge)
//        return true

      let a = {...state.additionalAccessory, ...payload}

      store.commit('preloader/preloader', true)

      httpReq.post('order/check-acc', {acc: a, order: state.id})
        .then(response => {
            if (response.data && state.askPcCharge) {
              store.commit('genericQuestion/openModal', {
                  question: 'Selected colour may incur powder coating charge depending on volume. Do you wish to proceed?',
                  skip: true,
                  skip_text: 'Don\'t ask this question for this order',
                  buttons: [
                    {
                      name: 'Yes',
                      icon: 'solid/check',
                      handler: function (skip) {
                        store.commit('orderPreview/additionalAccessory', {
                          colour: payload.colour
                        });
                        store.commit('orderPreview/askPcCharge', skip)
                        store.commit('optionColours/openModal', false)
                      }
                    },
                    {
                      name: 'No, let me pick another colour',
                      icon: 'solid/times',
                      handler: function () {
                        return false
                      }
                    }]
                }
              )

            } else {
              store.commit('orderPreview/additionalAccessory', {
                colour: payload.colour
              });
              store.commit('optionColours/openModal', false)

            }
          }
        ).finally(() => {
          store.commit('preloader/preloader', false)
        });
    }
  },

  actions: {

    deleteAccessory({commit}, payload) {
      store.commit('preloader/preloader', true)

      httpReq.put('order/delete-accessory', {
        id: payload,
        order_id: this.getters['orderPreview/id']
      })
        .then(response => {
        })
        .catch(error => {
        })
        .finally(() => {
          store.dispatch('orderPreview/getPreview');
          store.commit('preloader/preloader', false)
        })
    },

    saveAccessory({commit, state}) {
      store.commit('preloader/preloader', true)

      store.commit('orderPreview/additionalAccessory', {order_id: this.getters['orderPreview/id']})

      httpReq.put('order/save-accessory', state.additionalAccessory)
        .then(response => {
          store.commit('orderPreview/cleanAccessory');
          store.dispatch('orderPreview/getPreview');
        })
        .catch(error => {
        })
        .finally(() => {
          store.commit('preloader/preloader', false)
        })
    },

    editAccessory(state, payload) {
      store.commit('preloader/preloader', true)

      httpReq.get('order/render-accessory/' + payload.order_item_id)
        .then(response => {
          store.commit('orderPreview/additionalAccessory', response.data);
          store.commit('orderPreview/additionalAccessory', {open: true, mode: 'edit'});
          store.commit('accessories/currentItem', {
            style: response.data.style
          });
        })
        .catch(error => {
        })
        .finally(() => {
          store.commit('preloader/preloader', false)
        })
    },

    async getLocations({commit, getters, state}) {
      store.commit('preloader/preloader', true)
      await httpReq.get('order/orders/' + getters['id'] + '/locations')
        .then(response => {
          commit('setLocations', response.data)
        })
        .catch((error) => {
          console.error('Error while loading locations', error)
        })
        .finally(() => {
          store.commit('preloader/preloader', false)
        })
    },

    async loadLocation({commit, getters}, name) {
      let locName = name.toString().toLocaleLowerCase().trim()
      commit('locationLoadingStatus', {name: locName, isLoading: true, error: null})

      await httpReq.get('order/orders/' + getters['id'] + '/locations/' + encodeURIComponent(locName) + '/items')
        .then(response => {
          commit('updateLocationDetails', {name: locName, data: response.data})
          commit('locationLoadingStatus', {name: locName, isLoading: false, error: null})
        })
        .catch(error => {
          commit('locationLoadingStatus', {name: locName, isLoading: false, error: error})
        })
      return true
    },

    cleanLocation({dispatch, commit, getters}) {
      httpReq.get('order/orders/' + getters['id'] + '/locations')
        .then(({data}) => {
          commit('removeExtraLocations', data)
        })
    },

    async reloadLocation({dispatch, commit}, name) {
      dispatch('getOrderSummary')
      await dispatch('loadLocation', name)
      dispatch('cleanLocation')
    },

    sortLocations({getters, dispatch}, updatedLocationList) {
      const orderedList = updatedLocationList.map(i => i.name)
      httpReq.put(`order/orders/${getters['id']}/locations/sort`, orderedList)
        .then(response => {
        })
        .catch(error => {
        })
    },

    getItems({commit, getters}) {
      store.commit('preloader/preloader', true)
      commit('itemsLoading', true)
      httpReq.get('order/orders/' + getters['id'] + '/items')
        .then(response => {
          commit('bulkLoadLocationItems', response.data)
          commit('itemsLoaded', true)
          commit('itemsLoading', false)
        })
        .catch((error) => {
          console.error('Error while loading items', error)
        })
        .finally(() => {
          store.commit('preloader/preloader', false)
          commit('itemsLoading', false)
        })
    },

    loadAdditionalData({commit}, payload) {
      httpReq.get('order/orders/additional/' + payload.id)
        .then(response => {
          commit('updateAdditionalData', {item: payload, data: response.data})
        })
        .catch({})
    },

    getOrderSummary({commit, getters}) {
      httpReq.get('ordering-portal/orders/dealer/' + getters['id'] + '/summary')
        .then(response => {
          commit('updateOrderSummary', response.data)
        })
        .catch((error) => {
        })
        .finally(() => {
        })
    },

    getPreview({commit, dispatch}, silent) {
      if (silent !== true)
        store.commit('preloader/preloader', true)

      store.commit('orderPreview/items', {locations: {}})
      dispatch('getOrderSummary')
      dispatch('getItems')
      dispatch('getLocations')

      httpReq.get('order/order/' + this.getters['orderPreview/id'])
        .then(response => {
          commit('items', response.data)
          if (silent !== true)
            store.commit('preloader/preloader', false)
        })
        .catch(error => {
          if (silent !== true)
            store.commit('preloader/preloader', false)
        })
        .finally(() => {
          dispatch('getLocations')
        })
    },

    getPreviewLessInfo({commit, dispatch, state}, {silent, loadLocationsAutomatically}) {
      if (!silent) store.commit('preloader/preloader', true)

      store.commit('orderPreview/items', {locations: {}})
      dispatch('getOrderSummary')
      dispatch('getLocations').then( () => {
        if(loadLocationsAutomatically) {
          for(let loc in state.items.locations) {
            dispatch('loadLocation', loc)
          }
        }
      })
      httpReq.get('order/order/' + this.getters['orderPreview/id'])
        .then(response => {
          commit('items', response.data)
        })
        .catch(error => {
        })
        .finally(() => {
          if (!silent) store.commit('preloader/preloader', false)
        })
    },

    getPreviewGuest({commit}, credential) {
      store.commit('preloader/preloader', true)
      httpReq.get('site/order-preview',
        {
          params:
            {
              order_id: credential.query.order_id,
              key: credential.query.key,
            }
        })
        .then(response => {
          commit('items', response.data)
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors)
        })
        .finally(() => {
          store.commit('preloader/preloader', false)
        })
    },

    cloneOrder({commit, dispatch}, credential) {
      store.commit('preloader/preloader', true)
      httpReq.put('order/clone',
        {
          id: credential
        })
        .then(response => {
          localStorage.setItem('order_id', response.data.order_id)
          commit('id', response.data.order_id)
          router.push(`/order-preview/${response.data.order_id}`).catch(() => {})
          dispatch('getPreview')
          commit('cloneWindow', true)
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors)
        })
        .finally(() => {
          store.commit('preloader/preloader', false)
        })
    },

    async deleteOrderSilent({commit, dispatch}, credential) {
      store.commit('preloader/preloader', true)
      await httpReq.delete('order/delete',
        {
          data: {
            order_id: credential
          }
        })
        .then(response => {
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors)
        })
        .finally(() => {
          store.commit('preloader/preloader', false)
        })
    },

    deleteOrder({commit, dispatch}, credential) {
      store.commit('preloader/preloader', true)
      httpReq.delete('order/delete',
        {
          data: {
            order_id: credential
          }
        })
        .then(response => {
          if (response.status === 200) {
            router.push({name: 'orderList'})
          }
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors)
        })
        .finally(() => {
          store.commit('preloader/preloader', false)
        })
    },

    submitOrder({commit, dispatch}, credential) {
      store.commit('preloader/preloader', true)
      httpReq.post('order/submit-order',
        {
          order_id: credential
        })
        .then(response => {
          if (response.status === 200) {
            router.push({name: 'orderList'})
            store.dispatch('orderItems/getOrders', {clear: true})
          }
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors)
        })
        .finally(() => {

          store.commit('preloader/preloader', false)
        })
    },

    productionReport({commit, dispatch}, credential) {
      store.commit('preloader/preloader', true)
      httpReq.get('order/order-report/' + credential)
        .then(response => {
          if (response.status === 200) {
            router.push('/order-report/' + credential)
          }
          commit('items', response.data)
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors)
        })
        .finally(() => {
          store.commit('preloader/preloader', false)
        })
    },

    productionReview({commit, dispatch}, credential) {
      store.commit('preloader/preloader', true)
      httpReq.get('order/prod-review/' + credential)
        .then(response => {
          if (response.status === 200) {
            router.push('/prod-review/' + credential)
          }
          commit('items', response.data)
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors)
        })
        .finally(() => {
          store.commit('preloader/preloader', false)
        })
    },

    sendOrderToProd({commit, dispatch}, credential) {
      store.commit('preloader/preloader', true)
      httpReq.post('order/produce/' + credential)
        .then(response => {
          if (response.status === 200) {
            router.push({name: 'productionList'})
            store.commit('production/loaded', false)
          }
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors)
        })
        .finally(() => {
          store.commit('preloader/preloader', false)
        })
    },
    sendOrderToProduction({commit, dispatch}, {orderId, data}) {
      store.commit('preloader/preloader', true)
      httpReq.post('order/send-to-prod/' + orderId, data)
        .then(response => {
          if (response.status === 200) {
            router.push({name: 'productionList'})
            store.commit('production/loaded', false)
          }
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors)
        })
        .finally(() => {
          store.commit('preloader/preloader', false)
        })
    },

    rejectOrderProd({commit, dispatch}, payload) {
      store.commit('preloader/preloader', true)
      httpReq.post('order/reject-order', payload)
        .then(response => {
          if (response.status === 200) {
            router.push({name: 'productionList'})
            store.commit('production/loaded', false)
          }
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors)
        })
        .finally(() => {
          store.commit('preloader/preloader', false)
        })
    },

    completeOrder({commit, dispatch}, credential) {
      store.commit('preloader/preloader', true)
      httpReq.post('order/complete/' + credential)
        .then(response => {
          if (response.status === 200) {
            router.push({name: 'productionList'})
            store.commit('production/loaded', false)
          }
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors)
        })
        .finally(() => {
          store.commit('preloader/preloader', false)
        })
    },

    quoteOrder({commit}, credential) {
      store.commit('preloader/preloader', true)
      httpReq.post('order/send-quote',
        {
          order_id: credential
        })
        .then(response => {
          if (response.status === 200) {
            // router.push({name: 'orderList'});
            commit('action', 'quote')
            commit('estimateQuote', true)
            store.dispatch('orderItems/getOrders')
          }
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors)
        })
        .finally(() => {
          store.commit('preloader/preloader', false)
        })
    },

    estimateOrder({commit}, credential) {
      store.commit('preloader/preloader', true)
      httpReq.post('order/send-estimate',
        {
          order_id: credential
        })
        .then(response => {
          if (response.status === 200) {
            // router.push({name: 'orderList'});
            commit('action', 'estimate')
            commit('estimateQuote', true)
            store.dispatch('orderItems/getOrders')
          }
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors)
        })
        .finally(() => {
          store.commit('preloader/preloader', false)
        })
    },

    cancelOrder({commit}, credential) {
      store.commit('preloader/preloader', true)
      httpReq.post('order/cancel',
        {
          order_id: credential
        })
        .then(response => {
          if (response.status === 200) {
            router.push({name: 'orderList'})
          }
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors)
        })
        .finally(() => {
          store.commit('preloader/preloader', false)
        })
    },

    approveOrder({commit, state}, id) {
      store.commit('preloader/preloader', true)
      httpReq.post('site/send-order-approve',
        {
          order_id: id,
          key: state.key,
        })
        .then(response => {
          if (response.status === 200) {
            commit('status', true)
          }
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors)
        })
        .finally(() => {
          store.commit('preloader/preloader', false)
        })
    },

    rejectOrder({commit, state}, credential) {
      store.commit('preloader/preloader', true)
      httpReq.post('site/send-order-reject',
        {
          order_id: state.id,
          key: state.key,
          comment: credential
        })
        .then(response => {
          if (response.status === 200) {
            //router.push({name: 'orderList'})
            commit('customerAction', 'Order has been rejected.')
          }
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors)
        })
        .finally(() => {
          store.commit('preloader/preloader', false)
        })
    },

    reviewOrder({commit, state}, credential) {
      store.commit('preloader/preloader', true)
      httpReq.post('site/send-order-review',
        {
          order_id: state.id,
          key: state.key,
          comment: credential
        })
        .then(response => {
          if (response.status === 200) {
            //router.push({name: 'orderList'})
            commit('customerAction', 'Your message has been sent')
          }
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors)
        })
        .finally(() => {
          store.commit('preloader/preloader', false)
        })
    },
  },
}
