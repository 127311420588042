import {httpReq} from "@/htttpReq";
import store from "../../store";

export default {
  namespaced: true,

  state: {
    businessInfo: {},

    stateList: {}
  },

  getters: {
    businessInfo(state) {
      return state.businessInfo;
    },

    stateList(state) {
      return state.stateList;
    }
  },

  mutations: {
    getInfo1(state, payload) {
      state.businessInfo = payload;
    },

    stateList(state, payload) {
      state.stateList = payload;
    }
  },

  actions: {
    getInfo({commit}) {
      store.commit('preloader/preloader', true);
      httpReq.get('user/wholesale/business-details')
        .then(response => {
          commit('getInfo1', response.data);
        })
        .catch((error) => {
          if (error.response.status == '401') {
            localStorage.removeItem('user-token');
            localStorage.removeItem('nameUser');
            window.location = '/login';
          }
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },


    postInfo({commit}, credentials) {
      store.commit('preloader/preloader', true);
      httpReq.post('user/wholesale/business-details', credentials.businessInfo)
        .then(response => {
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors);
        })
        .finally(() => {
          // commit('clearInput');
          store.commit('preloader/preloader', false);
        })
    },

    getState({commit}) {
      httpReq.get('site/states')
        .then(response => {
          commit('stateList', response.data);
        })        .catch(error => {
        store.commit("errorsModal/openModal", error.response.errors || error.response.data.errors);
      })
    }
  },
};
