import {httpReq} from "../../htttpReq";
import router from "../../router";
import store from "../store";

export default {
  namespaced: true,
  state: {
    list: null,
    openModal: false
  },
  getters: {
    list(state) {
      return state.list;
    },

    openModal(state) {
      return state.openModal;
    }
  },
  mutations: {
    updateList(state, data) {
      state.list = data;
    },

    openModal(state, payload) {
      state.openModal = payload;
    },

    closePopup(state, payload){
      state.openModal = payload;
    }
  },
  actions: {
    getList({commit}) {
      store.commit('preloader/preloader', true);
      httpReq.get('user/reject-reason')
        .then((response) => {
          commit('updateList', response.data.data);
        })
        .catch(error => {
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },

    sendReject(context, credential) {
      store.commit('preloader/preloader', true);
      httpReq.post('profile/reject', credential.userReject)
        .then((response) => {
        })
        .catch(error => {
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
          context.commit('openModal', false);
          router.push({name: 'dashboard'})
        });
    }
  },

};
