import router from '../../router';
import store from "../store";

export default {
  namespaced: true,
  state: {
    data: {
      breadcrumbs: []
    },
    prevBreadcrumbs: [],
    parentParams: {},
    createOrder: false
  },
  getters: {

    createOrder(state){
      return state.createOrder;
    },

    data(state){
      return state.data;
    },

    get: state => key => {
      return _.has(state.data, key) ? state.data[key] : null;
    },

    getLocation(state) {
      if (state.data.breadcrumbs && state.data.breadcrumbs[0] && state.data.breadcrumbs[0].name) {
        return state.data.breadcrumbs[0].name;
      }
      return '';
    },

    orderRouteParam: state => p => {
      let s = store.getters['createdOrder/createdOrder'].items;
      return _.has(router.currentRoute.params, p)
        ? router.currentRoute.params[p]
        : (
          _.has(s, p) ? s[p] : null
        );
    },

    orderBuilderPath: state => pi => {
      let path = '';
      let packageReg = /package-builder/;
      let orderReg = /order-preview/;
      let setReg = /sets-builder/;

      let oo = _.split(pi, '>'),
        p = oo[0],
        id = oo[1],
        getParam = (p) => {
          return store.getters['app/orderRouteParam'](p)
        };

      if (packageReg.test(router.currentRoute.name) || setReg.test(router.currentRoute.name)) {
        path = !setReg.test(router.currentRoute.name) ? '/package-builder' : '/sets-builder';

        switch (p) {
          case 'type_id':
            path += `/${id}`;
            break;
          case 'style_id':
            path += '/' + getParam('type_id') + `/${id}`;
            break;
          case 'grade_id':
            path += '/' + getParam('type_id')
              + '/' + getParam('style_id') + `/${id}`;
            break;
          case 'color_id':
            path += '/' + getParam('type_id')
              + '/' + getParam('style_id')
              + '/' + getParam('grade_id') + `/${id}`;
            break;
        }
      }

      else if (orderReg.test(router.currentRoute.name)) {
        path = '/order-preview';
        switch (p) {
          case 'location_id':
            path += `/${id}`;
            break;
          case 'type_id':
            path += '/' + getParam('location_id') + `/${id}`;
            break;
          case 'style_id':
            path += '/' + getParam('location_id')
              + '/' + getParam('type_id') + `/${id}`;
            break;
          case 'grade_id':
            path += '/' + getParam('location_id')
              + '/' + getParam('type_id')
              + '/' + getParam('style_id') + `/${id}`;
            break;
          case 'package_id':
            path += '/' + getParam('location_id')
              + '/' + getParam('type_id')
              + '/' + getParam('style_id')
              + '/' + getParam('grade_id') + `/${id}`;
            break;
          case 'color_id':
            path += '/' + getParam('location_id')
              + '/' + getParam('type_id')
              + '/' + getParam('style_id')
              + '/' + getParam('grade_id')
                + '/' + getParam('package_id') + `/${id}`;
            break;
            case 'ins_color_id':
                path += '/' + getParam('location_id')
                    + '/' + getParam('type_id')
                    + '/' + getParam('style_id')
                    + '/' + getParam('grade_id')
                    + '/' + getParam('package_id')
                    + '/' + getParam('color_id') + `/${id}`;
                break;
          case 'option_id':
            path += '/' + getParam('location_id')
              + '/' + getParam('type_id')
              + '/' + getParam('style_id')
              + '/' + getParam('grade_id')
              + '/' + getParam('package_id')
              + '/' + getParam('color_id')
              + '/' + getParam('ins_color_id') + `/${id}`;
            break;
        }
      }
      return _.toString(path);
    }

  },
  mutations: {
    set(state, data) {
      state.data[data.k] = data.v;
    },

    clearBradcrumbs(state){
      state.data.breadcrumbs.length = 0;
    },

    setCreateOrder(state, payload){
      state.createOrder = payload;
    },

    setParentParams(state, payload){
      state.parentParams = payload;
    },

    setPrevBreadcrumbs: (state, p) => state.prevBreadcrumbs = p,

    pushBreadcrumb: (state, payload) => state.data.breadcrumbs.push(payload)
  },
  actions: {
    addBreadcrumb(context, data) {
      let breadcrumbs = this.getters['app/get']('breadcrumbs'),
          l = breadcrumbs.length;
          breadcrumbs[l] = data;
      this.commit(
        'app/set',
        {
          k: 'breadcrumbs',
          v: breadcrumbs,
          arr: data.selected,
        }
      );
    },

    popBreadcrumb(context, index) {
      let breadcrumbs = this.getters['app/get']('breadcrumbs'),
          l = breadcrumbs.length;
      if (l > 1) {
        this.commit(
          'app/set',
          {
            k: 'breadcrumbs',
            v: _.slice(breadcrumbs, 0, l - 1)
          }
        );
      }
    }

  }
};
