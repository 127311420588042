import {httpReq} from '../../htttpReq'
import store from '../store'

export default {
    namespaced: true,
    state: {
        name: '',
        defaults: [],
        userRights: []
    },
    getters: {
        getDefault: (state) => (name) => {
            return state.defaults[name]
        }
    },
    mutations: {
        updateDefaults(state, payload){
            state.defaults[payload.name] = payload.value
        },
        updateUserRights(state,payload){
          state.userRights = payload.data.map(({id})=>id)
        }
    },
    actions: {
        saveDefaults({state, commit}, payload) {
            httpReq.post('user/update_defaults', payload)
                .then(response => {
                    commit('updateDefaults', { name: payload.setting, value: payload.value})
                })
                .catch(error => {
                })
                .finally(() => {
                })
        },

        loadDefaults({state, commit}, payload){
            httpReq.get('user/get_defaults/' + payload)
                .then(response => {
                    commit('updateDefaults', {name: payload, value: response.data})
                })
                .catch(error => {
                })
                .finally(() => {
                })
        },
        loadUserRights({commit}){
          httpReq.get('user/get_my_rights')
                .then(response => {
                    commit('updateUserRights', response.data)
                })
                .catch(error => {
                })
                .finally(() => {
                })
        }
    }
}
