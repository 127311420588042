import store from "../../store";
import {httpReq} from "@/htttpReq";
import router from "../../../router";
export default {
  namespaced: true,

  state: {
    existingCustomer: [],
    customerDetailsWindow: false,
    successModal: false,
  },

  getters: {
    existingCustomer(state){
      return state.existingCustomer;
    }
  },

  mutations: {
    saveCustomers(state, payload){
      state.existingCustomer = payload;
    },

    customerDetailsOpen(state, payload){
      state.customerDetailsWindow = payload;
    },

    successModalOpen(state, payload){
      state.successModal = payload
    }
  },

  actions: {

    getExistingCustomer({commit}){
      // store.commit('preloader/preloader', true);
      httpReq.get('order/customers')
        .then(response => {
          commit('saveCustomers', response.data.data);
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },

    sendData({commit}, credential) {
      store.commit('preloader/preloader', true);
      httpReq.post('order/order', credential.customerDetails)
        .then(response => {
          store.commit('createdOrder/updateOrderId', response.data.data.order_id);
          store.commit('createdOrder/updateUserId', response.data.data.user_id);
          localStorage.setItem('order_id', response.data.data.order_id);
          localStorage.setItem('user_id', response.data.data.user_id);
          credential.order_id = response.data.data.order_id
          store.dispatch('customerDetails/handleReference', credential)
          router.push({ path: '/order-preview/' + response.data.data.order_id});
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors);
          if(error.response.data.errors){
            router.push({name: 'create-order'});
          }
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },

    customerDetailsToOrder({commit}, credentials){
      store.commit('preloader/preloader', true);
      store.dispatch('customerDetails/handleReference', credentials)
      httpReq.put('order/change-customer-details', credentials.customerDetails)
        .then(response => {
          if(response.data.data){
            commit('customerDetailsOpen', false);
            commit('successModalOpen', true);
            store.dispatch('orderPreview/getPreview');
          }
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors);
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },

    handleReference({commit}, payload){
      if (!payload.reference)
        return

      var id = payload.order_id
      if (!id)
        id = payload.customerDetails.order_id

      httpReq.put('order/reference/' + id, {reference: payload.reference})
        .then(response => {
          if(response.data.data){
            store.dispatch('orderPreview/getPreview')
          }
        })
        .catch(error => {
        })
        .finally(() => {
        })
    }
  },
};
