import {httpReq} from "../../htttpReq";
import router from "../../router";
import store from "../store";

export default {
  namespaced: true,
  state: {
    errorsList: [],
    isOpen: false,
    existsUID: true
  },
  getters: {

    errorsList(state) {
      return state.errorsList;
    },
    isOpen(state) {
      return state.isOpen;
    },
    existsUID(state) {
      return state.existsUID
    }
  },


  mutations: {
    updateErrors(state, payload) {
      state.errorsList.push(payload);

      if (state.errorsList.length > 0) {
        state.isOpen = true;
      }
    },

    closePopup(state) {
      state.isOpen = false;
      state.errorsList.length = 0;
    }
  },


  actions: {
    checkUID({context, state}, uid) {
        httpReq.get(
            'user-waiting-password',
            {
              params: {
                uid: uid
              }
            }
        ).then((response) => {
            state.existsUID = response.data;
            if (!response.data) {
                router.push({name: 'home'});
            }
        }).catch(error => {
            state.existsUID = error.response.data;
            if (!error.response.data) {
                router.push({name: 'home'});
            }
        });
    },
    sendPass(context, credential) {
      store.commit('preloader/preloader', true);
      httpReq.post('make-password', {
        password: credential.password,
        password_confirmation: credential.password_confirmation,
        uid: credential.uid,
      })
        .then((response) => {
          router.push({name: 'home'});
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors);
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        });
    }
  },
};
