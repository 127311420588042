import store from "../../store";

export default {
  namespaced: true,

  state: {
    selectedFilterType: 'all',
    openModal: false,
    statuses: [],
    selectedStatuses: [],
      query: ''
  },

  getters: {
      selectedFilterType(state) {
        return state.selectedFilterType
      },
      query(state){
        return state.query;
      },

    statuses(state){
      return state.statuses;
    },
  availableStatuses(state){
    const filterTypeStates = {
      quotesEstimatesAndDrafts: ['DRFT','EST','QOT'],
      orders: ['ORD'],
      all: ['DRFT','EST','QOT','ORD'],
    }

    const states = filterTypeStates[state.selectedFilterType]
    let statusDetails = state.statuses.filter(state => states.includes(state.code))
    statusDetails = _.flatMap(statusDetails, (itm) => itm.statuses.map(x => x.status_detail)).map(item => ({...item, name: `${item.description}`}))
    let result = []
    statusDetails.forEach((r, index) => {
      if(!result.find(x => x.status === r.status)) {
        result.push({...r, id: `${r.state}-${r.status}`})
      }
    })

    result.unshift({id:0,name: 'All', description: 'All'})
    return result
  }
},

  mutations: {
    setOpenModal(state, payload) {
      state.openModal = payload;
    },

    setStatuses(state, payload) {
      state.statuses = [];
      _.forEach(payload, (v, k) => {
        state.statuses.push(v);
      })
    },

    selectedStatuses(state, payload) {
      state.selectedStatuses = payload;
    },

      query(state, payload){
        state.query = payload;
      },
    setFilterTypeToQuotesEstimatesAndDrafts(state) {
      state.selectedFilterType = 'quotesEstimatesAndDrafts'
      // state.selectedStatuses = []
    },
    setFilterTypeToOrders(state) {
      state.selectedFilterType = 'orders'
      // state.selectedStatuses = []
    },
    setFilterTypeToAll(state) {
      state.selectedFilterType = 'all'
      // state.selectedStatuses = []
    }

  },

  actions: {
    getListStatuses({commit}) {
      request.make('/order/statuses', 'get')
        .then(response => {
          commit('setStatuses', response.data);
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors);
        })
    }
  }
  ,
}
;
