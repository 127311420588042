import { httpReq } from "@/htttpReq";
import store from "../../store";
import { hasPortalManagerRole } from '/src/helpers/helpers'

export default {
  namespaced: true,

  state: {
    users: {},
    multipleAccount: 0,
    userRole: "",
    role: {},
    userRights: [],
    is_setup_completed : true,
  },

  getters: {
    getSettUpCurrentState(state) {
      return state.is_setup_completed
    },
    users(state) {
      return state.users;
    },
    multipleAccount(state) {
      return state.multipleAccount;
    },
    userRole(state) {
      return state.userRole;
    },
    role(state) {
      if (state.role === "customer") {
        return true;
      }
      return false;
    }
  },

  mutations: {
    setSetUpCurrentState(state, isCompleted) {
      state.is_setup_completed = isCompleted
    },
    updateUsers(state, payload) {
      payload.data.map(user => {
        user.roles = user.user_rights.roles;
        user.portalManagerRole = hasPortalManagerRole(user.user_rights.roles)
        user.user_rights = user.user_rights.permissions.map(({ id }) => id);
      });
      state.users = payload;
    },

    role(state, payload) {
      localStorage.setItem("user_role", payload);
      state.role = payload;
    },
    updateUserRights(state, payload) {
      state.userRights = payload.data;
    }
  },

  actions: {
    getUsers({ commit }) {
      store.commit("preloader/preloader", true);
      httpReq
        .get("user/wholesale/users-details")
        .then(response => {
          commit("updateUsers", response.data);
        })
        .catch(error => {
          if (error.response.status == "401") {
            localStorage.removeItem("user-token");
            localStorage.removeItem("nameUser");
            window.location = "/login";
          }
          store.commit("errorsModal/openModal", error.response.data.errors);
        })
        .finally(() => {
          store.commit("preloader/preloader", false);
        });
    },

    sendUsers({ commit, dispatch }, credential) {
      store.commit("preloader/preloader", true);
      httpReq
        .post("user/wholesale/users-store", credential.newUser)
        .then(response => {
          dispatch("getUsers");
          credential.cb();
        })
        .catch(error => {
          store.commit("errorsModal/openModal", error.response.errors || error.response.data.errors);
        })
        .finally(() => {
          store.commit("preloader/preloader", false);
        });
    },

    activatedUser({ commit, dispatch }, credential) {
      store.commit("preloader/preloader", true);
      httpReq
        .post("user/wholesale/activate-deactivate", {
          status: credential.status,
          id: credential.id
        })
        .then(response => {
          dispatch("getUsers");
        })
        .catch(error => {
          store.commit("errorsModal/openModal", error.response.data.errors);
        })
        .finally(() => {
          store.commit("preloader/preloader", false);
        });
    },

    makeManager({ commit }, credential) {
      store.commit("preloader/preloader", true);
      httpReq
        .post("user/wholesale/role-setter", {
          is_manager: credential.status,
          id: credential.id
        })
        .then(response => {})
        .catch(error => {
          store.commit("errorsModal/openModal", error.response.data.errors);
        })
        .finally(() => {
          store.commit("preloader/preloader", false);
        });
    },

    getRole({ commit, state }) {
      httpReq
        .get("user/profile")
        .then(response => {
          commit("role", response.data.user_role);
          state.multipleAccount = response.data.allow_multiple_user;
          state.userRole = response.data.user_role;
          commit("setSetUpCurrentState", response.data.is_setup_completed);
          store.commit('account/updatePrefer', response.data.prefer_entry);
          store.commit('account/updateSizeEntering', response.data.size_entering_type);
        })
        .catch(error => {})
        .finally(() => {});
    },
    getUserRights({ commit, state }) {
      httpReq
        .get("user/wholesale/user-rights")
        .then(response => {
          commit("updateUserRights", response.data);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {});
    },
    syncUserRights({ commit, state, dispatch }, { userId, rights, portalManagerRole }) {
      httpReq
        .post(`user/wholesale/user-rights/${userId}`, { userRightIds: rights, portalManagerRole })
        .then(response => {
          if(response.data.message==='success'){
            dispatch("getUsers");
          }else{
            throw new Error('failed to update user rights')
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {});
    }
  }
};
