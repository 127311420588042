import {httpReq} from '@/htttpReq'


export default {
  namespaced: true,
  state: {
      loaded: false,
    orders: [
      ],
  },
  getters: {
      loaded(state){
          return state.loaded
      },
    orders(state) {
      return state.orders;
    },
  },
  mutations: {
      orders(state, payload)
      {
          state.orders = payload;
      },

      loaded(state, payload)
      {
          state.loaded = payload;
      }

  },
  actions: {
      loadOrders({commit}){
          httpReq.get('dashboard/get-orders')
              .then(response => {
                  commit('orders', response.data);
                  commit('loaded', true);
              })
              .catch(error => {
              })
              .finally(() => {
              })
      }
  },
};
