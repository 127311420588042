<template>
  <div
    v-if="$store.getters['errors/errorModalVisibility']"
    class="modal modal_wrap"
    @keyup.esc="hideErrorModal()"
    style="z-index: 999999999;"
  >
    <div class="modal_popup tw-px-4">
      <h4 class="error_title">
        {{ $store.getters['errors/getErrors'].message }}
      </h4>
      <span
        class="modal__close"
        @click="hideErrorModal()"
      >
        <svg-icon name="times" />
      </span>

      <div class="divider" />
      <ul>
        <li
          v-for="(error,index) in $store.getters['errors/getErrors'].errors"
          :key="index"
          class="text text_green text_italic"
        >
          {{ error[0] }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import GlobalMixins from "../../Mixins/GlobalMixins";

export default {
  name: "BaseModalComponent",
  mixins : [GlobalMixins],
  props: {
    size: {
      type: String,
      default: 'sm'
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getModelSize() {
      if (!(["sm", "lg"].includes(this.size))) return 'generic-modal'
      return this.size === 'sm' ? 'generic-modal' : 'modal_full'
    }
  }
}
</script>

<style lang="scss" scoped>

@import "../../../src/assets/scss/utils/vars";

.error_title {
  color: #dddddd;
}

ul {
  li {
    list-style: inside disc;
  }
}

.divider {
  &:before {
    border-color: $green;
  }
}

.btn {
  margin-bottom: 0;
}

.noMarkered {
  list-style-type: none;
}
</style>
