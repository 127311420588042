import store from "../../store";
import {httpReq} from "@/htttpReq";
import router from "@/router";

export default {
  namespaced: true,

  state: {
    options: [],
    optionsList: [],
    type: '',
    selected: [],
    selectedType: [],
    components: [],
    prevOption: '',
    allOptions: false,
    name: '',
    showAll: false,
    itemId: '',
    editSingleOptions: false,
    price: '',
    resultP: 0,
    priceAllOptions: 0,
    priceArray: {
      ACC: 0,
      MFLP: 0,
      PRTS: 0,
      PSHP: 0,
      HOPH: 0,
      PET: 0,
      MIDR: 0,
      MULL: 0
    },
    modalView: false,
    insertColoursDefault: {},
    insertColours: {},
    insertIds: [],
    oldOptions: {},
    incomplete: [],
  },

  getters: {
    options(state) {
      return state.options;
    },

    optionsList(state) {
      return state.optionsList;
    },

    selected(state) {
      return state.selected;
    },

    type(state) {
      return state.type;
    },

    editSingleOptions(state) {
      return state.editSingleOptions;
    },
  },

  mutations: {

      updateColour(state, payload){

          store.commit('orderPosition/updatePrice', true)

          return httpReq.put('order/update-option-colour',
              {
                  payload: store.getters['orderPosition/orderPosition'],
                  option: payload.option,
                  colour: payload.colour
              })
              .then(response => {
                  if (response.data.pc_charge_alert === true && store.getters['orderPreview/askPcCharge']){
                      store.commit('genericQuestion/openModal', {
                              question: 'Selected colour may incur powder coating charge depending on volume. Do you wish to proceed?',
                              skip: true,
                              skip_text: 'Don\'t ask this question for this order',
                              buttons: [
                                  {
                                      name: 'Yes',
                                    icon: 'solid/check',
                                      handler: function (skip) {
                                          store.commit('orderPosition/setOption', {
                                              option: payload.option,
                                              selection: response.data.settings
                                          })
                                        store.commit('orderPreview/askPcCharge', skip)
                                          store.commit('optionColours/openModal', false)
                                          return true
                                      }
                                  },
                                  {
                                      name: 'No, let me pick another colour',
                                      icon: 'solid/times',
                                      handler: function () {
                                          store.commit('genericQuestion/closeModal')
                                          return false
                                      }
                                  }]
                          }
                      )
                  }
                  else{
                      store.commit('orderPosition/setOption', {
                          option: payload.option,
                          selection: response.data.settings
                      })
                      store.commit('optionColours/openModal', false)
                      return true
                  }

              })
              .catch(error => {
              })
              .finally(() => {
                  store.commit('orderPosition/updatePrice', false)
              })


      },












    setInsertIds: (state, payload) => state.insertIds = payload,
    setOldOptions: (state, payload) => state.oldOptions = payload,

    changeInsertColour: (state, payload) => {
      if (_.has(state.oldOptions, 'INS')) {
        state.oldOptions['INS'].oldColour = state.oldOptions['INS'].colour;
        state.oldOptions['INS'].oldColour.componentIds = state.oldOptions['INS'].components.map((e) => e.id);
        state.oldOptions['INS'].colour = payload
      }
    },

    setOptionsToOrder(state, payload) {
      if (payload.components) {
        state.allOptions = false;
        state.lastOption = payload.last_option;
        state.options = payload.components;
        payload.components.components !== undefined ? state.options.option_name = payload.option.option : false;
        state.type = payload.option.component_type;
        state.price = payload.price;
        state.resultP = payload.price;
      }
      else if (payload.settings) {
        state.allOptions = false;
        state.lastOption = payload.last_option;
        state.options = payload.settings;
        state.options.option_name = payload.option.option;
        state.type = payload.option.component_type;
        state.price = payload.price;
        state.resultP = payload.price;
      }
      else {
        state.optionsList = [];
        state.incomplete = [];
        _.forEach(payload, (el, k) => {
          el.typeOpt = k;
          state.optionsList.push(el);
          if(el.is_incomplete){
            state.incomplete.push(el.option_name);
          }
        });

        state.optionsList.sort((a, b) => {
          return a.sort_order - b.sort_order;
        });
        state.allOptions = true;
        state.type = '';
        state.resultP = payload.price ? payload.price.price : 0;
      }
      state.name = payload.option_name;
    },

    setOptionsToPackage(state, payload){
      state.optionsList = [];
      state.incomplete = [];

      _.forEach(payload, (el, k) => {
        el.typeOpt = k;
        state.optionsList.push(el);
        if(el.is_incomplete){
          state.incomplete.push(el.option_name);
        }
      });

      state.optionsList.sort((a, b) => {
        return a.sort_order - b.sort_order;
      });
      state.allOptions = true;
      state.type = '';
    },

    openDefaultOption(state, payload){
      state.allOptions = true;
      state.lastOption = payload.last_option;
      state.options = payload.components;
      payload.components.components !== undefined ? state.options.option_name = payload.option.option : false;
      state.type = payload.option.component_type;
      state.price = payload.price;
      state.resultP = payload.price;
      state.name = payload.option_name;
    },

    changeChangedPriceFromAllOptions(state, payload) {
      state.changedPrice = payload;
    },

    setAvailableOptions(state, payload) {
      state.name = payload.option_name;
      state.type = payload.typeOpt;
      state.price = payload.price;
      state.options = payload.settings;
    //  state.options.option_name = payload.option ? payload.option : payload.typeOpt;
    },

    setLockOptions(state, payload) {
      state.name = payload.option_name;
      state.type = payload.typeOpt;
      state.price = payload.price;
      state.options = payload.default_setup.groups;
      state.options.option_name = payload.option ? payload.option : payload.typeOpt;
    },

    setSelected(state, payload) {
      if (state.selected.length === 0) {
        state.selected.push(payload);
        state.selectedType.push(payload.option_name);
      }
      else {
        let exists = _.findIndex(state.selected, {option_name: payload.option_name});
        if (exists !== -1) {
          state.selected[exists] = payload;
          state.selectedType.push(payload.option_name);
        }
        else {
          state.selected.push(payload);
          state.selectedType.push(payload.option_name);
        }
      }
    },

    setTypeOrigin: (state, payload) => state.type = payload,

    setType(state, payload) {
      store.commit('selected/appendToAllSelected', state.selected);
      let removed = _.findIndex(state.selected, ['option_name', payload]);
      if (payload === 'INSCOLOUR' || payload === 'AVAILABLECOLOUR') {
        state.type = payload;
      }
      else if (removed === 0) {
        state.selected.length = 0;
        state.selectedType.length = 0;
        state.type = '';
        state.options = {};
      }
      else if (removed > 0) {
        //state.selected.splice(removed, state.selected.length - 1);
        state.selectedType.splice(removed, state.selectedType.length - 1);
        state.type = payload;
      }
    },

    cleanOptions(state) {
      state.options = {};
      state.selected = [];
      state.selectedType = [];
      state.type = '';
      state.modalView = false;
      state.components = [];
    },

    closeAvailable(state, payload) {
      state.options = {};
      state.type = '';
      // state.selectedType = [];
      state.modalView = false;
      state.components = [];
    },

    cleanToEditSingleOption() {
      state.selected = [];
      state.selectedType = [];
    },

    setAllOptions(state, payload) {
      state.allOptions = payload;
    },

    setShowAll(state, payload) {
      state.showAll = payload;
    },

    setItemId(state, payload) {
      state.itemId = payload;
    },

    setEditSingleOptions(state, payload) {
      state.editSingleOptions = payload;
    },

    createOption(state, payload) {
      state.createOption = payload;
    },

    setModalView(state, payload) {
      state.modalView = payload;
    },

    setComponents(state, payload) {
      state.components = payload;
    },

    setChangedPrice(state, payload) {
      let price = 0;
      if (payload.accessories_price !== undefined) {
        state.priceArray['ACC'] = payload.accessories_price;
      }
      else {
        _.forEach(payload, (item, key) => {
          if (key !== 'debug') {
            state.priceArray[key] = item.price;
          }
        });
      }
      _.forEach(state.priceArray, itemPrice => {
        parseFloat(price += itemPrice);
      });
      // state.priceSingleOption = Number(price);
    },


    setColorToOptionsWithColor(state, payload) {
      let id = _.findIndex(state.selected, item => {
        return item.option_name === state.options.option_name;
      });
      if(id !== -1){
        state.selected[id].color_id = payload;
      }
      else return false;
    },

    setPriceArray(state, payload) {
      // If data in price array doesn't exist.
      if (_.sum(_.values(state.priceArray)) === 0) {
        // Assuming edit default option from create option step.
        if (_.has(payload, 'price') && _.has(payload, 'typeOpt')) {
          _.forEach(state.priceArray, (item, key) => {
            state.priceArray[key] = 0;
          });
        }
        // Assuming there is no price at all.
        if (payload.ACC !== undefined && payload.opt !== undefined) {
          _.forEach(payload.opt, (item, key) => {
            if (key !== 'debug') {
              state.priceArray[key] = item;
            }
          });
          state.priceArray.ACC = payload.ACC;
          state.resultP = payload.price;
        }
      }
    },

    setPriceAllOptions(state, payload) {
      let resultPrice = payload;
      resultPrice = resultPrice.replace(/[,]/g, "");
      state.priceAllOptions = resultPrice;
    },

    clearPriceArrayFromPreview(state) {
      setTimeout(() => {
        _.forEach(state.priceArray, (item, key) => {
          state.priceArray[key] = 0;
        });
        state.resultP = 0;
        state.priceAllOptions = 0;
      }, 0);
    }
  },

  actions: {
    async getInsertColours({state}) {
      await httpReq.get('order/order-option/insert/colours/' + store.getters['createdOrder/createdOrder'].items.colors + '/' + state.insertIds)
        .then((response) => {
          state.insertColoursDefault = state.insertColours = {};
          if (_.has(response.data, 'default_colours')) {
            state.insertColoursDefault = response.data.default_colours
          }
          if (_.has(response.data, 'all')) {
            state.insertColours = response.data.all
          }
        })
    },

    async updateOptionPrice({state}, payload)
    {
        if (!payload.settings)
            return;

        store.commit('orderPosition/updatePrice', true);

        let op = store.getters['orderPosition/orderPosition'];

        var group = 'default';
        _.forEach(op.options.available, (o) => {
          if (o.code == payload.option)
            group = 'available';
        })


        op.options[group][payload.option].settings = payload.settings;

        op.return_option = payload.option;

        httpReq.put('order/get-lock-details', op)
            .then(response => {
                store.commit('orderPosition/updateOptionPrice', {
                    group: group,
                    option: payload.option,
                    price: response.data.price
                });
            })
            .catch(error => {
            })
            .finally(() => {
                store.commit('orderPosition/updatePrice', false);
            })
    },

    getOptions({commit}) {
      store.commit('preloader/preloader', true);
      httpReq.get('order/order-options/' + store.getters['createdOrder/createdOrder'].items.type_id + '/' + store.getters['createdOrder/createdOrder'].items.style_id + '/' + store.getters['createdOrder/createdOrder'].items.grade_id)
        .then(response => {
          commit('options', response.data);
        })
        .catch(error => {
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },

    getOptionsToPackage({commit}) {
      store.commit('preloader/preloader', true);
      let config = {
        params: {
          type: store.getters['createdOrder/createdOrder'].items.type_id,
          styles_id: store.getters['createdOrder/filterStyle'],
          grades_id: store.getters['createdOrder/filterGrade'],
        }
      };

      httpReq.get('package/get-options', config)
        .then(response => {
          commit('setOptionsToOrder', response.data);
        })
        .catch(error => {
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },

    getOptionsToSet({commit}) {
      store.commit('preloader/preloader', true);
      let config = {
        params: {
          type: store.getters['createdOrder/createdOrder'].items.type_id,
          styles_id: [store.getters['createdOrder/createdOrder'].items.style_id],
          grades_id: [store.getters['createdOrder/createdOrder'].items.grade_id],
        }
      };

      httpReq.get('package/get-options', config)
        .then(response => {
          commit('setOptionsToOrder', response.data);
        })
        .catch(error => {
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },



    getOptionsNew({state, commit}) {
      store.commit('preloader/preloader', true);
      httpReq.get('options/options-list',
        {
          type: store.getters['createdOrder/createdOrder'].items.type_id,
          style_id: store.getters['createdOrder/createdOrder'].items.style_id,
          grade_id: store.getters['createdOrder/createdOrder'].items.grade_id,
          color_id: store.getters['createdOrder/createdOrder'].items.colors,
          width: store.getters['createdOrder/createdOrder'].items.width,
          drop: store.getters['createdOrder/createdOrder'].items.drop,
          ins_color: store.getters['createdOrder/createdOrder'].items.insert_color,
          options: state.selected,
          prev_option: state.selectedType.length ? state.selectedType[state.selectedType.length - 1] : '',
          show_all: state.showAll,
          single_option: state.editSingleOptions ? state.editSingleOptions : '',
          item_id: store.getters['orderItem/get']('isUpdate') ? store.getters['createdOrder/createdOrder'].itemId : '',
          order_id: store.getters['orderPreview/id'],
          package_id: store.getters['createdOrder/createdOrder'].items.package_id,
        })
        .then(response => {
          commit('setOptionsToOrder', response.data);
        })
        .catch(error => {
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },


    async showOrderOptions({commit, state}) {
      const item = store.getters['createdOrder/createdOrder'];
      let sendData = {
          item_id: state.itemId
        },
        neededFields = ['type_id', 'style_id', 'grade_id', 'colors', 'width', 'drop'];
      if (_.toInteger(item.id) > 0) {
        _.forEach(neededFields, (v) => {
          sendData[v] = _.toInteger(item.items[v])
        });
      }

      await httpReq.get('order/show-order-options', sendData)
        .then(response => {
          commit('setOptionsToOrder', response.data);
        })
        .catch(error => {
        })
        .finally(() => {
        })
    },

    getSingleOption({commit, state}, credentials) {
      store.commit('preloader/preloader', true);
      httpReq.get('order/order-single-option',
        {
          item_id: store.getters['createdOrder/createdOrder'].itemId,
          selected: credentials.selected,
          option_name: credentials.index,
          width: store.getters['createdOrder/createdOrder'].items.width,
          drop: store.getters['createdOrder/createdOrder'].items.drop,
          options: state.selected,
        })
        .then(response => {
          commit('setOptionsToOrder', response.data);
        })
        .catch(error => {
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },

    updateAvailableOptionsPrice({commit, state}, credantials) {
      let params = {};
      let options = state.options;
      if(credantials === undefined){
        _.forEach(options, (item, key) => {
          params[key] = item;
        })
      }
      else{
        params = credantials
      }
      store.commit('preloader/preloader', true);
      httpReq.post('order/preview/update-available-option-price',
        {
          type: store.getters['createdOrder/createdOrder'].items.type_id,
          style_id: store.getters['createdOrder/createdOrder'].items.style_id,
          grade_id: store.getters['createdOrder/createdOrder'].items.grade_id,
          order_id: store.getters['orderPreview/id'],
          settings: params.settings,
          components: params.components,
          option_name: params.option_name,
          color_id: params.color_id ? params.color_id : '',
        })
        .then(response => {
          commit('setChangedPrice', response.data);
        })
        .catch(error => {

        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    }
  },
};
