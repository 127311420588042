import {httpReq} from "../../htttpReq";
import router from "../../router";
import store from "../store";
import preloader from "./preloader";

export default {
  namespaced: true,

  state: {

  },

  getters: {

  },

  mutations: {

  },

  actions: {
    sendReset({commit}, credential) {
      store.commit('preloader/preloader', true);
      httpReq.post('reset-password', {
        token: credential.email,
        password: credential.password,
        new_password: credential.new_password
      })
        .then((response) => {
          router.push({name: 'login'});
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors);
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        });
    },
  },
};
