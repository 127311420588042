export default {
  namespaced: true,
  state: {
    successMessage: {},
    successModalVisibility: false
  },
  getters: {
    getSuccessMessage(state) {
      return state.successMessage
    },
    successModalVisibility(state) {
      return state.successModalVisibility
    }
  },
  mutations: {
    SET_SUCCESS_MESSAGE(state, errors) {
      state.successMessage = errors
    },
    REMOVE_SUCCESS_MESSAGE(state) {
      state.successMessage = {}
    },
    HIDE_MODAL(state) {
      state.successModalVisibility = false
    },
    SHOW_MODAL(state) {
      state.successModalVisibility = true
    }
  },
  actions: {

    setSuccessMessage({commit}, errors) {
      commit('SET_SUCCESS_MESSAGE', errors);
      commit('SHOW_MODAL');
    },
    removeSuccessMessage({commit}) {
      commit('REMOVE_SUCCESS_MESSAGE');
      commit('HIDE_MODAL');
    }
  },
}
