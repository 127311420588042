import {status} from '@/constants/statusConstant';
import {mapGetters} from 'vuex';
import store from "../store/store";

export default {
  methods: {
    getStatusOrState(code, limit = false) {
      try {
        let text = _.find(status, {code}).text
        if (limit && text.length >= 18) text = text.substring(0, 18) + '..';
        return text
      } catch {
        return ''
      }
    },
    shortenText(text) {
      if (text.length >= 18) return text = text.substring(0, 18) + '..';
      return text
    },
    isAdmin() {
      return this.userRole.toLowerCase() === '1admin'
    },
    isFirstDimensionWidth() {
      return this.first_dimension
    },
    startLoader() {
      store.commit('preloader/preloader', true);
    },
    stopLoader() {
      store.commit('preloader/preloader', false);
    },
    getCustomerFullAddress(customerDetails) {
      let full_address = customerDetails.address
      if (customerDetails.suburb) full_address = full_address + ', ' + _.startCase(customerDetails.suburb)
      if (customerDetails.postcode) full_address = full_address + ', ' + customerDetails.suburb
      if (customerDetails.state) full_address = full_address + ', ' + customerDetails.state.code
      return full_address
    },
    getValueInAU(value) {
      if (value) {
        let number = parseFloat(value).toLocaleString()
        return '$ ' + number
      }
      return 'N/A'
    },
    getFullName(data) {
      let fullName = ''
      if (data.first_name) fullName += data.first_name
      if (data.name) fullName += data.name
      if (data.last_name) fullName += data.last_name
      return fullName
    },
    showErrorModal(errors) {
      store.dispatch('errors/setErrors', errors.response.data)
    },
    hideErrorModal() {
      store.dispatch('errors/removeErrors')
    },
    showSuccessModal(message) {
      store.dispatch('success/setSuccessMessage', message)
    },
    hideSuccessModal() {
      store.dispatch('success/removeSuccessMessage')
    }
  },
  computed: {
    ...mapGetters({
      userRole: 'auth/getUserRole',
      first_dimension: 'orderPreview/isWidth'
    })
  }
}
