import {httpReq} from '@/htttpReq'
import store from '../../store'

export default {
    namespaced: true,

    state: {
        style: {},
        location: '',
    },

    getters: {
        style(state) {
            return state.style
        },

    },

    mutations: {
        updateStyle(state, payload) {
            state.style = payload
        },
    },

    actions: {
        getStyle({commit}) {
            store.commit('preloader/preloader', true)
            httpReq.get(
                'order/order-styles/'
          + store.getters['orderPosition/orderPosition'].item.type_id
          + '/' + store.getters['orderPosition/orderPosition'].htl
          + '/' + store.getters['orderPosition/orderPosition'].wdt
            )
                .then(response => {
                    commit('updateStyle', response.data)
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },

        getStyleFromType({commit}, type) {
            store.commit('preloader/preloader', true)
            httpReq.get(
                'styles/' + type
            )
                .then(response => {
                    commit('updateStyle', response.data)
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },


        getStyleWithoutLimits({commit}) {
            store.commit('preloader/preloader', true)
            httpReq.get(
                'styles/' + store.getters['createdOrder/createdOrder'].items.type_id
            )
                .then(response => {
                    commit('updateStyle', response.data)
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },
    },
}
