import store from '../../store'
import router from '../../../router'
import {httpReq} from '@/htttpReq'

export default {
    namespaced: true,

    state: {
        saving: false,
        saved: false,
        selection: [],
        mode: 'all',
        currentItem: {
            style: 0,
            grade: 0,
            item: 0,
            name: '',
            measure_type: 0,
            item_type: 0,
            colour_group: 0,
            colour: {},
            is_same_as_frame: false,
            select_colour: false,
            qty: 1,
            length1: 0,
            length2: 0,
            length3: 0,
            length4: 0,
            length1_type: 'NONE',
            length2_type: 'NONE',
            length3_type: 'NONE',
            length4_type: 'NONE',
            price: 0
        },



        accessories: {},
        accessoriesItems: {},
        editAccessoryItem: {},
        mitreCut: {},
        length: {},
        colors: {},

        window: {
            openAdditionalWindow: false,
            openAdditionalType: false,
            openAdditionalStyle: false,
            openAdditionalSize: false,
            openAdditionalColor: false,
        },
    },

    getters: {
        currentItemTypeStyle(state) {
            let result = {}
            for(let field in state.accessories.data){

                let type =  state.accessories.data[field]

                for(let f in type) {
                    if(type['styles'] && type['styles'][state.currentItem.style]) {
                        result = {
                            typeId:  type['id'],
                            typeDescription: type['name'],
                            styleId: type['styles'][state.currentItem.style].id,
                            styleDescription: type['styles'][state.currentItem.style].name,
                        }
                        break
                    }
                }
            }
            return result
        },
        saving(state) {
            return state.saving
        },

        saved(state) {
            return state.saved
        },

        accessories(state) {
            return state.accessories
        },

        mode(state){
            return state.mode
        },

        editAccessoryItem(state) {
            return state.editAccessoryItem
        },

        accessoriesItems(state) {
            return state.accessoriesItems
        },

        mitreCut(state) {
            return state.mitreCut
        },

        length(state) {
            return state.length
        },
        colors(state) {
            return state.colors
        },
        currentItem(state){
            return state.currentItem
        }
    },

    mutations: {
        saving(state, payload) {
            state.saving = payload
        },

        saved(state, payload) {
            state.saved = payload
        },

        mode(state, payload){
            state.mode = payload
        },
        createAccessory(state, payload) {
            let types = _.get(payload, 'data', []).sort((a,b) => a.sort_order > b.sort_order)
            state.accessories = {data: types}
        },

        updateAccessoriesItems(state, payload) {
            state.accessoriesItems = payload
        },

        updateAddAccessoriesItem(state, payload) {
            state.editAccessoryItem = payload
        },

        updateMitreCut(state, payload) {
            state.mitreCut = payload
        },

        updateLength(state, payload) {
            state.length = payload
        },

        clearAccessories(state) {
            state.accessories = {}
        },

        setColors(state, payload) {
            state.colors = payload
        },

        currentItem(state, payload)
        {
            for(var k in payload) {
                if (k == 'debug')
                    continue
                state.currentItem[k] = payload[k]
            }
        },

        windowOpen(state, payload){
            state.window[`${payload}`] = true
        },

        windowClose(state, payload){
            state.window[`${payload}`] = false
        },

        setAccessory({commit}, payload) {
            store.commit('preloader/preloader', true)
            httpReq.get('accessories/set-accessory/' + payload.item_id + '/' + store.getters['orderPosition/orderPosition'].colour.id)
                .then(response => {
                    store.commit('accessories/currentItem', response.data)
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },

        setAdditionalAccessory({commit}, payload) {
            store.commit('preloader/preloader', true)
            httpReq.get('accessories/set-accessory/' + payload.item_id)
                .then(response => {
                    store.commit('orderPreview/additionalAccessory', response.data)
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },

        cleanup(state){
            state.mode = 'all'
            state.currentItem = {
                style: 0,
                grade: 0,
                item: 0,
                name: '',
                measure_type: 0,
                item_type: 0,
                colour_group: 0,
                colour: {},
                select_colour: false,
                qty: 1,
                length1: 0,
                length2: 0,
                length3: 0,
                length4: 0,
                length1_type: 'NONE',
                length1_type: 'NONE',
                length1_type: 'NONE',
                length1_type: 'NONE',
                price: 0
            }
        },
      checkAccessory(state, payload) {

//      if (!checkPCCharge)
//        return true

        let a = {...state.currentItem, ...payload}

        store.commit('preloader/preloader', true)

        let p = store.getters['orderPosition/orderPosition']

        httpReq.post('order/check-acc', {acc: a,
                    order: p.order_id,
                    current_charge: p.pc_charge_list
        })
          .then(response => {
              if (response.data && store.getters['orderPreview/askPcCharge']) {
                store.commit('genericQuestion/openModal', {
                    question: 'Selected colour may incur powder coating charge depending on volume. Do you wish to proceed?',
                    skip: true,
                    skip_text: 'Don\'t ask this question for this order',
                    buttons: [
                      {
                        name: 'Yes',
                        icon: 'solid/check',
                        handler: function (skip) {
                          store.commit('accessories/currentItem', {
                            colour: payload.colour,
                            is_same_as_frame: payload.is_same_as_frame
                          });
                          store.commit('orderPreview/askPcCharge', skip)
                          store.commit('optionColours/openModal', false)
                        }
                      },
                      {
                        name: 'No, let me pick another colour',
                        icon: 'solid/times',
                        handler: function () {
                          return false
                        }
                      }]
                  }
                )

              } else {
                store.commit('accessories/currentItem', {
                  colour: payload.colour,
                  is_same_as_frame: payload.is_same_as_frame
                });
                store.commit('optionColours/openModal', false)

              }
            }
          ).finally(() => {
          store.commit('preloader/preloader', false)
        });
      }

    },

    actions: {

        getAccessories({commit}) {
            store.commit('preloader/preloader', true)
            httpReq.get('accessories/types-with-styles')
                .then(response => {
                    commit('createAccessory', response.data)
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },

        getStyle({commit}, credential) {
            store.commit('preloader/preloader', true)
            httpReq.get('accessories/grades/' + credential.type + '/' + credential.item)
                .then(response => {
                    store.commit('accessories/currentItem', {
                        style: credential.item
                    })
                    store.commit('accessories/updateAccessoriesItems', response.data)
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },

        saveAccessory({state})
        {
            let mode = store.getters['accessories/mode'],
                acc = store.getters['orderPosition/getCurrentOptionSettings'],
                cur = store.getters['accessories/currentItem']

            store.commit('preloader/preloader', true)
            store.commit('accessories/saving', true)
            store.commit('accessories/saved', false)

            if (!acc || acc == null)
            {
                acc = []
            }

            if (typeof(cur.index) != 'undefined') {
                var i = cur.index
                delete cur.index
                acc[i] = cur
            }
            else {
                acc.push(cur)
            }

            let op = store.getters['orderPosition/orderPosition']

            op.options.available.ACC.settings = acc

            httpReq.put('order/update-accessories', op)
                .then(response => {
                    store.commit('orderPosition/setupPosition', response.data)
                })
                .catch(error => {
                })
                .finally(() => {
                    store.commit('accessories/cleanup')
                    store.commit('accessories/mode', 'all')
                    store.commit('preloader/preloader', false)
                    store.commit('accessories/saving', false)
                    store.commit('accessories/saved', true)
                })



        },

        deleteAccessory(state, payload)
        {
            let acc = store.getters['orderPosition/getCurrentOptionSettings']

            if (!acc || acc == null)
            {
                return
            }

            acc.splice(payload, 1)

            store.commit('orderPosition/setOption', {option: 'ACC', selection: acc })

        },

        cleanup(state){
            store.commit('accessories/cleanup')
        },

        getMitreCut({commit}, credential) {
            store.commit('preloader/preloader', true)

            httpReq.get('accessories/mitre-cut/' + credential)
                .then(response => {
                    commit('updateMitreCut', response.data)
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },


        getLength({commit}) {
            store.commit('preloader/preloader', true)
            httpReq.get('length-type')
                .then(response => {
                    commit('updateLength', response.data)
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },


        getColorsGroup({commit}) {
            httpReq.get('order/order-colours-by-group',
                {
                    params: {
                        type: store.getters['createdOrder/createdOrder'].items.type_id,
                        style: store.getters['createdOrder/createdOrder'].items.style_id,
                        grade: store.getters['createdOrder/createdOrder'].items.grade_id,
                    }
                })
                .then(response => {
                    commit('setColors', response.data)
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },

        updateOrderPriceInOrder({commit}){
            let order = store.getters['createdOrder/createdOrder']
            httpReq.post('order/preview/update-accessories-price',
                {
                    accessory: order.items.accessories,
                    order_id: order.id,
                    type: order.items.type_id,
                    style_id: order.items.style_id,
                    grade_id: order.items.grade_id,
                    order: order,
                })
                .then(response => {
                    store.commit('options/setChangedPrice', response.data)
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        }
    },
}
