import {httpReq} from "@/htttpReq";
import store from "../../store";

export default {
  namespaced: true,

  state: {
    types: {},
    location: '',
    OSQR: {
      width: 0,
      drop: 0,
    },

    updates: false,
  },

  getters: {
    types(state) {
      if (!localStorage.getItem('token')) return
      if (_.isEmpty(state.types) )
      {
          store.commit('preloader/preloader', true);

          httpReq.get('order/order-types')
              .then(response => {
                  store.commit('types/updateTypes', response.data);
              })
              .catch(error => {
                  store.commit('errorsModal/openModal', error.response.data.errors);
              })
              .finally(() => {
                  store.commit('preloader/preloader', false);
              })
      }
      return state.types;

    },
    OSQR(state) {
      return state.OSQR;
    },

    updates(state){
      return state.updates;
    }
  },

  mutations: {
    updateTypes(state, payload) {
      state.types = payload;
    },
    setLocation(state, payload) {
      state.location = payload;
    },
    setOSQR(state, payload) {
      state.OSQR = payload;
    },
    update(state, payload){
      state.updates = payload;
    }
  },

  actions: {
    getTypes({commit}, silent = false) {
      if(!silent) store.commit('preloader/preloader', true)

      httpReq.get('order/order-types')
        .then(response => {
          commit('updateTypes', response.data);
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors);
        })
        .finally(() => {
          if(!silent) store.commit('preloader/preloader', false)
        })
    },

    getTypesWithoutLimits({commit}) {
      store.commit('preloader/preloader', true);

      httpReq.get('types')
        .then(response => {
          commit('updateTypes', response.data);
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors);
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },
  },
};
