import store from '../../store'
import {httpReq} from "@/htttpReq";
import router from '@/router'

export default {
    namespaced: true,
    state: {
        items: [],

        filteredItems: [],
        page: 0,
        last_page: 0,
        total: 0,
        effectiveStates: [],
        sortBy: undefined,
    },
    getters: {
        items(state) {
            return state.items
        },
        orderType(state) {
            if(state.effectiveStates.find(x => ['DRFT','EST','QOT'].includes(x))) return 'ordersAndQuotes'
            if(state.effectiveStates.length === 1 && state.effectiveStates.find(x => ['ORD'].includes(x))) return 'orders'
            return 'all'
        },

        filteredItems(state) {
            return state.filteredItems
        },

        pager(state) {
            return {
                page: state.page,
                last_page: state.last_page,
                total: state.total
            }
        },
      sortBy(state){
          return state.sortBy
      }
    },
    mutations: {
        setSortBy(state, payload) {
            state.sortBy = payload
        },
        updateItems(state, payload) {
            state.items = payload
        },

        addItems(state, payload) {
            state.items = state.items.concat(payload)
        },

        clearItems(state) {
            state.page = 0
            state.last_page = 0
            state.items = []
            state.total = 0
        },

        productionItems(state, payload) {
            state.items = payload
        },

        updatePage(state, payload) {
            state.page = payload.current_page
            state.last_page = payload.last_page
            state.total = payload.total
        },
        filteredItems(state, payload) {
            state.filteredItems = payload
        },

    },
    actions: {
        getOrders({state, commit}, payload) {
          if(!store.getters['users/getSettUpCurrentState']){
           router.push({name : 'welcome'}).catch(error => {})
            return;
          }

            store.commit('preloader/preloader', true)
            var statuses, query, clear, states
            const sortBy = payload.sortBy || state.sortBy
          if(payload && payload.states) {
                states = payload.states
            }
            if (payload && payload.statuses)
                statuses = payload.statuses

            if (payload && payload.query)
                query = payload.query

            httpReq.get('order/orders', {
                params: {
                    states: states,
                    statuses: statuses,
                    searchString: query,
                    count: 50,
                    sortBy,
                }
            })
                .then(response => {
                    commit('updatePage', response.data.meta)
                    commit('updateItems', response.data.data)
                }).finally(() => {
                store.commit('preloader/preloader', false)
            })
        },

        loadMore({state, commit}, payload) {
            store.commit('preloader/preloader', true)
            var statuses, query, states
            const sortBy = payload.sortBy || state.sortBy

            if(payload && payload.states) {
              states = payload.states
            }

            if (payload && payload.statuses)
                statuses = payload.statuses

            if (payload && payload.query)
                query = payload.query

            httpReq.get('order/orders', {
                params: {
                    states: states,
                    statuses: statuses,
                    searchString: query,
                    page: state.page + 1,
                    count: 50,
                    sortBy,
                }
            })
                .then(response => {
                    commit('updatePage', response.data.meta)
                    commit('addItems', response.data.data)
                }).finally(() => {
                store.commit('preloader/preloader', false)
            })
        },

    }

}
