<template>
  <div
    id="app"
    @keyup.esc="closeWarning"
    class="tw-flex tw-flex-col"
    style="--PrimaryColour: #0E3753;
    --PrimaryTextColour: #FFFFFF;
    --SecondaryColour: #59b692;
    --SecondaryTextColour: #FFFFFF;
    --LayoutIconPrimary: #59b692;
    --LayoutIconSecondary: #777777;
    --PrimaryButtonColour: #59B692;
    --PrimaryButtonTextColour: #FFFFFF;"

  >
    <div class="header_main">
      <div class="header_main__container container" :style="!showAuthMenu ? 'padding:0 0': ''">
        <div class="logo">
          <router-link class="logo" to="/">
              <img
                src="https://starlinesecurity.net.au/wp-content/uploads/2021/02/logo_light3.png"
                alt="Logo Light3"
                sizes="100vw"
              />
          </router-link>
        </div>
        <nav class="menu" v-if="!hideHeaderNav">
          <div
            class="menu__trigger"
            :class="menuShow && 'menu__trigger--active'"
            @click="menuShow = !menuShow"
            @keyup.esc="menuShow = !menuShow"
          >
            <svg-icon v-if="menuShow" name="solid/times"/>
            <svg-icon v-else name="solid/bars"/>
          </div>
          <transition  v-if="showAuthMenu"
                       name="fade">
            <ul class="menu__list"
                :class="menuShow && 'menu__list--active'">
              <div class="menu__mask"></div>
              <div class="d-flex">
                <li
                  class="menu__item"
                  @click="menuShow = false"
                >
                  <router-link
                    :to="{name: 'dashboard'}"
                    class="menu__link"
                  >
                    Dashboard
                  </router-link>
                </li>

                <li
                  v-if="isAdmin()"
                  class="menu__item"
                  @click="menuShow = false"
                >
                  <router-link
                    :to="{path: '/leads'}"
                    class="menu__link"
                  >
                    <span>Leads</span>
                    <span class="menu__badge">{{ leadsCount }}</span>
                  </router-link>
                </li>

                <li
                  class="menu__item"
                  @click="menuShow = false"
                >
                  <router-link
                    exact-path
                    :to="{path: '/my-jobs'}"
                    class="menu__link"
                  >
                    Jobs
                    &nbsp;
                    <router-link
                      exact-path
                      :to="{path: '/my-orders/new'}"
                      class="menu__link"
                      title="Create new Job"
                    >
                      <svg-icon name="solid/circle-plus" />
                    </router-link>
                  </router-link>
                </li>
                <li
                  class="menu__item"
                  @click="menuShow = false"
                >
                  <router-link
                    exact-path
                    :to="{path: '/my-orders'}"
                    class="menu__link"
                  >
                    Orders
                  </router-link>
<!--                  &nbsp;-->
<!--                  <router-link-->
<!--                    exact-path-->
<!--                    :to="{path: '/my-orders/new'}"-->
<!--                    class="menu__link"-->
<!--                  >-->
<!--                    <svg-icon name="solid/circle-plus" />-->
<!--                  </router-link>-->
                </li>





                <li
                  class="menu__item"
                  @click="menuShow = false"
                >
                  <router-link
                    :to="{path: '/customers'}"
                    class="menu__link"
                  >
                   Customers
                  </router-link>
                </li>
              </div>
              <div class="d-flex ml-auto ">
                <li
                  class="menu__item"
                  @click="menuShow = false"
                >
                  <router-link
                    :to="{name: 'settings.account'}"
                    class="menu__link"
                  >
                    <span>Account</span>
                  </router-link>
                </li>
                <li class="menu__item">
                  <a type="button" href="https://help.quotingportal.com.au/tutorial-videos" class="menu__link" target="_blank">
                    Help
                  </a>
                </li>
                <li class="menu__item">
                  <a type="button" href="#" class="menu__link" @click="logout">
                    Sign Out
                  </a>
                </li>
              </div>
            </ul>
          </transition>

          <transition  v-else
                       name="fade">
            <ul class="menu__list"
                :class="menuShow && 'menu__list--active'">
              <div class="menu__mask"></div>
              <div class="d-flex">
                <li
                  v-for="(route,index) in menuItems.routs"
                  :key="index"
                  class="menu__item"
                  @click="menuShow = false"
                >
                  <router-link
                    :to="route.url"
                    class="menu__link"
                  >
                    {{route.text}}
                  </router-link>
                </li>

              </div>

              <div class="d-flex ml-auto ">
                <li
                  v-for="(link,index) in menuItems.links"
                  :key="index"
                  class="menu__item"
                  @click="menuShow = false"
                >
                  <a type="button" target="_blank" :href="link.url" class="menu__link">
                    {{link.text}}
                  </a>
                </li>
              </div>
            </ul>
          </transition>
        </nav>
      </div>
      <div id="page-header"></div>
    </div>

    <div class="container_fluid tw-flex-1 tw-overflow-auto" :class="{'non_auth' : isThisIsANonAuthRoute}" :style="getBackgroundImage">
<!--      <div-->
<!--        class="tw-pt-4 tw-font-helvetica"-->
<!--        :class="{-->
<!--          'container_fluid tw-pl-3 tw-pr-3':-->
<!--            $route.name === 'production-dashboard',-->
<!--          container: $route.name !== 'production-dashboard',-->
<!--        }"-->
<!--      >-->
      <div
        class="tw-font-helvetica container_fluid"
      >
        <Modal class="order-page-item-wizard-modal" v-model="showWizardModal">
          <Wizard
            ref="refWizard"
            :isModal="true"
            :isQuoting="false"
            @closeModal="showWizardModal = false"
            @alert="showAlert"
            v-if="showWizardModal"
          />

        </Modal>
        <JobsCreate v-if="jobsCreateIsOpened" @close="jobsCreateIsOpened = false"/>
        <router-view/>
      </div>
    </div>

    <div class="footer tw-z-10">
      <div id="sticky-footer-ref" class=""></div>
      <div id="sticky-footer" class=""></div>
      <div class="footer__container container">
        <div class="footer__copyright">© Starline Security 2019 - {{ (new Date()).getFullYear() }}. All rights reserved.</div>
        <router-link to="/information" class="footer__terms">Terms & Conditions </router-link>
      </div>
    </div>

    <transition name="slide">
      <preloader v-if="preloader" />
    </transition>
    <notifications />
    <generic-question tabindex="0" />
    <errors-modal tabindex="0" />
    <BaseErrorModalComponent :show="errorModalVisibility" />
    <BaseSuccessModalComponent :show="successModalVisibility" />

    <!-- <VueQueryDevTools /> -->

  </div>
</template>

<script>
import {defineAsyncComponent} from "vue";
import Preloader from './components/Preloader'
import errorsModal from './components/modals/errorModal'
import genericQuestion from './components/modals/GenericQuestion'
import BaseErrorModalComponent from './components/commons/BaseErrorModalComponent.vue'
import BaseSuccessModalComponent from "./components/commons/BaseSuccessModalComponent";
import { mapGetters } from 'vuex';

const Modal = defineAsyncComponent(() => import('@/components/item-wizard/components/elements/Modal.vue'))
const Wizard = defineAsyncComponent(() => import('@/components/item-wizard/wizard.vue'))
const JobsCreate = defineAsyncComponent(() => import('@/components/jobs/JobsCreate.vue'))

import { VueQueryDevTools } from "vue-query/devtools";
import { useQueryProvider } from "vue-query";
import { defaultStyleColors } from '@/components/item-wizard/store/constant'
import GlobalMixins from '@/Mixins/GlobalMixins.js'
import retry from "retry";
import imageItem from "./components/ImageItem";



export default {

    components: {
        Preloader,
        errorsModal,
        genericQuestion,
        BaseErrorModalComponent,
      BaseSuccessModalComponent,
      Modal,
      Wizard,
      JobsCreate,
      VueQueryDevTools,
    },
    mixins: [GlobalMixins],
    data() {
        return {
            menuShow: false,
            menu: null,
            showAuthMenu: false,
            showWizardModal : false,
            jobsCreateIsOpened : false,
            nonAuthRouteNames : ['login','status'],
            hideHeaderNav : false
        }
    },
    computed: {
      ...mapGetters({
        is_setup_completed: 'users/getSettUpCurrentState',
        login: 'auth/isAuthenticated',
      }),
      getBackgroundImage (){
        let image = ''
        if(this.$route.name === 'login') image = require('@/assets/img/banners/login-banner.jpg')
        else if(this.$route.name === 'status') image = require('@/assets/img/banners/application_status-banner.jpg')
        return `background-image: url(${image})`;
      },
      isThisIsANonAuthRoute () {
        return _.includes(this.nonAuthRouteNames,this.$route.name)
      },
      leadsCount() {return this.$store.getters['leads/leadsCount']},


        menuItems() {
            let menu = this.$store.getters['menu/menu']
            let menusObj = {
                routs: [],
                links: [],
            }
            _.forEach(menu, item => {
                if(item.link){
                    menusObj.links.push(item)
                }
                else{
                    menusObj.routs.push(item)
                }
            })
            return menusObj
        },

        preloader() {
            return this.$store.getters['preloader/preloader']
        },
        errorModalVisibility() {
          return this.$store.getters['errors/errorModalVisibility']
        },
        successModalVisibility() {
          return this.$store.getters['success/successModalVisibility']
        }
    },

    async created() {
      useQueryProvider();
      //Application get refreshed after login. so data received at login can't be used
     if(this.login) await this.$store.dispatch('users/getRole');
      this.getMenu()
      this.parseStyles(defaultStyleColors)

    },

    mounted() {

      setTimeout(() =>{
        if(this.$router.options.history && this.is_setup_completed && this.$router.options.history.state.current === '/welcome') {
          this.$router.push({name:'dashboard'}).catch(err => err);
        }
      },3000)
      if (this.login) {
        this.getLeadsCount()
        setInterval(() => this.getLeadsCount(), 300000);
      }

      document.body.addEventListener('keyup', e => {
            if (e.keyCode === 27){
                this.$store.commit('errorsModal/closeModal')
                // this.$store.commit('createdOrder/warningModal', false);
                // this.$store.commit('createdOrder/deleteModal', false);
                // this.$store.commit('createdOrder/warningOutOfSquare', false);
                this.$store.commit('deleteModalGlobal/setStatus', false)
                this.$store.commit('deleteModalGlobal/setParams', {
                    itemType: String,
                    action: String,
                    actionAfter: {},
                    actionData: {}
                })
                this.$store.commit('orderPreview/delete', false)
                this.$store.commit('orderPreview/cancel', false)
                this.$store.commit('orderPreview/cloneWindow', false)
                this.$store.commit('orderPreview/setTotalPriceWindow', false)
                this.$store.commit('orderPreview/setSettingsWindow', false)
                this.$store.commit('filterOrders/setOpenModal', false)
                this.$store.commit('packages/setClone', false)
            }
        })
        return this.$store.dispatch('state/state')
    },

    methods: {
         showAlert(alert) {
           this.$notify({
            type: 'success',
            title: alert
          })
        },
        parseStyles(styles) {
          // const root = document.querySelector('html')
          // styles.forEach(el => {
          //   root.style.setProperty(`--${el.setting}`, el.value)
          // })
        },
        logout() {
          this.$store.dispatch("auth/logout");
        },
        getMenu() {
            return this.$store.dispatch('menu/menu')
        },

        isAdmin(){
          return localStorage.getItem('user_role')
            // return localStorage.getItem('user_role') === 'admin'

        },
        getLeadsCount(){
          this.$store.dispatch('leads/getLeadsCount')
        },
        closeWarning(){
            this.$store.commit('errorsModal/closeModal')
        },
        refreshOrders() {
          if(!this.is_setup_completed) return
          const pageType = this.$route.query.orderType
          let states = ['DRFT','EST','QOT', 'ORD']
          if(pageType === 'quote-and-estimate') {
            states = ['DRFT','EST','QOT']
          }
          else if(pageType === 'orders') {
            states = ['ORD']
          }
          this.$store.dispatch('orderItems/getOrders',{states });
        }
    },
  watch: {

    login: {
        immediate: true,
        handler(val, old) {
          if(val) {
            this.getLeadsCount()
          }
        }
      },
    '$store.state.users.is_setup_completed' (newValue){
      if(!newValue) {
        this.$router.push({name:'welcome'}).catch(err => err);
      }
    },
    '$route' (to,from){
      if(this.is_setup_completed && (to.name === 'welcome' || from.name === 'welcome')){
        this.$router.push({name:'dashboard'}).catch(err => err);
      }

      if(!this.is_setup_completed) {
        this.$router.push({name:'welcome'}).catch(err => err);
      }
      this.showAuthMenu = localStorage.getItem('token')
      this.hideHeaderNav = to.name.indexOf('view-quote') > -1
    },
    showWizardModal (value){
      if(value) document.querySelector('body').style.overflow = 'hidden'
      else document.querySelector('body').style.overflow = ''
    }
  }
}

</script>

<style lang="scss">
  @import "assets/scss/main";

  html {
    font-family: $helvetica;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #app {
    font-family: $helvetica;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;

    @media print {
      min-height: unset;
    }
  }

  .footer {
    @media print {
      display: none;
    }
  }

  .topLine {
    display: block;
    height: 20px;
    width: 100%;
    background-color: $navy;
  }
  svg.lg.svg-inline--fa.fa-chevron-left.fa-w-10 {
    font-size: 2em;
    color:#fff
  }

  svg.lg.svg-inline--fa.fa-chevron-left.fa-w-10 :hover {
    cursor: pointer;
    color: #51B592;
  }

  .v3dp__datepicker {
    .v3dp__input_wrapper {
      input {
        padding: .5rem .3rem;
        border: none;
        border-bottom: 2px solid #0e3753;
        font-size: 1rem;
        max-width: 120px;
        text-align: right;
      }
    }
  }
  .svg-icon-secondary-colour {
    path {
      fill: var(--LayoutIconSecondary);
    }
  }
  .svg-icon-primary-colour {
    path {
      fill: var(--LayoutIconPrimary);
    }
  }
  .link {
    &_dotted {
      // color: rgba(41, 128, 185, 0.7);
      color: #2c3e50;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: bold;
      font-size: 15px;
      line-height: 100%;
      padding-bottom: 4px;
      border-bottom: 1px dashed #2c3e50;
      cursor: pointer;
    }
    &__back {
      color: #2c3e50;
      text-decoration: none;
      font-size: 15px;
      margin-bottom: 14px;
      display: flex;
      cursor: pointer;
      span {
        padding-bottom: 2px;
        font-weight: bold;
        border-bottom: 1px solid #2c3e50;
        margin-left: 5px;
        display: block;
      }
    }
  }
  .error {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    input {
      border-color: #DB5151!important;
    }
    &.validation_text {
      color:  #DB5151!important;
    }
  }
  .validation_text {
      position: absolute;
      left: 0;
      bottom: 0;
      color: #DB5151;
  }

</style>


<style scoped lang="scss">
.non_auth{
  //background-image: url('/img/login-banner.52fcd81d.jpg');
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
.order-page-item-wizard-modal {
  height: calc(100% - 50px) !important;
  .modal {
    transform: translate(0, 0);
    left: 0;
    padding: 0;
    top: 0!important;
  }

  .modal-wrapper.show .modal {
    top: 0!important;
  }

  .wizard__content {
    width: 93vw;
    overflow-x: scroll;
  }

  .modal-wrapper.delete-confirmation-modal {
    display: flex!important;
    align-items: center;
    justify-content: center;
  }

  .line.example__line_width {
    transform: none;
  }

  .enter-size__overall {
    width: max-content;
  }
}
/*.container_fluid{
  height:90vh;
}*/

</style>
