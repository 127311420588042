import axios from 'axios';

class Request {
    constructor(options) {

        this.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-origin': this.getOrigin(),
        };

        this.loginUrl = _.has(options, 'loginUrl') ? options.loginUrl : '/';

        if (_.has(options, 'headers')) {
            this.headers = Object.assign(this.headers, options.headers)
        }

        let vm = this;

        this.instance = axios.create({
            baseURL: _.has(options, 'baseURL') ? options.baseURL : '/',
            withCredentials: _.has(options, 'timeout') ? options.withCredentials : false,
            timeout: _.has(options, 'timeout') ? options.timeout : 0,
            headers: vm.headers,
            validateStatus: function (status) {
                vm.checkAuth(status);
                return status >= 200 && status < 300;
            },
        });

        /*
        API will pass the current-version header with each request and that header is almost exposed
        to all the responses. So once response received we can check the current header value and
        pre saved header value at localstorage. If values dont match tab will be hard reloaded and
        since from then index.html make a request to API and store the newest version value to the
         localstorage. So from next request axios will pass the new version.
         */
        this.instance.interceptors.response.use((response) => {
         /* if(!(window.location.host.includes('localhost')) && response && response.headers &&
            Number(response.headers['current-version']) !== Number(localStorage.getItem('current_version'))) {
            window.location.reload(true)
          }*/
          return response;
        })

        this.checkExistsToken();

    }
    getOrigin() {
      const origin = window.location.origin
      const isLocalhost = origin.includes('localhost')
      return isLocalhost ? process.env.VUE_APP_ORIGIN : origin
    }
    hasToken() {
        return !!localStorage.getItem('token')
    }

    checkExistsToken() {
        if (localStorage.getItem('token')) {
            this.instance.defaults.headers.common['Authorization'] = Request.prepareToken(localStorage.getItem('token'));
            return true;
        }

        localStorage.removeItem('token');
    }

    make(url, method = 'get', data = {}) {

        if (_.has(data, 'headers')) {
            delete data.headers;
        }

        return this.instance[method](url, Request.prepareSendData(method, data));

    }

    static prepareSendData(method, data) {

        if (!['get'].includes(method)) {
            return data;
        }

        if (Object.keys(data).length > 0 && !_.has(data, 'params')) {
            return {
                params: data
            }
        }

        return data;
    }

    checkAuth(status) {
        if (status === 401) {
            this.logout()
        }
    }

    logout() {
        localStorage.removeItem('token');
        if (window.location.pathname !== this.loginUrl) {
            window.location = this.loginUrl
        }
    }

    login(data) {
        this.setToken(data.token)
    }

    setToken(token) {
        localStorage.setItem('token', token);
        localStorage.setItem('user-token', token);
        this.instance.defaults.headers['Authorization'] = Request.prepareToken(token);
        this.instance.defaults.headers.common['Authorization'] = Request.prepareToken(token);
    }

    static prepareToken(token) {
        return 'Bearer ' + _.toString(token.trim());
    }

}

export default Request;
