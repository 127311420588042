import {httpReq} from "@/htttpReq";


export default {
  namespaced: true,
  state: {
    data: {},
  },
  getters: {
    data(state) {
      return state.data;
    },
  },
  mutations: {

    data(state, payload) {
      state.data = payload;
    },

    runReport(state, payload){

        httpReq.get('dashboard/finances', payload)
            .then(response => {
                state.data = response.data;
            })
            .catch(error => {
            })
            .finally(() => {
            })
    }
  },
  actions: {

  },
};
