import {httpReq} from "@/htttpReq";
import store from "@/store/store";

const status = {
  ON_HOLD: 'On Hold',
  PENDING_APPROVAL: 'Pending Approval'
}

export default {
  namespaced: true,
  state: {
    data: [],
  },
  getters: {
    data(state) {
      return state.data;
    },
  },
  mutations: {
      setData(state, payload) {
        state.data = _.orderBy(payload, ['name'], ['asc'])
      },
      markCancel(state, {dealerId, order}) {
        let data = state.data
        let dealer = data.find(d => d.id === dealerId)
        dealer.orders = dealer.orders.filter(o => o.number !== order.number)
        if(!dealer.orders.length) {
          data = data.filter(dealer => dealer.id !== dealerId)
        }
        state.data = data
      },
      markHold(state, {dealerId, orderIds}) {
        let data = state.data
        let dealer = data.find(d => d.id === dealerId)
        const affectedOrders = dealer.orders.filter(o => orderIds.includes(o.number))
        affectedOrders.forEach(order => {
          order.status = status.ON_HOLD
        })
        state.data = data
      },
      markApproved(state, {dealerId, orderIds}) {
        let data = state.data
        data.forEach(dealer => {
          dealer.orders.filter(x => orderIds.includes(x.number)).forEach(x => x.isApproved = true)
        })
        
        state.data = data
      }
  },
    actions: {
        async load({commit, state, dispatch}) {
          store.commit('preloader/preloader', true)
          try {
              const response = await httpReq.get('starline/financial-controller/get')
              commit('setData', _.toArray(response.data) )
          }catch(error) {
              store.commit('errorsModal/openModal', error.response.data.errors)
          }
          finally {
            store.commit('preloader/preloader', false)
          }
        },
        async cancelOne({commit, state, dispatch}, {dealerId, order}) {
            const response = await httpReq.post('starline/financial-controller/cancel', {orders: [order.number]})
            commit('markCancel', {dealerId, order} )
            return true
        },
        async hold({commit, state, dispatch}, {dealerId, orders}) {
          const orderIds = orders.map(o => o.number)
          const response = await httpReq.post('starline/financial-controller/hold', {orders: orderIds})
          commit('markHold', {dealerId, orderIds} )
          return true
        },
        async approve({commit, state, dispatch}, {dealerId, orders}) {
          const orderIds = orders.map(o => o.number)
          const response = await httpReq.post('starline/financial-controller/approve', {orders: orders})
          commit('markApproved', {dealerId, orderIds} )
          return true
        }

    },
}
