/* eslint-disable no-alert, no-console */
import { createApp } from 'vue';
import moment from 'moment'
import Request from './http/request'
import Notifications from '@kyvg/vue3-notification'

const EXCLUDED_PROD = [
    '52.59.217.237',
    'localhost'
]

if (typeof process.env.VUE_APP_URL !== 'undefined') {
    const API_URL = process.env.VUE_APP_URL
    let p = 'http'

    if (typeof process.env.VUE_APP_API_PROTOCOL !== 'undefined')
        p = process.env.VUE_APP_API_PROTOCOL

    window.request = new Request({baseURL: `${p}://${API_URL}/api/`, loginUrl: '/login'})
}
else {
    const API_URL = !EXCLUDED_PROD.includes(window.location.hostname)
        ? 'api.' + window.location.host
        : 'api.ss-portal.x.devitprosteer.com'
    window.request = new Request({baseURL: `https://${API_URL}/api/`, loginUrl: '/login'})
}

import App from './App.vue'
import router from './router'
import store from './store/store'
import {_} from 'lodash'
import PortalVue from 'portal-vue'
import SvgIcon from './components/SvgIconNew.vue'
import SvgIconQouting from './components/item-wizard/components/elements/SvgIcon.vue'
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import "vue3-date-time-picker/dist/main.css";

import './index.css'
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorField from "@/components/ErrorField";
import InlineSvg from 'vue-inline-svg'
import 'vue-cal/dist/vuecal.css'
import "./vee-validate-config.js"

//for events since v3 removed $on, $off and $once .
import mitt from 'mitt'
export const eventBus = mitt()


const app = createApp(App)

import BaseModalComponent from "./components/commons/BaseModalComponent";

import  VueGoogleMaps from '@fawmi/vue-google-maps'
app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: ["places", "geometry"]
  },
})

app.use(Toast, {
  position: "bottom-right",
  transition: "Vue-Toastification__fade",
});

app.use(store)
app.use(PortalVue)
app.component('Form',Form)
app.component('Field',Field)
app.component('ErrorField',ErrorField)
app.component('ErrorMessage',ErrorMessage)
app.component('svg-icon', SvgIcon)
app.component('svg-icon-qouting', SvgIconQouting)
app.component('inline-svg', InlineSvg)
app.component('base-modal', BaseModalComponent)
app.use(Notifications)
  app.use(router)
//createApp(App).use(store).use(PortalVue)
//.component('font-awesome-icon', FontAwesomeIcon)
/*.use(veeValidate, {
    events: 'input',
    classes: true,
    classNames: {
        invalid: 'form__input_error',
    }
})*//*.use(VueTheMask)*/

app.config.globalProperties.$filters = {
  formatDate(value) {
    return moment(String(value)).format('DD MMM YYYY')
  },
  formatMoney(value) {
    if (value){
      let val = (value/1).toFixed(2).replace(',', '.')
      return '$' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    return '$0.00'
  }
}

app.mount('#app')
