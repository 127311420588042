<template>
  <div
    class="error_field"
    :class="isError ? 'error_field--error': ''">
    <div
      v-show="isError"
      class="text__error">
      {{ textError }}
    </div>
    <slot></slot>
  </div>
</template>

<script>

export default ({
  name: "ErrorField",
  components: {
  },
  props: ['textError', 'isError'],
  emits: ['close'],
  setup(props, {emit}) {
    return {
    }
  }
})
</script>

<style scoped lang="scss">
.error_field {
  position: relative;
  &--error {
    animation-name: bounce;
    animation-duration: .5s;
    animation-delay: 0.2s;
    ::v-deep input {
      border: 1px solid #C0392B;
    }
    ::v-deep textarea {
      border: 1px solid #C0392B;
    }
    .text {
      &__error {
        position: absolute;
        left: 10px;
        white-space: nowrap;
        bottom: -16px;
        font-size: 13px;
        letter-spacing: 0.02em;
        color: #C0392B;
      }
    }
  }
}
@keyframes bounce {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(4px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-4px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(3px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-3px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(1px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-1px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
}

</style>
