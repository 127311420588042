<template>
  <div class="preloader">
    <div class="preloader__inner">
      <span class="preloader__img">
        <svg-icon name="solid/spinner"></svg-icon>
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Preloader",
  }
</script>

<style scoped>

</style>
