export default {
  namespaced: true,
  state: {
    items: [
      {
        img: 'file-circle-plus',
        text: 'Create New Order',
        link: {name: 'order-item'},
        params: {
          id: 'new'
        }
      },
      {
        img: 'clipboard-list',
        text: 'My Jobs',
        link: {name: 'jobs'}
      },
      {
        img: 'clipboard',
        text: 'My Orders',
        link: {name: 'my-orders'}
      },
      // {
      //   img: 'user-cog',
      //   text: 'Account Settings',
      //   link: {name: 'settings'},
      // },
      {
        img: 'question-circle',
        text: 'Get Help With The System',
        link: 'https://help.quotingportal.com.au/tutorial-videos',
        target: 'blank'
      },
      {
        img: 'user-cog',
        // img: 'hand-holding-usd',
        text: 'Account information',
        link: {name: 'settings.account'},
        // link: {name: 'finances'},
        //admin: true
      },
    ],
  },
  getters: {
    items(state) {
      return state.items;
    },
  },
  mutations: {

  },
  actions: {

  },
};
