import {httpReq} from "@/htttpReq";
import store from "../../store";

export default {
  namespaced: true,

  state: {
    financials: {}
  },

  getters: {
    financials(state) {
      return state.financials;
    }
  },

  mutations: {
    getFinancials(state, payload) {
      state.financials = payload;
    }
  },

  actions: {
    getFinancial({commit}) {
      store.commit('preloader/preloader', true);
      httpReq.get('user/wholesale/financials-details')
        .then(response => {
          commit('getFinancials', response.data);
        })
        .catch(error => {
          if (error.response.status == '401') {
            localStorage.removeItem('user-token');
            localStorage.removeItem('nameUser');
            window.location = '/login';
          }
          store.commit('errorsModal/openModal', error.response.data.errors);
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },

    postUpdateFinancials({commit}, credentials) {
      store.commit('preloader/preloader', true);
      httpReq.post('user/wholesale/financials-details', credentials.financials)
        .then(response => {
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors);
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    }
  },
};
