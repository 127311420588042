import {httpReq} from '@/htttpReq'
import store from '../../store'

export default {
    namespaced: true,

    state: {
        grade: {},
        location: '',
    },

    getters: {
        grade(state) {
            return state.grade
        }
    },

    mutations: {
        updateGrade(state, payload) {
            state.grade = payload
        },
    },

    actions: {
        getGrade({commit}) {
            store.commit('preloader/preloader', true)
            httpReq.get('order/order-grades/' + store.getters['orderPosition/orderPosition'].item.type_id + '/' + store.getters['orderPosition/orderPosition'].item.style_id)
                .then(response => {
                    commit('updateGrade', response.data)
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },
        getGradeFromStyle({commit}, payload) {
            store.commit('preloader/preloader', true)
            httpReq.get('order/order-grades/' + payload.type + '/' + payload.style)
                .then(response => {
                    commit('updateGrade', response.data)
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },

        getGradeToPackage({commit}) {
            store.commit('preloader/preloader', true)

            let config = {
                params: {
                    type: store.getters['orderPosition/orderPosition'].type.id,
                    styles_id: store.getters['createdOrder/filterStyle'],
                }
            }

            httpReq.get('package/grades', config)
                .then(response => {
                    commit('updateGrade', response.data.data)
                })
                .catch(error => {
                    store.commit('errorsModal/openModal', error.response.data.errors)
                })
                .finally(() => {
                    store.commit('preloader/preloader', false)
                })
        },
    },
}
