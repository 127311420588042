import store from "../store";
export default {
  namespaced: true,

  state: {
    menu: {},
  },
  getters: {
    menu(state) {
      _.forEach(state.menu, item => {
        let result = item.url.match(/http/);
        if(result !== null){
          item.link = true
        }
      });
      return state.menu
    },
    isLogin(state){
      return state.isLogin;
    }
  },
  mutations: {
    getMenu(state, payload){
      state.menu = payload;
    },
  },
  actions: {
    menu(context){
      // store.commit('preloader/preloader', true);
      request.make('site/static', 'get').then(response => {
          context.commit('getMenu', response.data.header.mainMenu);
        })
        .finally(() => {
          // store.commit('preloader/preloader', false);
        });
    }
  },
};
