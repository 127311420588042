var dateFormat = require('dateformat');

export function formatDate(date) {
if (date){
  return dateFormat(date, 'dd/mm/yyyy');
}
}

export function compareDate(a, b) {

    a = new Date(a);
    b = new Date(b);

    return (a > b) ? 1 : (a < b) ? 2 : 0

}

export function dateCalcFormat(date, showTime = false, prettyFormat = true) {
  const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  const months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  if (date instanceof Date){
    if (showTime)
      return date.toLocaleString('en-AU')

    return date.toLocaleDateString('en-AU')
  }
  else if (date) {
    const date1 = new Date(date.substr(0,10));
    const date2 = new Date();
    const diffTime = date1 - date2;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    let h = date.substr(11,2)
    let m = date.substr(14,2)
    let suffix = "am"
    h = parseInt(h)
    if (h > 12) {
      h -= 12;
      suffix = "pm";
    }
    h = h % 12;
    h = h ? h : 12;
    let timeString = `${h}:${m} ${suffix}`;
    if (prettyFormat) {
      if (diffDays == 1) {
        return 'Tomorrow' + (showTime ? ' ' + timeString : '')
      } else if (diffDays == -1) {
        return 'Yesterday' + (showTime ? ' ' + timeString : '')
      } else if (diffDays < 7 && diffDays > 1) {
        return new Intl.DateTimeFormat('en-AU', { day: '2-digit', year: 'numeric', month: 'short' }).format(date1) + (showTime ? ' ' + timeString : '')
      } else if (diffDays == 0) {
        if (h && m) {
          if (showTime) {
            return `Today ${h}:${m} ${suffix}`
          }
          return `${h}:${m} ${suffix}`
        }
      }
    }
    return date1.getDate() + " " + months[date1.getMonth()] + " " + date1.getFullYear() + (showTime ? ' ' + timeString : '')
  }
}
export function dateRemainFormat(date, newDate) {
  if (date) {
    const date1 = new Date(date.substr(0,16));
    const date2 = new Date(newDate);
    const diffTime = date1 - date2;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60)) % 24;
    const diffMinutes = Math.floor(diffTime / (1000 * 60)) % (24*60) % 60;
    return {diffDays, diffHours, diffMinutes}
  }
}
export function dateForRequest(date) {
  if (!date)
    return  null;
  return `${date.substr(10,4)}-${date.substr(5,2)}-${date.substr(0,2)}`

}
export function dateForCheckMeasureDate(date) {
  if (date) {
    return `${date.substr(8,2)} / ${date.substr(5,2)} / ${date.substr(0,4)}`
  }
}
export function timeForCheckMeasureDate(time) {
  if (time) {
    let h = parseInt(time.substr(11,2))
    if (h > 12) {
      h -= 12
    }
    if (h < 10) {
      h ='0'+h
    }
    return `${h}:${time.substr(14,2)}}`
  }
}
export function isAmForCheckMeasureDate(isAm) {
  if (isAm) {
    let h = parseInt(isAm.substr(11,2))
    if (h > 12) {
      return false
    } else return true
  }
}
export function timeForRequest(time, activeIsAm) {
  if (!time)
    return null
  let h = time.substr(0,2)
  if (h == '12') {
    h = '00'
  }
  if (activeIsAm == false) {
    h = parseInt(h) + 12
  }
  let m = time.substr(7,2)
  return `${h}:${m}`

}

export function validateDate(value){
    const split = value.split('/')
    if(split.length !== 3) return false
    return !(split[0].trim().length !== 2 || split[1].trim().length !== 2 || split[2].trim().length !== 4);
}

export function validateTime(value) {

  let hours = null
  let minutes = null

  const split = value.split(":")

  if(split.length !== 2) return false

  hours = parseInt(split[0].trim())
  minutes = parseInt(split[1].trim())


  return (hours.toString().length <= 2 && minutes.toString().length <= 2 && hours <= 12 &&  minutes <= 59);

}
