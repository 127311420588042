import {httpReq} from "../../../htttpReq";
import router from "../../../router";
import store from "../../store";

export default {
  namespaced: true,
  state: {
    isUpdate: false,
    itemId: 0
  },
  getters: {
    get: state => key => {
      return _.has(state, key) ? state[key] : null;
    },
    isUpdate(state){
      return state.isUpdate;
    }
  },
  mutations: {
    set(state, payload) {
      state[payload.k] = payload.v;
    },
  },
  actions: {
    prepareUpdate({commit, state}, p) {
      state.isUpdate = true;
      state.itemId = p.item.item_id;
      store.commit('createdOrder/selectModal', true);
      // TODO remove after coompleted BE
      p.item.colour_id = _.has(p.item, 'colour_id') ? p.item.colour_id : 0;
      p.item.colour_name = _.has(p.item, 'colour_name') ? p.item.colour_name : 'Empty';
      p.item.colour_id = _.isNull(p.item.colour_id) ? 0 : p.item.colour_id;
      p.item.colour_name = _.isNull(p.item.colour_name) ? 'Empty' : p.item.colour_name;

      store.commit('createdOrder/updateType', _.toInteger(p.item.type_id));
      store.commit('createdOrder/updateStyle', _.toInteger(p.item.style_id));
      store.commit('createdOrder/updateGrade', _.toInteger(p.item.grade_id));
      store.commit('createdOrder/updateName', _.toString(p.room));
      store.commit('createdOrder/updateWidth', _.toString(p.item.length1));
      store.commit('createdOrder/updateDrop', _.toString(p.item.length2));
      store.commit('createdOrder/updateColors', _.toString(p.item.colour_id));

      if (Object.keys(p.item.accessories).length) {
        store.commit(
          'createdOrder/setAccessories',
          _.toArray(_.reduce(p.item.accessories, (memo, item, key) => {
              item.lengthVal = {
                l1: item.length1,
                l2: item.length2,
                l3: item.length3,
                l4: item.length4,
              };
              item.mitreCut = item.mitre;
              item.name = item.get_item.name;
              item.type = item.get_item.type;
              item.typeName = '';
              memo[key] = item;
              return memo;
            }, {}
          ))
        );
      }

      if (Object.keys(p.item.options).length) {

        store.commit(
          'createdOrder/setOptions',
          _.reduce(p.item.options, (memo, item, key) => {
              item.components = item.settings.components;
              item.settings = item.settings.settings;
              memo[key] = item;
              return memo;
            }, {}
          )
        );
      }

      // store.dispatch('options/getOptions');

      store.commit(
        'app/set',
        {
          k: 'breadcrumbs',
          v: [
            {
              route: {
                path: '/order-preview/0'
              },
              name: p.room,
              disabled: true,
            },
            {
              route: {
                path: `/order-preview/0/${p.item.type_id}`
              },
              name: p.item.type_name,
              disabled: false,
            },
            {
              route: {
                path: `/order-preview/0/${_.toInteger(p.item.type_id)}/${p.item.style_id}`
              },
              name: p.item.style_name,
              disabled: false,
            },
            {
              route: {
                path: `/order-preview/0/${_.toInteger(p.item.type_id)}/${_.toInteger(p.item.style_id)}/${p.item.grade_id}/0`
              },
              name: p.item.grade_name,
              disabled: false,
            },
            {
              route: {
                path: `/order-preview/0/${_.toInteger(p.item.type_id)}/${_.toInteger(p.item.style_id)}/${p.item.grade_id}/0/${p.item.colour_id}`
              },
              name: p.item.colour_name,
              disabled: false,
            },
          ]
        }
      );

      router.push({
        path: `/order-preview/0/${_.toInteger(p.item.type_id)}/${_.toInteger(p.item.style_id)}/${_.toInteger(p.item.grade_id)}/0/${p.item.colour_id}`
      });

    },
    uploadOSQFiles({commit, state}, orderPositionId) {
      let data = store.getters['createdOrder/osqStandardFiles'];

      request.make(
        `order/upload-option-file/${_.toInteger(orderPositionId)}`,
        'post',
        data
      ).then((response) => {
        store.commit('createdOrder/setOsqStandardFiles', new FormData());
      }).catch(
        error => {
          store.commit('errorsModal/openModal', error.response.data.errors);
          if (_.toInteger(error.response.status) === 401) {
            localStorage.removeItem('user-token');
            localStorage.removeItem('nameUser');
            window.location = '/login';
          }
        }
      );

    },
    update({commit, state, dispatch}, p) {
      let data = store.getters['orderPosition/orderPosition'];
      store.commit('preloader/preloader', true);

      httpReq.put('order/update-item', data)
        .then((response) => {
          if (response.data) {
            if (response.data.data.order_position_option_id) {
              dispatch('uploadOSQFiles', _.toInteger(response.data.data.order_position_option_id))
            }
            store.commit('orderPreview/positionBuilder', false);
            store.dispatch('orderPreview/getPreview');
          }
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors);
          if (error.response.status == '401') {
            localStorage.removeItem('user-token');
            localStorage.removeItem('nameUser');
            window.location = '/login';
          }
        })
        .finally((response) => {
          store.commit('preloader/preloader', false);
        });
    },

    updateAdditionalAccessory({commit, state, dispatch}, p) {

      store.commit('preloader/preloader', true);
      httpReq.put('order/update-additional-accessory',
          {
              item_id: p.item,
              measureType: p.measureType,
              lengthVal:  p.lengthVal,
              squareVal:  p.squareVal,
              quantity:  p.quantity,
              miterCut: p.miterCut,
              main_item_id: p.main_item_id,
              width: p.width,
              drop: p.drop,
              grade_id: p.grade,
              style_id: p.style,
              type_id: p.type
          }).then((response) => {
            if (response.data) {
              store.commit('createdOrder/selectModal', false);
              state.itemId = 0;
              state.isUpdate = false;
              let oid = data.id;
              store.commit('createdOrder/cleanOrderObj');
              router.push({path: '/order-preview/' + oid});
              store.dispatch('orderPreview/getPreview');
            }
          })
          .catch(error => {
            if (error.response && error.response.status == '401') {
              store.commit('errorsModal/openModal', error.response.data.errors);
              localStorage.removeItem('user-token');
              localStorage.removeItem('nameUser');
              window.location = '/login';
            }
          })
          .finally((response) => {
            store.commit('preloader/preloader', false);
          });
    },

    prepareUpdateFromPreview({commit, state}, p) {
      state.isUpdate = true;
      state.itemId = p.item.item_id;
      localStorage.setItem('order_id', p.order.order_id);

      store.commit('createdOrder/selectModal', true);

      // TODO remove after coompleted BE
      p.item.colour_id = _.has(p.item, 'colour_id') ? p.item.colour_id : 0;
      p.item.colour_name = _.has(p.item, 'colour_name') ? p.item.colour_name : 'Empty';
      p.item.colour_id = _.isNull(p.item.colour_id) ? 0 : p.item.colour_id;
      p.item.colour_name = _.isNull(p.item.colour_name) ? 'Empty' : p.item.colour_name;
      p.item.insert_colour_id = _.isNull(p.item.insert_colour_id) ? 0 : p.item.insert_colour_id;
      p.item.insert_colour_name = _.isNull(p.item.insert_colour_name) ? 'Empty' : p.item.insert_colour_name;

      store.commit('createdOrder/updateOrderId', _.toInteger(p.order.order_id));
      store.commit('createdOrder/updateType', _.toInteger(p.item.type_id));
      store.commit('createdOrder/updateStyle', _.toInteger(p.item.style_id));
      store.commit('createdOrder/updateGrade', _.toInteger(p.item.grade_id));
      store.commit('createdOrder/updateName', _.toString(p.room));
      store.commit('createdOrder/updateWidth', _.toString(p.item.width));
      store.commit('createdOrder/updateDrop', _.toString(p.item.drop));
      store.commit('createdOrder/updateColors', _.toString(p.item.colour_id));
      store.commit('createdOrder/updateInsertColors', p.item.insert_colour_id);
      store.commit('createdOrder/updateOrderInsert', _.toString(p.item.ins));
      store.commit('createdOrder/updateOrderMesh', _.toString(p.item.mesh));
      store.commit('createdOrder/updateItemId', _.toString(p.item.item_id));


      p.item.accessories = _.isNull(p.item.accessories) || _.isUndefined(p.item.accessories) ? {} : p.item.accessories;

      if (Object.keys(p.item.accessories).length) {

        store.commit(
          'createdOrder/setAccessories',
          _.toArray(_.reduce(p.item.accessories, (memo, item, key) => {
              item.lengthVal = item.lengthVal;
              item.mitreCut = item.mitre;
              item.typeName = '';
              memo[key] = item;
              return memo;
            }, {}
          ))
        );
      }

      p.item.options = _.isNull(p.item.options) || _.isUndefined(p.item.options) ? [] : p.item.options;

      // store.dispatch('options/getOptions');
      if (Object.keys(p.item.options).length) {
        store.commit('options/setOldOptions', p.item.options);
        store.commit('createdOrder/setOptions', _.reduce(p.item.options, (memo, item, key) => {
            item.components = item.components;
            item.settings = item.settings;
            memo[key] = item;
            return memo;
          }, {}
          )
        );
      }

      store.commit('app/set',
        {
          k: 'breadcrumbs',
          v: [
            {
              route: {
                path: '/order-preview/0'
              },
              name: p.room,
              disabled: false,
              parentParams: {
                goToOptions: true,
                itemId: state.itemId,
                nextPath: `/order-preview/0/${_.toInteger(p.item.type_id)}/${_.toInteger(p.item.style_id)}/${p.item.grade_id}/0/${p.item.colour_id}/${p.item.insert_colour_id}`
              },
            },
            {
              route: {
                path: `/order-preview/0/${p.item.type_id}`
              },
              parentParams: {
                goToOptions: true,
                itemId: state.itemId,
                nextPath: `/order-preview/0/${_.toInteger(p.item.type_id)}/${_.toInteger(p.item.style_id)}/${p.item.grade_id}/0/${p.item.colour_id}/${p.item.insert_colour_id}`
              },
              name: _.toInteger(p.item.width) + ' x ' + _.toInteger(p.item.drop),
              disabled: false,
            },
            {
              route: {
                path: `/order-preview/0/${p.item.type_id}`
              },
              toEdit: true,
              name: p.item.type_name,
              disabled: false,
            },
            {
              route: {
                path: `/order-preview/0/${_.toInteger(p.item.type_id)}/${p.item.style_id}`
              },
              name: p.item.style_name,
              disabled: false,
            },
            {
              route: {
                path: `/order-preview/0/${_.toInteger(p.item.type_id)}/${_.toInteger(p.item.style_id)}/${p.item.grade_id}`
              },
              name: p.item.grade_name,
              disabled: false,
            },
            {
              route: {
                path: `/order-preview/0/${_.toInteger(p.item.type_id)}/${_.toInteger(p.item.style_id)}/${p.item.grade_id}/0/${p.item.colour_id}/${p.item.insert_colour_id}`
              },
              name: p.item.colour_name,
              disabled: false,
            },
          ]
        }
      );

      router.push({
        path: `/order-preview/0/${_.toInteger(p.item.type_id)}/${_.toInteger(p.item.style_id)}/${p.item.grade_id}/0/${p.item.colour_id}/${p.item.insert_colour_id}`
      });
    },

    delete({commit}, p) {

      httpReq.delete('order/delete-item',
        {
          data: {order_id: p.id, item_id: p.itemId}
        }
      ).then((r) => {
        store.dispatch('orderItems/getOrders');
        store.commit('orderPreview/id', p.id);
        if(p.location === undefined) {
          store.dispatch('orderPreview/getPreview');
        }
      }).catch(error => {
        store.commit('errorsModal/openModal', error.response.data.errors);
      }).finally(() => {
        store.commit('preloader/preloader', false);
        store.commit('createdOrder/deleteModal', false);
      })
    },
  },


};
