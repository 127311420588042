import store from '../../store'
import router from '../../../router'
import {httpReq} from '@/htttpReq'

export default {
  namespaced: true,

  state: {
    leadsList: [],
    leadsPagination: {},
    leadsCount: null,
    leadsSorting: null,
    leadsGrouping: null,
    leadsSearch: null,
    leadItem: {},
    priceList: [],
    rejectionReasons: []
  },

  getters: {
    leadsList(state) {
      return state.leadsList || []
    },
    leadsAmount(state) {
      return state.leadsPagination.total || ''
    },
    leadsNextPage(state)  {
      return state.leadsPagination?.current_page + 1 || 1
    },
    leadsTotalPages(state) {
      return state.leadsPagination?.total_pages || 1
    },
    leadsSorting(state) {
      return state.leadsSorting
    },
    leadsGrouping(state) {
      return state.leadsGrouping
    },
    leadsCount(state) {
      return state.leadsCount
    },
    leadItem(state) {
      return state.leadItem
    },
    priceList(state) {
      let prices = state.priceList.map(reason => {
        return reason.name
      })
      return prices
    },
    priceListFull(state) {
      return state.priceList
    },
    priceListElementValue:(state) => (payload) => {
      return state.priceList.find(element => element.id === payload)?.id || state.priceList.find(element => element.is_default == 1)?.id
    },
    leadProperty: (state) => (payload) => {
      if (payload.key_2) {
        return state.leadItem[payload.key]?.[payload.key_2]
      } else {
        return state.leadItem?.[payload.key]
      }
    },
    rejectionReasons(state) {
      let reasons = state.rejectionReasons.map(reason => {
        return reason.reason
      })
      reasons.push('Other reason')
      return reasons
    },
    leadsSearch(state) {
      return state.leadsSearch
    },
  },

  mutations: {
    leadsList(state, payload){
      state.leadsList.push(...payload)
    },
    leadsPagination(state, payload){
      state.leadsPagination = payload;
    },
    leadsSearch(state, payload){
      state.leadsSearch = payload;
    },
    leadsSorting(state, payload){
      state.leadsSorting = payload;
    },
    leadsGrouping(state, payload){
      state.leadsGrouping = payload;
    },
    leadsCount(state, payload){
      state.leadsCount = payload;
    },
    leadItem(state, payload){
      state.leadItem = payload
    },
    leadProperty(state, payload){
      if (payload.key_2) {
        if (!state.leadItem[payload.key]) {
          state.leadItem[payload.key] = {}
        }
        state.leadItem[payload.key][payload.key_2]= payload.value;
      } else {
        state.leadItem[payload.key] = payload.value;
      }
    },
    rejectionReasons(state, payload){
      state.rejectionReasons = payload
    },
    priceList(state, payload){
      state.priceList = payload
    },
    resetLeads(state, payload){
      state.leadsList = [];
      state.leadsPagination = {};
    },
    resetSorting(state, payload){
      state.leadsSorting = null
    },
    resetGrouping(state, payload){
      state.leadsGrouping = null
    },
    resetLeadItem(state, payload){
      state.leadItem = {}
    },
  },

  actions: {
    getLeadsList({state, getters, commit, dispatch}, payload) {
      let parameters = {
      }
      Object.assign(parameters, {page: getters.leadsNextPage})
      if (getters.leadsSorting?.trim() !== "null") {Object.assign(parameters, {sort: getters.leadsSorting})}
      if (getters.leadsGrouping) {Object.assign(parameters, {group_by: getters.leadsGrouping})}
      let pagintation = getters.leadsGrouping ? 10 : 15
      if (getters.leadsNextPage <= getters.leadsTotalPages) {
        return httpReq.get(`ordering-portal/my-leads?paginate=${pagintation}&filter[search]=${getters.leadsSearch || ''}`,
          parameters
        )
          .then(response => {
            commit('leadsList', response.data?.data);
            commit('leadsPagination', response.data?.pagination);
          })
      }
    },
    getLeadsCount({state, getters, commit}, payload) {
      return httpReq.get(`ordering-portal/my-leads/count`,
      )
        .then(response => {
          commit('leadsCount', response.data.data?.my_leads_count);
        })
    },
    getLeadItem({state, getters, commit}, payload) {
      return httpReq.get(`ordering-portal/my-leads/${payload}`)
        .then(response => {
          commit('leadItem', response.data?.data);
        })
    },
    getRejectionReasons({state, getters, commit}, payload) {
      return httpReq.get(`ordering-portal/my-leads/rejection-reasons`
      )
        .then(response => {
          commit('rejectionReasons', response.data?.data);
        })
    },
    getPriceList({state, getters, commit}, payload) {
      return httpReq.get(`ordering-portal/drop-down/price-lists`,
      )
        .then(response => {
          commit('priceList', response.data?.data);
        })
    },
    getLeadItemChangedByPriceList({state, getters, commit}, payload) {
      // store.commit('preloader/preloader', true);
      let parameters = {
      }
      return httpReq.get(`ordering-portal/my-leads/${payload.lead_id}/price-list-changed/${payload.pricelist_id}`,
        parameters
      )
        .then(response => {
          // commit('leadItem', response.data?.data);
        })
        .finally(() => {
          // store.commit('preloader/preloader', false);
        })
    },
    changeLeadsStatus({state, getters, commit}, payload) {
      store.commit('preloader/preloader', true);
      let parameters = {
      }
      if (payload.parameters) {
        Object.assign(parameters, payload.parameters);
      }
      return httpReq.put(`ordering-portal/my-leads/${payload.id}/${payload.status}`,
        parameters
      )
        .then(response => {
          commit('leadsSorting', getters.leadsSorting+' ');
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },
  },
};
