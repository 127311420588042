import store from '../../store'
import router from '../../../router'
import {httpReq} from '@/htttpReq'
import  {dateForCheckMeasureDate, timeForCheckMeasureDate , isAmForCheckMeasureDate} from "@/helpers/datetime";

export default {
  namespaced: true,

  state: {
    selectedJob : {},
    job: {},
    jobsSorting: 'by_date',
    jobsSearch: null,
    statusList: [
      {name:'Open', icon: 'solid/folder',ref:'ref_open'},
      {name: 'Quote', value:'Quotes & Estimates', icon: 'solid/envelope',ref:'ref_quotes'},
      {name:'In Production'  ,value:'Order Submitted', icon: 'solid/screwdriver-wrench',ref:'ref_in_production'},
      {name:'On The Way',value:'Order On the Way', icon: 'solid/shipping-fast',ref: 'ref_on_the_way'},
      {name:'Awaiting Installation', icon: 'solid/user-helmet-safety',ref:'ref_awaiting_installation'},
      {name:'Complete', icon: 'solid/archive',ref:'ref_complete'},
      {name:'Cancelled', icon: 'solid/xmark-to-slot',ref:'ref_cancelled'}],
    checkMeasureCalendars: [],
    checkMeasureData: [],
    orderContainer: {},
    orderContainerLocations: [],
    orderContainerAccessories: [],
    orderContainerSummary: {},
    jobPrice: {},
    jobAllItems: [],
    productMargins: {},
    states: [
      {name: 'Draft', icon: 'solid/pen-swirl', background: 'rgba(11, 53, 83, 0.15)'},
      {name: 'Estimate', icon: 'solid/comment-dollar', background: 'rgba(241, 196, 15, 0.35)'},
      {name: 'Order', icon: 'solid/file-check', background: 'rgba(89, 182, 146, 0.3)'},
      {name: 'Quote', icon: 'solid/file-check', background: 'rgba(89, 182, 146, 0.3)'},
    ],
    statuses: [
      {name: 'Draft',
        icon: '', iconColor: '',
        iconHeader: '', iconHeaderColor: '', iconHeaderBackground: ''},
      {name: 'Pending',
        icon: 'solid/clock', iconColor: 'linear-gradient(0deg, rgba(11, 53, 83, 0.3), rgba(11, 53, 83, 0.3)), linear-gradient(0deg, rgba(11, 53, 83, 0.1), rgba(11, 53, 83, 0.1)), #FFFFFF',
        iconHeader: 'solid/clock', iconHeaderColor: 'linear-gradient(0deg, rgba(11, 53, 83, 0.6), rgba(11, 53, 83, 0.6)), #FFFFFF', iconHeaderBackground: ''},
      {name: 'Review',
        icon: 'solid/pen-swirl', iconColor: 'rgba(11, 53, 83, 0.15)',
        iconHeader: '', iconHeaderColor: '', iconHeaderBackground: ''},
      {name: 'Accepted',
        icon: 'solid/check', iconColor: 'linear-gradient(0deg, rgba(89, 182, 146, 0.8), rgba(89, 182, 146, 0.8)), linear-gradient(0deg, rgba(11, 53, 83, 0.1), rgba(11, 53, 83, 0.1)), #FFFFFF',
        iconHeader: 'solid/check', iconHeaderSize: '12px', iconHeaderColor: '#1C2833', iconHeaderBackground: 'linear-gradient(0deg, rgba(89, 182, 146, 0.8), rgba(89, 182, 146, 0.8)), linear-gradient(0deg, rgba(11, 53, 83, 0.1), rgba(11, 53, 83, 0.1)), #FFFFFF'},
      {name: 'Rejected',
        icon: 'solid/times', iconColor: 'linear-gradient(0deg, rgba(192, 57, 43, 0.5), rgba(192, 57, 43, 0.5)), linear-gradient(0deg, rgba(11, 53, 83, 0.1), rgba(11, 53, 83, 0.1)), #FFFFFF',
        iconHeader: 'solid/times',iconHeaderSize: '14px', iconHeaderColor: 'white', iconHeaderBackground: 'linear-gradient(0deg, rgba(192, 57, 43, 0.5), rgba(192, 57, 43, 0.5)), linear-gradient(0deg, rgba(11, 53, 83, 0.1), rgba(11, 53, 83, 0.1)), #FFFFFF'},
      {name: 'On Hold',
        icon: 'solid/pen-swirl', iconColor: 'rgba(11, 53, 83, 0.15)',
        iconHeader: '', iconHeaderColor: '', iconHeaderBackground: ''},
      {name: 'Production Scheduled',
        icon: 'solid/pen-swirl', iconColor: 'rgba(11, 53, 83, 0.15)',
        iconHeader: '', iconHeaderColor: '', iconHeaderBackground: ''},
      {name: 'In Production',
        icon: 'solid/pen-swirl', iconColor: 'rgba(11, 53, 83, 0.15)',
        iconHeader: '', iconHeaderColor: '', iconHeaderBackground: ''},
      {name: 'Completed',
        icon: 'solid/pen-swirl', iconColor: 'rgba(11, 53, 83, 0.15)',
        iconHeader: '', iconHeaderColor: '', iconHeaderBackground: ''},
      {name: 'Dispatched',
        icon: 'solid/pen-swirl', iconColor: 'rgba(11, 53, 83, 0.15)',
        iconHeader: '', iconHeaderColor: '', iconHeaderBackground: ''},
    ]
  },

  getters: {
    jobsList: (state) => (payload) => {
      return state[`jobsList${payload.toString().replace(/\s/g, "")}`] || []
    },
    job(state) {
      return state.job
    },
    jobProperty: (state) => (payload) => {
      if (payload.key_2) {
        return state.job[payload.key]?.[payload.key_2]
      } else {
        return state.job?.[payload.key]
      }
    },
    jobDate(state) {
      return 'job_date' in state.job ? state.job.job_date : dateForCheckMeasureDate(state.job?.check_measure_date) || ''
    },
    jobTime(state) {
      return 'job_time' in state.job ? state.job.job_time : timeForCheckMeasureDate(state.job?.check_measure_date) || ''
    },
    jobIsAm(state) {
      return 'job_is_am' in state.job ? state.job.job_is_am : isAmForCheckMeasureDate(state.job?.check_measure_date) || false
    },
    jobID(state) {
      return state.job?.id || null
    },
    customerID(state) {
      return state.job?.customer_id || ''
    },
    customer(state) {
      return state.job?.customer || null
    },
    jobsAmount: (state) => (payload) => {
      return state[`jobsPagination${payload.toString().replace(/\s/g, "")}`]?.total || ''
    },
    jobsNextPage: (state) => (payload) => {
      return state[`jobsPagination${payload.toString().replace(/\s/g, "")}`]?.current_page + 1 || 1
    },
    jobsTotalPages: (state) => (payload) => {
      return state[`jobsPagination${payload.toString().replace(/\s/g, "")}`]?.total_pages || 1
    },
    jobContainers(state) {
      return state.job?.container || []
    },
    jobContainersID(state) {
      let ContainersID = [state.job?.container_id]
      return ContainersID || []
    },
    orderContainer(state) {
      return state.orderContainer || {}
    },
    orderContainerAccessories(state) {
      return state.orderContainerAccessories || []
    },
    orderContainerSummary(state) {
      return state.orderContainerSummary || {}
    },
    orderContainerSummaryTotal(state) {
      return state.orderContainerSummary.Total?.amount || ''
    },
    orderContainerLocations(state) {
      return state.orderContainerLocations || []
    },
    jobPrice(state) {
      return state.jobPrice || {}
    },
    jobPriceTotal(state) {
      return state.Total?.amount || ''
    },
    jobAllItems(state) {
      return state.jobAllItems || []
    },
    jobsSorting(state) {
      return state.jobsSorting
    },
    jobsSearch(state) {
      return state.jobsSearch
    },
    checkMeasureCalendars(state) {
      return state.checkMeasureCalendars
    },
    checkMeasureData(state) {
      return state.checkMeasureData
    },
    productMargins(state) {
      return state.productMargins
    },
    productMarginsProperty: (state) => (payload) => {
      if (payload.key_2) {
        return state.productMargins[payload.key]?.[payload.key_2]
      } else {
        return state.productMargins?.[payload.key]
      }
    },
    states(state) {
      return state.states
    },
    statuses(state) {
      return state.statuses
    },
    exactState: (state) => (payload) => {
      return state.states.find(state => state.name == payload) || {}
    },
    exactStatus: (state) => (payload) => {
      return state.statuses.find(status => status.name == payload) || {}
    },
    exactJobStatus: (state) => (payload) => {
      return state.statusList.find(status => status.name == payload) || {}
    }
  },

  mutations: {
    jobsList(state, payload){
      if(!state[`jobsList${payload[1]}`]) {
        state[`jobsList${payload[1]}`] = []
      }
      state[`jobsList${payload[1]}`].push(...payload[0])
    },
    job(state, payload){
      state.job = payload;
    },
    jobProperty(state, payload){
      if (payload.key_2) {
        if (!state.job[payload.key]) {
          state.job[payload.key] = {}
        }
        state.job[payload.key][payload.key_2]= payload.value;
      } else {
        state.job[payload.key] = payload.value;
      }
    },
    jobsPagination(state, payload){
      state[`jobsPagination${payload[1]}`] = payload[0];
    },
    orderContainer(state, payload){
      state.orderContainer = payload;
    },
    orderContainerAccessories(state, payload){
      state.orderContainerAccessories.push(payload);
    },
    orderContainerSummary(state, payload){
      state.orderContainerSummary = payload;
    },
    orderContainerLocations(state, payload){
      state.orderContainerLocations = payload;
    },
    jobPrice(state, payload){
      state.jobPrice = payload;
    },
    jobAllItems(state, payload){
      state.jobAllItems = payload
    },
    jobsSearch(state, payload){
      state.jobsSearch = payload;
    },
    jobsSorting(state, payload){
      state.jobsSorting = payload;
    },
    checkMeasureCalendars(state, payload) {
      let newArray = []
       payload.forEach(item => {
        for (var i = 0; i < item.job_count; i++) {
          newArray.push({start: item.date, end: item.date, title: item.id_with_prefix})
        }
      });
      state.checkMeasureCalendars = newArray
    },
    checkMeasureData(state, payload){
      state.checkMeasureData = payload;
    },
    productMargins(state, payload){
      state.productMargins = payload;
    },
    productMarginsProperty(state, payload){
      if (payload.key_2) {
        if (!state.productMargins[payload.key]) {
          state.productMargins[payload.key] = {}
        }
        state.productMargins[payload.key][payload.key_2]= payload.value;
      } else {
        state.productMargins[payload.key] = payload.value;
      }
    },
    statuses(state, payload){
      state.statuses = payload;
    },
    currentActionPerformingJobs(state, payload){
      state.selectedJob = payload
    },
    resetJobs(state, payload){
      state[`jobsList${payload.toString().replace(/\s/g, "")}`] = [];
      state[`jobsPagination${payload.toString().replace(/\s/g, "")}`] = {};
    },
    resetJob(state, payload){
      state.job = {}
    },
    resetOrderContainer(state, payload){
      state.orderContainer = {}
    },
    resetOrderContainerAccessories(state, payload){
      state.orderContainerAccessories = []
    },
    resetOrderContainerSummary(state, payload){
      state.orderContainerSummary = {}
    },
    resetOrderContainerLocations(state, payload){
      state.orderContainerLocations = []
    },
    resetJobPrice(state, payload){
      state.jobPrice = {}
    },
    resetJobAllItems(state, payload){
      state.jobAllItems = {}
    },
    resetSorting(state, payload){
      state.jobsSorting = null
    }
  },

  actions: {
    getJobsList({state, getters, commit}, payload) {
      let parameters = {
      }
      if (payload) {
        Object.assign(parameters, {page: getters.jobsNextPage(payload.status)})
      }
      if (getters.jobsSorting) {Object.assign(parameters, {sort: getters.jobsSorting})}
      let status = payload?.status || ''
      if (state.jobsSorting && getters.jobsNextPage(payload.status) <= getters.jobsTotalPages(payload.status)) {
        return httpReq.get(`ordering-portal/my-jobs?paginate=10&filter[status]=${payload?.status || ''}&filter[search]=${getters.jobsSearch || ''}`,
          parameters
        )
          .then(response => {
            commit('jobsList', [response.data?.data , status.replace(/\s/g, "")]);
            commit('jobsPagination', [response.data?.pagination , status.replace(/\s/g, "")]);
          })
      }
    },
    getOrderContainer({state, getters, commit}, payload) {
      return httpReq.get(`ordering-portal/my-jobs/${payload.id}`)
        .then(response => {
          commit('orderContainer', response.data?.data);
        })
    },
    getOrderContainerAccessories({state, getters, commit}, payload) {
      return httpReq.get(`ordering-portal/my-jobs/${payload.id}/accessories`)
        .then(response => {
          commit('orderContainerAccessories', response.data?.data);
        })
    },
    getOrderContainerSummary({state, getters, commit}, payload) {
      return httpReq.get(`ordering-portal/my-jobs/${payload.id}/summary`)
        .then(response => {
          commit('orderContainerSummary', response.data?.data);
        })
    },
    async getOrderContainerLocations({state, getters, commit, dispatch}, payload)  {
      commit('resetOrderContainerAccessories');
      return httpReq.get(`ordering-portal/my-jobs/${payload.container_id}/locations`)
        .then(async response => {
          console.log(response)
            await Promise.all(response.data?.data.map(async (location) => {
            await dispatch('getOrderContainerLocation', {id: payload.id, container_id: payload.container_id, location_name: location.name});
          }));
        })
    },
    async getOrderContainerLocation({state, getters, commit}, payload) {
      await httpReq.get(`ordering-portal/my-jobs/${payload.id}/locations/${payload.location_name}`)
        .then(response => {
          commit('orderContainerAccessories', response.data.data?.original);
        })
    },
    calculateJobPrice({state, getters, commit}, payload) {
      return httpReq.get(`ordering-portal/my-jobs/${payload.id}/summary`)
        .then(response => {
          commit('jobPrice', response.data?.data);
        })
    },
    getJobAllItems({state, getters, commit}, payload) {
      store.commit('preloader/preloader', true);
      return httpReq.get(`ordering-portal/my-jobs/${payload}/get-all-items`,)
        .then(response => {
          commit('jobAllItems', response.data?.data);
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },
    getCheckMeasureCalendars({state, getters, commit}, payload) {
        return httpReq.get(`ordering-portal/my-jobs/check-measure-calendars`
        )
          .then(response => {
            commit('checkMeasureCalendars', response.data?.data);
          })
      },
    getCheckMeasureData({state, getters, commit}, payload) {
      store.commit('preloader/preloader', true);
      return httpReq.get(`ordering-portal/my-jobs/check-measure-calendars/${payload}/jobs`,
      )
        .then(response => {
          commit('checkMeasureData', response.data?.data);
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },
    getProductMargins({state, getters, commit}, payload) {
      return httpReq.get(`ordering-portal/my-jobs/${payload}/product-margins`,)
        .then(response => {
          commit('productMargins', response.data?.data);
        })
        .catch(error => {
          console.log(error)
        })
    },
    editProductMargins({state, getters, commit}, payload) {
      store.commit('preloader/preloader', true);
      let parameters = {
      }
      if (payload.parameters) {
        Object.assign(parameters, payload.parameters);
      }
      return httpReq.put(`ordering-portal/my-jobs/${payload.id}/product-margins`, parameters)
        .then(response => {
          // commit('productMargins', response.data?.data);
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },
    async deleteJob({commit, getters, state}, job) {
      store.commit('preloader/preloader', true);
      try{
        const response = await httpReq.delete(`ordering-portal/my-jobs/${job.id}`)

        let columnsToBeReset = await store.dispatch('jobs/getColumnsThatNeedToBeRefreshedBasedOnPerformedAction', {action:"delete",job})

          if(!columnsToBeReset.currentColumnToReset && !columnsToBeReset.statusOfNextColumnToReset){
            commit('jobsSorting', getters.jobsSorting+' ');
          }else {

            store.commit('jobs/resetJobs', columnsToBeReset.currentColumnToReset)
            await store.dispatch('jobs/getJobsList', {status: columnsToBeReset.currentColumnToReset})

            if(columnsToBeReset.statusOfNextColumnToReset) {
              store.commit('jobs/resetJobs', columnsToBeReset.statusOfNextColumnToReset)
              await store.dispatch('jobs/getJobsList', {status: columnsToBeReset.statusOfNextColumnToReset})
            }
          }
      }
      catch(errors) {
        console.log(errors)
        store.dispatch('errors/setErrors', errors?.response?.data ?? "Could not process your request.")
      }
      store.commit('preloader/preloader', false);

    },
    async changeJobsStatus({state, getters, commit}, payload) {

      store.commit('preloader/preloader', true);
      let parameters = {}
      if (payload.parameters) {
        Object.assign(parameters, payload.parameters);
      }
      return httpReq.put(`ordering-portal/my-jobs/${payload.id}/${payload.status}`,
        parameters
      )
        .then(async response => {

          let job = await store.dispatch('jobs/getJobById', payload.id)
          let columnsToBeReset = await store.dispatch('jobs/getColumnsThatNeedToBeRefreshedBasedOnPerformedAction', {action:payload.status,job})

          commit('jobsSorting', getters.jobsSorting+' ');

          // if(!columnsToBeReset.currentColumnToReset && !columnsToBeReset.statusOfNextColumnToReset){
          //   commit('jobsSorting', getters.jobsSorting+' ');
          // }else {

          //   store.commit('jobs/resetJobs', columnsToBeReset.currentColumnToReset)
          //   await store.dispatch('jobs/getJobsList', {status: columnsToBeReset.currentColumnToReset})

          //   if(columnsToBeReset.statusOfNextColumnToReset) {
          //     store.commit('jobs/resetJobs', columnsToBeReset.statusOfNextColumnToReset)
          //     await store.dispatch('jobs/getJobsList', {status: columnsToBeReset.statusOfNextColumnToReset})
          //   }
          // }

          return response
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        }).catch((errors) => {
          console.log(errors)
          store.dispatch('errors/setErrors', errors.response.data)
        })
    },
    async getJobById({state},jobId){
      let job = null
        state.statusList.forEach((status) => {
          let thisStatusJobs = state[`jobsList${status.name.replace(/\s/g, "")}`] || []
          let searchJob = _.find(thisStatusJobs,{id:jobId})
          if(searchJob) job = searchJob
        })
      return job
    },
    async getJob({state, getters, commit}, payload) {
      return httpReq.get(`ordering-portal/my-jobs/${payload}`,)
        .then(response => {
          commit('job', response.data?.data);
          return response.data?.data
        })
    },
    async getColumnsThatNeedToBeRefreshedBasedOnPerformedAction ({state},data){
        const performedAction = data.action
        const actionPerformedJob = data.job
        let statusOfNextColumnToReset = "Open"

        if(performedAction === 'mark-as-check-measure-completed'){
          statusOfNextColumnToReset = 'Awaiting Order Placement'
        }else if(performedAction === 'update-check-measuring-date'){
          statusOfNextColumnToReset = 'Awaiting Check Measure'
        }else if(performedAction === 'mark-as-job-placed'){
          statusOfNextColumnToReset = 'In Production'
        }else if(performedAction === 'mark-as-delivered'){
          statusOfNextColumnToReset = 'Awaiting Installation'
        }

        return {
          currentColumnToReset : actionPerformedJob?.status,
          statusOfNextColumnToReset
        }

    },
    editJob({state, getters, commit}, payload) {
      store.commit('preloader/preloader', true);
      let parameters = {
      }
      if (payload.parameters) {
        Object.assign(parameters, payload.parameters);
      }
      return httpReq.put(`ordering-portal/my-jobs/${payload.id}`, parameters
      )
        .then(response => {
          commit('job', response.data?.data);
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },
    createNewJob({state, getters, commit}, payload) {
      store.commit('preloader/preloader', true);
      let parameters = {
      }
      if (payload.parameters) {
        Object.assign(parameters, payload.parameters);
      }
      return httpReq.post(`ordering-portal/my-jobs`, parameters
      )
        .then(response => {
          commit('job', response.data?.data);
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },
    getStatuses({state, getters, commit}, payload) {
      return httpReq.get(`order/statuses`,)
        .then(response => {
          commit('statuses', response.data?.data);
        })
    },
  },
};
