import { createStore } from 'vuex'
import Vuex from 'vuex';


// import httpReq from '../../src/htttpReq';
// import {router} from '../../src/router';

import menu from './modules/menu';
import state from './modules/state';
import wholesale from './modules/wholesale';
import consumer from './modules/consumer';
import status from './modules/status';
import registration from './modules/registration/registration';
import auth from './modules/auth';
import account from './modules/wholesaleProfile/account';

import reject from './modules/reject';
import approve from './modules/approve';
import createPassword from './modules/createPassword';
import resetPassword from './modules/resetPassword';


import userList from './modules/dashboardLists/userList';
import userStatistics from './modules/dashboardLists/userStatistics';
import itemsList from './modules/dashboardLists/itemsList';
import ordersList from './modules/dashboardLists/ordersList';
import salesList from './modules/dashboardLists/salesList';
import errorsModal from './modules/modals/errorsModal';
import genericQuestion from './modules/modals/genericQuestion';
import finances from './modules/dashboardLists/finances';
import dealers from './modules/dashboardLists/dealers';

import businessDetails from './modules/wholesaleProfile/businessDetails';
import financials from './modules/wholesaleProfile/financials';
import users from './modules/wholesaleProfile/users';
import discount from './modules/wholesaleProfile/discount';


import preloader from './modules/preloader';

import orderItems from './modules/orderItems/orderItems';

import customerDetails from './modules/orderItems/customerDetails';
import order from './modules/order/order';
import jobs from './modules/jobs/jobs';
import leads from './modules/leads/leads';
import orders from './modules/orders/orders';
import customers from './modules/customers/customers';
//TODO: Remove
import createdOrder from './modules/order/createdOrder';

import orderPosition from './modules/order/positionBuilder';
import currentUser from './modules/current_user'

import production from './modules/order/production';


import types from './modules/order/types';
import style from './modules/order/style';
import grade from './modules/order/grade';
import colors from './modules/order/colors';
import optionColours from './modules/order/optionColours';
import options from './modules/order/options';
import orderPreview from './modules/order/orderPreview';
import orderDetails from './modules/order/orderDetails';
import location from './modules/order/location';
import insertMesh from './modules/order/insertMesh';

import packages from './modules/packages/packages';
import sets from './modules/sets/sets';
import accessories from './modules/order/accessories';


import app from './modules/appDB';
import orderItem from "./modules/orderItems/orderItem";
import deleteModalGlobal from "./modules/modals/deleteModalGlobal";

import settingsOrder from './modules/modals/settingsOrder';
import totalPriceOrder from './modules/modals/totalPriceOrder';
import filterOrders from './modules/modals/filterOrders';
import financialController from "@/store/modules/dashboardLists/financialController";
import applySet from './modules/order/applySet';
import selected from "./modules/options/selected";
import shipping from "@/store/modules/order/shipping";
import errors from './modules/errors.js'
import success from './modules/success.js'

import wizard from './modules/wizard.js'

import callback from './modules/callback';




const store =  createStore({
  modules: {
    success,
    errors,
    preloader,
    menu,
    state,
    wholesale,
    consumer,
    status,
    registration,
    auth,
    account,
    reject,
    approve,
    createPassword,
    deleteModalGlobal,

    userList,
    userStatistics,
    itemsList,
    ordersList,
    filterOrders,
    salesList,
    orderItems,
    orderItem,
    order,
    jobs,
    leads,
    orders,
    customers,
    createdOrder,
      orderPosition,
      // production,
    types,
    style,
    grade,
    colors,
      optionColours,
    options,
    orderPreview,
      orderDetails,
    // settingsOrder,
    // totalPriceOrder,
    // location,
    // insertMesh,
    // applySet,

    // packages,
    // sets,
    // accessories,

    app,
    currentUser,

    errorsModal,
      customerDetails,
    genericQuestion,

    businessDetails,
    financials,
    users,
    discount,

    resetPassword,
    selected,
      finances,
      dealers,
    // financialController,
    shipping,
    callback,

    wizard
  },
});
//store.use(Vuex);
export default store;
