import store from "../../store";
import router from "../../../router";
import {httpReq} from "@/htttpReq";

export default {
  namespaced: true,

  state: {
    sets: {},
    setId: Number,
    packageName: '',
    isSetState: true,
  },

  getters: {
    sets(state) {
      return state.sets
    },
    get: state => key => {
      return _.has(state, key) ? state[key] : null;
    },
  },

  mutations: {
    updateSets(state, payload) {
      state.sets = payload;
    },

    set(state, payload) {
      state[payload.k] = payload.v;
    },

    setPackageName(state, payload){
      state.packageName = payload;
    }
  },

  actions: {
    ['prepareEditPackage']({commit, state, dispatch}, data) {
      let set = data.set,
        packageData = data.package;

      store.commit('packages/packageId', _.toInteger(packageData.id));
      store.commit('createdOrder/selectModal', true);

      store.commit('createdOrder/updateType', _.toInteger(set.type));
      store.commit('createdOrder/updateStyle', _.toInteger(set.style_id));
      store.commit('createdOrder/updateGrade', _.toInteger(set.grade_id));
      store.commit('createdOrder/updateName', _.toString(packageData.name));

      if (Object.keys(packageData.accessories).length) {
        store.commit(
          'createdOrder/setAccessories',
          _.toArray(_.reduce(packageData.accessories, (memo, item, key) => {
              item.lengthVal = {
                l1: item.length1,
                l2: item.length2,
                l3: item.length3,
                l4: item.length4,
              };
              item.mitreCut = item.mitre;
              item.name = item.get_item.name;
              item.type = item.get_item.type;
              item.typeName = '';
              memo[key] = item;
              return memo;
            }, {}
          ))
        );
      }

      if (Object.keys(packageData.options).length) {
        store.commit('createdOrder/setOptions',
          _.reduce(packageData.options, (memo, item, key) => {
              item.components = item.settings.components;
              item.settings = item.settings.settings;
              memo[key] = item;
              return memo;
            }, {}
          )
        );
      }

      store.dispatch('packages/getPackage', data.package.id);

      store.commit(
        'app/set',
        {
          k: 'breadcrumbs',
          v: [
            {
              route: {
                path: '/sets-builder-choice'
              },
              name: set.name,
              disabled: true,
            },
            {
              route: {
                path: `/sets-builder-choice/${set.type}`
              },
              name: set.type_name,
              disabled: true,
            },
            {
              route: {
                path: `/sets-builder-choice/${set.style_id}`
              },
              name: set.style_name,
              disabled: true,
            },
            {
              route: {
                path: `/sets-builder-choice/${set.grade_id}`
              },
              name: set.grade_name,
              disabled: true,
            },
          ]
        }
      );
      router.push({path: '/sets-builder-choice'});
    },

    createSetWithPackage({commit, state, dispatch}) {
      let data = store.getters['createdOrder/createdOrder'];
      store.commit('preloader/preloader', true);
      httpReq.post('set/create',
        {
          name: data.items.name,
          type: data.items.type_id,
          style: data.items.style_id,
          grade: data.items.grade_id,
          all_users: data.items.allUsers
        }).then((response) => {
        if (response.data) {
          state.setId = response.data[0].id;
          dispatch('appendPackageToSet', false);
          commit('setPackageName', '');
        }
      }).catch(error => {
      }).finally((response) => {
        store.commit('preloader/preloader', false);
      });
    },

    ['appendPackageToSet']({commit, state}, preloader = true) {
      let data = store.getters['createdOrder/createdOrder'];
      httpReq.post(
        'set/add-package',
        {
          set_id: state.setId,
          packages: {
            packages: {
              name: state.packageName,
              options: store.getters['options/selected'],
              accessories: data.items.accessories
            }
          }
        }).then((response) => {
        store.commit('createdOrder/selectModal', false);
        store.commit('createdOrder/cleanOrderObj');
        store.dispatch('sets/getSets');
        state.setId = Number;
        state.packageName = String;
      });
    },

    getSets({commit}) {
      store.commit('preloader/preloader', true);
      httpReq.get('set/all').then(response => {
        if (response) {
          commit('updateSets', response.data.data);
        }
      }).catch(error => {
        store.commit('errorsModal/openModal', error.response.data.errors);
      }).finally(() => {
        store.commit('preloader/preloader', false);
      })
    },

    delete({commit}, p) {
      httpReq.delete('set/delete', {data: {id: p.id, with_packages: p.with_packages}}).then((r) => {
        store.dispatch('sets/getSets');
      }).catch(error => {
        store.commit('errorsModal/openModal', error.response.data.errors);
      }).finally(() => {
        store.commit('preloader/preloader', false);
      })
    },

    postSets({commit}) {
      let data = store.getters['createdOrder/createdOrder'];
      store.commit('preloader/preloader', true);

      httpReq.post('set/create',
        {
          name: data.items.name,
          type: data.items.type_id,
          style: data.items.style_id,
          grade: data.items.grade_id,
          all_users: data.items.allUsers
        })
        .then((response) => {
          if (response.data) {
            store.commit('createdOrder/selectModal', false);
            store.commit('createdOrder/cleanOrderObj');
            store.dispatch('sets/getSets');
            commit('setPackageName', '');
          }
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors);
        })
        .finally((response) => {
          store.commit('preloader/preloader', false);
          router.push({name: 'sets'});
        });
    },

    getToSetOption({commit}, credentials) {
      store.commit('preloader/preloader', true);
      let config = {
        params: {
          type: store.getters['createdOrder/createdOrder'].items.type_id,
          styles_id: [store.getters['createdOrder/createdOrder'].items.style_id],
          grades_id: [store.getters['createdOrder/createdOrder'].items.grade_id],
          option_name: credentials ? credentials : '',
        }
      };

      httpReq.get('package/package-single-option', config)
        .then(response => {
          store.commit('options/openDefaultOption', response.data);
        })
        .catch(error => {
        })
        .finally(() => {
          store.commit('preloader/preloader', false);
        })
    },

    updatePackageInSet({commit, dispatch}, credentials) {
      let data = store.getters['createdOrder/createdOrder'];
      store.commit('preloader/preloader', true);
      httpReq.post('package/update',
        {
          package_id: store.getters['packages/packageId'],
          name: credentials,
          options: store.getters['options/selected'],
          accessories: data.items.accessories
        })
        .then((response) => {
          if (response.data) {
            store.commit('createdOrder/selectModal', false);
            store.commit('packages/setIsEditFromPackage', false);
            store.commit('createdOrder/cleanOrderObj');
            dispatch('getSets');
            router.push({name: 'sets'});
          }
        })
        .catch(error => {
          store.commit('errorsModal/openModal', error.response.data.errors);
        })
        .finally((response) => {
          store.commit('preloader/preloader', false);
        });
    },
  },
};
